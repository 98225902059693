var FOR_THIRD_PARTY_USE = false;
var ALLOW_OFFLINE_CHAT = false;
var SHOW_STATUS_IF_EXPIRED = true;
var sockwrap;
var CentionBaseURL;
var CentionTemplateButtonCallbacks = {};
var spacePrefix = "";

if (typeof c3jQuery == "undefined") {
	var c3jQuery = jQuery.noConflict(true);
}
if (typeof CentionBaseURL == "undefined") {
	CentionBaseURL = "";
	switch (window.location.hostname) {
	case "api.cention.com":
	case "cloud.cention.com":
	case "cloud.cust.cention.se":
		spacePrefix = window.location.pathname.substr(0, window.location.pathname.indexOf("/", 3));
	}
}
if (typeof CloudFrontURL == "undefined") {
	var CloudFrontURL = CentionBaseURL;
}
if (FOR_THIRD_PARTY_USE) {
	var CentionChatStatus = {};
	sockwrap = "sockwrap.js";
	var webSocket = "socket.js";
	$ = c3jQuery;

	var SocketScript = document.createElement("script");
	SocketScript.setAttribute("type", "text/javascript");
	SocketScript.setAttribute("src", webSocket);
	document.head.appendChild(SocketScript);
}

var _CentionChat = function(config) {
	var that = {};
	var Public = {};
	var USING_WEBSOCKET = true;
	if (USING_WEBSOCKET) {
		var ws; // = Socket('wss://'+window.location.host+"/external.ws");
	}
	var chatSock = null;
	var actionHandlers = {};
	var SystemMessage;
	var JSON_ENCODED = "\x01JSON";
	var onlineChatRegistered = false;
	var offlineChatRegistered = false;

	Public.sessionSecret = '';
	Public.sessionId = '';
	Public.areaId = 0;
	Public.activeSession = false;
	that.parameters = {};
	that.queuePosition = -1;
	that.mcount = {};
	that.maxAcked = 0;
	// agentSeen keep track of whether the agent has seen the message sent by client.
	// It contains message ids that was sent by the client only.
	that.agentSeen = {};
	Public.baseURL = ""; // May be set by third party client.
	that.trackClientUrl = true;
	if(config){
		that.trackClientUrl = config.trackClientUrl;
	}
	if (typeof I === 'function') {
		Public.I = I;
	} else {
		Public.I = function(text) { return text; };
	}

	function log() {
		if (window.console && console.log && typeof console.log === "function") {
			console.log.apply(console, arguments)
		}
	}

	function isInt(v) {
		return v === parseInt(v, 10);
	}

	function isJSONPayload(text) {
		// returns true if text starts with "\x01JSON"
		return text.lastIndexOf(JSON_ENCODED, 0) === 0;
	}

	const hasSuffix = (src, suffix) => src.indexOf(suffix, src.length - suffix.length) !== -1
	, trimSlash = str => str.replace(/^\/+|\/+$/g, '')
	;

	function isImageFilename(filename) {
		if(typeof filename !== "undefined") {
			const name = filename.toLowerCase();
			if(hasSuffix(name, '.jpg')) {
				return true;
			} else if(hasSuffix(name, '.jpeg')) {
				return true;
			} else if(hasSuffix(name, '.png')) {
				return true;
			} else if(hasSuffix(name, '.gif')) {
				return true;
			} else if(hasSuffix(name, '.webp')) {
				return true;
			}
		}
		return false;
	}

	// CentionChatAPI.go: renderFileUploadMessage()
	function renderFileUploadMessage(j) {
		let isImg = isImageFilename(j.fileName);
		let html;
		html = '<a href="/Cention/web/chat/client/download' + escape(j.fileDownload) + '">' + j.fileName +
		' <span>(' + j.sizeHuman + ')</span></a>';
		if(isImg) {
			html = '<a href="' + Public.baseURL + spacePrefix + escape(j.fileDownload)
			+ '">'
			+ '<img src="' + Public.baseURL + spacePrefix + escape(j.fileDownload)
			+ ' width="50%"></img>'
			+ j.fileName
			+ ' <span>(' + j.sizeHuman + ')</span></a>';
		}
		if (j.error) {
			html += '<span>Error: ' + j.error + '</span>';
		}
		return html
	}

	function renderTemplateButtonMessage(j) {
		var html = '<div class="buttonTemplateContainer">'
			+ '<div class="buttonTemplateText">' + j.text + '</div>';
		if (Array.isArray(j.buttons)) {
			c3jQuery.each(j.buttons, function(index, button) {
				var id = Math.random().toString(36).substr(2, 9);
				switch (button.type) {
					case "url":
						CentionTemplateButtonCallbacks[id] = function() {
							window.open(button.url);
						};
						break;
					case "postback":
						CentionTemplateButtonCallbacks[id] = function() {
							Public.message(that.JSONMessage({
								"event": "BUTTON_POSTBACK",
								"buttonTitle": button.title,
								"buttonData": button.data
							}));
						};
						break;
				}
				html += '<div class="buttonTemplateButton"'
					+ ' onclick="CentionTemplateButtonCallbacks[\'' + id + '\']();">'
					+ button.title
					+ '</div>';
			});
		}
		html += '</div>';
		return html;
	}

	function renderButtonPostbackMessage(j) {
		return j.buttonTitle;
	}

	function sanitizeText(text){
		var entities = [
		['amp', '&'],
		['apos', '\''],
		['#x27', '\''],
		['#x2F', '/'],
		['#39', '\''],
		['#34', '\"'],
		['#47', '/'],
		['lt', '<'],
		['gt', '>'],
		['nbsp', ' '],
		['quot', '"']
		];

		for (var i = 0, max = entities.length; i < max; ++i)
			text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

		return text;
	}

	SystemMessage = {
		renderSetAgentsEvent: function(m) {
			var text = []
			, i
			, byName = function(a, b) {
				if (a < b) { return -1; }
				if (a > b) { return 1; }
				return 0;
			}
			, collectNames = function(a) {
				var i = 0
				, r = []
				;
				for(i=0; i<a.length; i++) {
					r.push(m.names[a[i]]);
				}
				return r;
			}
			, added = collectNames(m.added)
			, removed = collectNames(m.removed)
			;

			added.sort(byName);
			removed.sort(byName);

			if (removed.length > 0) {
				text.push(Public.I("Agent(s) removed: {AGENT_NAMES}")
					.replace('{AGENT_NAMES}', removed.join(", ")));
			}
			if (added.length > 0 && !m.byInvitation) {
				text.push(Public.I("Agent(s) added: {AGENT_NAMES}")
				       .replace('{AGENT_NAMES}', added.join(", ")));
			}
			if (text.length == 0) {
				return null;
			}
			return text.join(".<br>\n");
		},
		renderAgentLeftEvent: function(m) {
			return Public.I("{AGENT_NAME} has left the chat.").replace("{AGENT_NAME}", m.name);
		},
		renderClientEndsChat: function(m) {
			var text;
			if (m.name == "UNKNOWN") {
				text = Public.I("Client ended the chat.");
			} else {
				text = Public.I("{NAME} ended the chat.").replace("{NAME}", m.name);
			}
			return text;
		},
		renderAttachmentAdded: function(m) {
			let msg;
			msg = "<a href=\"" +
			Public.baseURL + spacePrefix + m.file.download +
			"\">" + m.file.value +
			" <span>(" + m.file.sizeHuman + ")</span></a>";
			return msg;
		},
		renderReturnToQueue: function(m) {
			var text = Public.I("{FROM_AGENT} handover the chat to agent")
				.replace("{FROM_AGENT}", m.from)
				;
			return text;
		},
		renderForwardToAgent: function(m) {
			var text;

			text = Public.I("{FROM_AGENT} forwarded the chat to {TO_AGENT}")
				.replace("{FROM_AGENT}", m.from).replace("{TO_AGENT}", m.to)
				;

			return text;
		},
		renderForwardToArea: function(m) {
			var text;

			text = Public.I("{FROM_AGENT} forwarded the chat to area {AREA_NAME}")
				.replace("{FROM_AGENT}", m.from)
				.replace("{AREA_NAME}", m.areaName)
				;

			return text;
		},
		renderSendChatHistory: function(m) {
			var text = Public.I("A copy of the chat will be sent to {CLIENT_EMAIL}")
				.replace("{CLIENT_EMAIL}", m.to)
				;
			return text;
		},
		renderAcceptChat: function(m) {
			var text = Public.I("{AGENT_NAME} joins the chat.")
				.replace("{AGENT_NAME}", m.who)
				;
			return text;
		},
		renderRejectChat: function(m) {
			return null;
		},
		renderChatExpired: function(m) {
			return Public.I("This chat has expired.");
		},
		renderOwnerEndsChat: function(m) {
			return Public.I("{AGENT_NAME} ended the chat.")
				.replace("{AGENT_NAME}", m.who);
		},
		renderAgentUnableAcceptCall: function(m) {
			return Public.I("Agent {AGENT_NAME} is unable to accept video call at the moment.")
				.replace("{AGENT_NAME}", m.who);
		},
		renderAgentAcceptCall: function(m) {
			return Public.I("Agent {AGENT_NAME} is accepting video call from {CLIENT_NAME}")
				.replace("{AGENT_NAME}", m.who)
				.replace("{CLIENT_NAME}", m.from);
		},
		renderClientHangupCall: function(m) {
			return Public.I("{CLIENT_NAME} stopped the video call")
				.replace("{CLIENT_NAME}", m.name);
		},
		renderAgentHangupCall: function(m) {
			return Public.I("Agent {AGENT_NAME} stopped the video call")
				.replace("{AGENT_NAME}", m.who);
		},
		renderClientAcceptCall: function(m) {
			return Public.I("{CLIENT_NAME} is accepting video call from agent {AGENT_NAME}")
				.replace("{CLIENT_NAME}", m.name)
				.replace("{AGENT_NAME}", m.from);
		},
		renderClientRejectCall: function(m) {
			return Public.I("{CLIENT_NAME} is not accepting video call from agent {AGENT_NAME}")
				.replace("{CLIENT_NAME}", m.name)
				.replace("{AGENT_NAME}", m.from);
		},
		renderAgentRejectScreenShare: function(m) {
			return Public.I("Agent {AGENT_NAME} is unable to accept screen sharing at the moment.")
				.replace("{AGENT_NAME}", m.who);
		},
		renderClientRejectScreenShare: function(m) {
			return Public.I("{CLIENT_NAME} is unable to accept screen sharing at the moment.")
				.replace("{CLIENT_NAME}", m.from);
		},
		renderStopRecord: function(m) {
			return null;
		},
		renderDoneRecord: function(m) {
			return null;
		},
		renderClientShareScreen: function(m) {
			if(m.coBrowse) {
				return Public.I("{CLIENT_NAME} is starting a co-browsing with agent {AGENT_NAME}")
				.replace("{CLIENT_NAME}", m.from)
				.replace("{AGENT_NAME}", m.who);
			} else {
				return Public.I("{CLIENT_NAME} starting screen sharing with agent {AGENT_NAME}")
				.replace("{CLIENT_NAME}", m.from)
				.replace("{AGENT_NAME}", m.who);
			}
		},
		renderAgentShareScreen: function(m) {
			return Public.I("Agent {AGENT_NAME} starting screen sharing with {CLIENT_NAME}")
				.replace("{AGENT_NAME}", m.from)
				.replace("{CLIENT_NAME}", m.who)
		},
		renderClientStopScreenShare: function(m) {
			if(m.coBrowse) {
				return Public.I("{CLIENT_NAME} stopped a co-browsing session with agent {AGENT_NAME}").
				replace("{CLIENT_NAME}", m.from).
				replace("{AGENT_NAME}", m.who);
			} else {
				return Public.I("{CLIENT_NAME} stopped screen sharing with agent {AGENT_NAME}").
				replace("{CLIENT_NAME}", m.from).
				replace("{AGENT_NAME}", m.who);
			}
		},
		renderAgentStopScreenShare: function(m) {
			return Public.I("Agent {AGENT_NAME} stopped screen sharing with {CLIENT_NAME}").
				replace("{AGENT_NAME}", m.from).
				replace("{CLIENT_NAME}", m.who);
		},
		renderAgentStopRemoteScreenShare: function(m) {
			if(m.coBrowse) {
				return Public.I("Agent {AGENT_NAME} stopped a co-browsing session.")
				.replace("{AGENT_NAME}", m.who);
			} else {
				return Public.I("Agent {AGENT_NAME} stopped screen sharing.")
				.replace("{AGENT_NAME}", m.who);
			}
		},
		renderClientStopAgentScreenShare: function(m) {
			return Public.I("{CLIENT_NAME} stopped screen sharing")
				.replace("{CLIENT_NAME}", m.from);
		},
		renderClientActivateCoBrowsing: function(m) {
			return Public.I("{CLIENT_NAME} activated co-browsing")
				.replace("{CLIENT_NAME}", m.from);
		},
		renderAgentInitiatedCoBrowsing: function(m) {
			return Public.I("{AGENT_NAME} initiated a co-browsing session.")
				.replace("{AGENT_NAME}", m.from);
		},
		renderClientRequestCallInstantly: function(m) {
			return Public.I("{CLIENT_NAME} have requested a callback through phone number {PHONE}")
				.replace("{CLIENT_NAME}", m.from).replace("{PHONE}", m.data.info.phone);
		},
		renderClientRequestCallSchedule: function(m) {
			const unixTimestamp = m.data.info.datetime;
			const milliseconds = unixTimestamp * 1000;
			const dateObject = new Date(milliseconds);
			const humanDateFormat = dateObject.toLocaleString();

			return Public.I("{CLIENT_NAME} have requested a callback through phone number {PHONE} at {TIME}, we will reach you at the appointed time.")
				.replace("{CLIENT_NAME}", m.from).replace("{PHONE}", m.data.info.phone).replace("{TIME}", humanDateFormat);
		},
		format: function(json,clientId,agents) {
			var m = JSON.parse(json.text);
			if (!m) {
				return json;
			}
			var sysMsg = {sender:'SYSTEM',fromClient: json.fromClient,text:json.text};
			switch (m.event) {
				case "SET_AGENTS":
					sysMsg.text = SystemMessage.renderSetAgentsEvent(m);
					break;
				case "AGENT_LEFT":
					sysMsg.text = SystemMessage.renderAgentLeftEvent(m);
					break;
				case "CLIENT_ENDS_CHAT":
					sysMsg.text = SystemMessage.renderClientEndsChat(m);
					break;
				case "ADD_ATTACHMENT":
					Array.prototype.contains = function(obj) {
						return this.indexOf(obj) > -1;
					};
					isAgent = agents.indexOf(m.who);
					if(isAgent > -1 ){
						sysMsg.fromClient = false;
					}else {
						sysMsg.fromClient = true;
					}
					sysMsg.sender = m.name;
					sysMsg.text = SystemMessage.renderAttachmentAdded(m);
					break;
				case "RETURN_TO_QUEUE":
					sysMsg.text = SystemMessage.renderReturnToQueue(m);
					break;
				case "FORWARD_TO_AGENT":
					sysMsg.text = SystemMessage.renderForwardToAgent(m);
					break;
				case "FORWARD_TO_AREA":
					sysMsg.text = SystemMessage.renderForwardToArea(m);
					break;
				case "SEND_CHAT_HISTORY":
					sysMsg.text = SystemMessage.renderSendChatHistory(m);
					break;
				case "ACCEPT_CHAT":
					sysMsg.text = SystemMessage.renderAcceptChat(m);
					break;
				case "REJECT_CHAT":
					sysMsg.text = null;
					break;
				case "CHAT_EXPIRED":
					sysMsg.text = SystemMessage.renderChatExpired(m);
					break;
				case "OWNER_ENDS_CHAT":
					sysMsg.text = SystemMessage.renderOwnerEndsChat(m);
					break;
				case "AGENT_REJECT_VIDEO_CALL":
					sysMsg.text = SystemMessage.renderAgentUnableAcceptCall(m);
					break;
				case "AGENT_ACCEPT_VIDEO_CALL":
					sysMsg.text = SystemMessage.renderAgentAcceptCall(m);
					break;
				case "CLIENT_HANGUP_VIDEO_CHAT":
					sysMsg.text = SystemMessage.renderClientHangupCall(m);
					break;
				case "AGENT_HANGUP_VIDEO_CHAT":
					sysMsg.text = SystemMessage.renderAgentHangupCall(m);
					break;
				case "CLIENT_ACCEPT_VIDEO_CHAT":
					sysMsg.text = SystemMessage.renderClientAcceptCall(m);
					break;
				case "CLIENT_REJECT_VIDEO_CALL":
					sysMsg.text = SystemMessage.renderClientRejectCall(m);
					break;
				case "CHAT_VIDEO_RECORD_STOP":
					sysMsg.text = SystemMessage.renderStopRecord(m);
					break;
				case "CHAT_VIDEO_RECORD_DONE":
					sysMsg.text = SystemMessage.renderDoneRecord(m);
					break;
				case "CLIENT_OFFER_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderClientShareScreen(m);
					break;
				case "AGENT_OFFER_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderAgentShareScreen(m);
					break;
				case "CLIENT_STOP_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderClientStopScreenShare(m);
					break;
				case "AGENT_STOP_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderAgentStopScreenShare(m);
					break;
				case "AGENT_STOP_REMOTE_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderAgentStopRemoteScreenShare(m);
					break;
				case "AGENT_REJECT_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderAgentRejectScreenShare(m);
					break;
				case "CLIENT_REJECT_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderClientRejectScreenShare(m);
					break;
				case "CLIENT_STOP_AGENT_SCREEN_SHARE":
					sysMsg.text = SystemMessage.renderClientStopAgentScreenShare(m);
					break;
				case "AGENT_INIT_COBROWSE":
					//agent initiated a co browsing session to let client know and act further
					sysMsg.text = SystemMessage.renderAgentInitiatedCoBrowsing(m);
					break;
				case "CLIENT_ACTIVATE_COBROWSE":
					sysMsg.text = SystemMessage.renderClientActivateCoBrowsing(m);
					break;
				case "CALLBACK_REQUEST_NOW":
					sysMsg.text = SystemMessage.renderClientRequestCallInstantly(m);
					break;
				case "CALLBACK_REQUEST_SCHEDULE":
					sysMsg.text = SystemMessage.renderClientRequestCallSchedule(m);
					break;
			}
			return sysMsg;
		}
	};

	that.maybeFormat = function (message) {
		var j
		, orig = message.text
		;
		// Image view handling on client side
		// This will convert agent url to client accessible url
		// to let agent view/download the image sent by agent
		if (message.sender == 'AGENT') {
			var addBaseURL = RegExp(Public.baseURL+'/Cention/web/', 'g');
			var addSpacePrefix = RegExp(spacePrefix+'/Cention/web/', 'g');
			var addWebroot = RegExp('/Cention/web/', 'g');

			if(message.text.search("/Cention/web/chat/client") > -1) {
				message.text = message.text.replace(addBaseURL,Public.baseURL+spacePrefix+'/Cention/web/');
				return;
			}
			if(spacePrefix !== ''){
				if(message.text.search(Public.baseURL+spacePrefix+"/Cention/web/file") > -1) {
					return;
				}else if (message.text.search(spacePrefix+"/Cention/web/file") > -1) {
					return;
				}else{
					var addWebroot = RegExp('/Cention/web/', 'g');
					if(message.text.search("/Cention/web/file") > -1) {
						message.text = message.text.replace(addWebroot,Public.baseURL+spacePrefix+'/Cention/web/');
						return;
					}
				}
			}else{
				if(message.text.search(Public.baseURL+"/Cention/web/file") > -1) {
					return;
				}else if(message.text.search("/Cention/web/file") > -1) {
					message.text = message.text.replace(addWebroot,Public.baseURL+'/Cention/web/');
					return;
				}else{
					var addWebroot = RegExp('/Cention/web/', 'g');
					if(message.text.search("/Cention/web/file") > -1) {
						message.text = message.text.replace(addWebroot,Public.baseURL+'/Cention/web/');
						return;
					}
				}
			}
		}
		if (!isJSONPayload(message.text)) return;
		try {
			j = that.parseJSONMessage(message.text);
			switch(j.event) {
				case "FILE_UPLOAD":
					message.text = renderFileUploadMessage(j);
					break;
				case "TEMPLATE_BUTTON":
					message.text = renderTemplateButtonMessage(j);
					break;
				case "BUTTON_POSTBACK":
					message.text = renderButtonPostbackMessage(j);
					break;
			}
		} catch (e) {
			message.text = orig;
		}
	};
	if (USING_WEBSOCKET) {
		that.wsDispatcher = function(action) {
			if (action && action.type == 'WS_CONNECTED') {
				if (!Public.sessionSecret) {
					that.register(that.parameters);
				} else {
					that.reconnect();
				}
			}
		};

		that.setupClientSocketListeners = function() {
			ws.SetEventListener("register", function(msg, cb) {
				that.connected = true;
				if (msg.status == 'OFFLINE') {
					if(offlineChat) {
						invokeAction('offline chat', msg);
						offlineChatRegistered = true;
					} else {
						Public.disconnect();
					}
				} else if (msg.status == 'CLOSED') {
					invokeAction('chat closed');
				} else {
					Public.sessionSecret = msg.sessionSecret;
					Public.sessionId = msg.sessionId;
					Public.areaId = msg.areaId;
					Public.activeSession = true;
					Public.haveCookie = msg.haveCookie;

					invokeAction('connect', msg);
				}
				cb('client ' + msg.status);
			});
			ws.SetEventListener("unsentmessages", function() {
				that.emitMessage();
			});
			ws.SetEventListener("status", function(cb) {
				var response = {
					forcelongpolling: false,
				};
				cb(response);
			});
			ws.SetEventListener("agent unavailable", function() {
				invokeAction('agent unavailable');
			});
			ws.SetEventListener("queue full", function() {
				invokeAction('queue full');
			});
			ws.SetEventListener("CHAT_NEW_MESSAGE", function(cm, ack) {
				var data
				, i
				, messageId
				, message
				, ackedids = []
				;
				if (!cm) return;

				data = cm.session;
				data.chat = cm.messages;
				for (i=0; i<cm.messages.length; i++) {
					message = cm.messages[i];
					if (message.fromClient) {
						if (!that.agentSeen.hasOwnProperty(message.id)) {
							that.agentSeen[message.id] = false;
						}
						message.sender = 'CLIENT';
						message.read = message.id <= data.amaxId; // mak: TODO amaxId
						that.maybeFormat(message);
					} else if (message.aid > 0) {
						data.agent = message.agent;
						message.sender = 'AGENT';
						that.maybeFormat(message);
					} else {
						var oSysMsg = SystemMessage.format(message,0,0);
						message.sender = oSysMsg.sender;
						message.text = oSysMsg.text;
						message.fromClient = oSysMsg.fromClient;
					}
				}
				actionHandlers['chat message'].call(null, data);
				for (i=0; i<cm.messages.length; i++) {
					messageId = cm.messages[i].id;
					// Remember that we have received this message
					if (isInt(messageId)) {
						that.mcount[messageId] = true;
					}

				}

				if (that.maxAcked < data.amaxId) { // mak: data.amaxId TODO
					for (var id in that.agentSeen) {
						if (that.agentSeen[id]) {
							continue;
						}
						if (id <= data.amaxId) { // mak: data.amaxId TODO
							that.agentSeen[id] = true;
							ackedids.push(id);
						}
					}
					that.maxAcked = data.amaxId; // mak: data.amaxId TODO

					if (ackedids.length > 0) {
						invokeAction('message acked', {ids:ackedids});
					}
				}
				ack(Object.keys(that.mcount).length, cm.messages.length);
			});
			ws.SetEventListener("agent preview", function(data) {
				invokeAction('agent preview', data);
			});
			ws.SetEventListener("queue", function(n) {
				if (that.queuePosition == -1 || n < that.queuePosition) {
					that.queuePosition = n;
					invokeAction('queue', n);
				}
			});
			ws.SetEventListener("finish chat session", function(data) { // data.closedBy = "client", "agent" or "expired"
				Public.activeSession = false;
				Public.disconnect();
				if (SHOW_STATUS_IF_EXPIRED== true && data && data.closedBy) {
					invokeAction('finish chat session', data.closedBy);
				} else {
					invokeAction('finish chat session');
				}
			});
			ws.SetEventListener("session reconnect", function(msg) {
				that.connected = true;
				switch (msg.status) {
					case 'TRY_AGAIN':
						setTimeout(function(){
							log('Reconnection failed, retry sending reconnect..');
							that.reconnect();
						},4000);
						break;
					case 'OFFLINE':
						invokeAction('chat closed');
						break;
				}
			});
			ws.SetEventListener("message acked", function(msg) {
				var i;
				for (i=0;i<msg.ids.length;i++) {
					if (that.maxAcked < msg.ids[i]) {
						that.maxAcked = msg.ids[i];
					}
					that.agentSeen[msg.ids[i]] = true;
				}
				invokeAction('message acked', msg);
			});
			ws.SetEventListener("video-answer", function(data) {
				var errMsg = Public.I("Video call request already accepted in another window");
				onCreateAnswerSuccess(data, Public.sessionId, ws, errMsg);
			});
			ws.SetEventListener("video-offer", function(data) {
				var alertCallMsg = Public.I("The agent is requesting a video call with you, do you want to accept?");
				if(!data.init) {
					alertCallMsg = Public.I("The agent wants to share screen with you, do you want to accept?");
				}
				var clientAcceptance = confirm(alertCallMsg);
				if( c3jQuery("#CentionChatVideoCallWrapper").css('display') == 'none' ){
					ws.SendEvent('video-chat-error', {
						sessionId: Public.sessionId,
						type: "video-chat-error",
						data: {type: "INVALID_CLIENT_FEATURE", msg: Public.I("Client is not enabling video chat.")}
					});
				} else {
					if(data.init) {
						if (clientAcceptance == true) {
							//Client Answer agent
							createAnswerForAgent(data, Public.sessionId, ws);
						} else {
							ws.SendEvent('CLIENT_REJECT_VIDEO_CALL', {
								sessionId: Public.sessionId,
								type: "CLIENT_REJECT_VIDEO_CALL",
								data: Public.I("Client is not accepting video chat.")
							});
						}
					} else {
						setAgentDisplayOffer(true);
						if (clientAcceptance == true) {
							c3jQuery("#CentionChatVideoFrame").show();
							handleReceiveAgentDisplay(data, Public.sessionId, ws);
						} else {
							console.log("you have rejected screen sharing offer from agent");
							handleRejectScreenShareOffer(Public.sessionId, ws);
						}
					}
				}
			});
			ws.SetEventListener("hang-up", function(data) {
				hangupAction(Public.sessionId, ws, true);
			});
			ws.SetEventListener("video-reject", function(data) {
				var cancelAlertMsg = Public.I("The agent is unable to accept video call at the moment");
				cancelCallAction(cancelAlertMsg);
			});
			ws.SetEventListener("agent-mute-video", function(data) {
				handleAgentMute();
			});
			ws.SetEventListener("agent-unmute-video", function(data) {
				handleAgentUnMute();
			});
			ws.SetEventListener("agent-disable-video", function(data) {
				handleAgentDisableVideo();
			});
			ws.SetEventListener("agent-enable-video", function(data) {
				handleAgentEnableVideo();
			});
			ws.SetEventListener("new-ice-candidate", function(data) {
				if(agentDisplayOffer || clientDisplayOffer || data.agentScreenShare) {
					//screen sharing ICE received
					handleDisplayICECandidateFromAgent(data, true, ws);
				} else {
					//video call ICE received
					handleICECandidateMsgFromAgent(data, true, ws);
				}
			});
			ws.SetEventListener("AGENT_STOP_SCREEN_SHARE", function(data) {
				handleStopAgentScreenShare();
			});
			ws.SetEventListener("AGENT_REJECT_SCREEN_SHARE", function(data) {
				alert(Public.I("The agent is unable to accept screen sharing at the moment"));
				cancelScreenShareAction(Public.sessionId, ws);
			});
			ws.SetEventListener("AGENT_STOP_REMOTE_SCREEN_SHARE", function(data) {
				stopScreenSharing(Public.sessionId, false, true, ws);
			});
			ws.SetEventListener("AGENT_VIDEO_BLUR_START", function(data) {
				startAgentVideoBlur(Public.sessionId);
			});
			ws.SetEventListener("AGENT_VIDEO_BLUR_STOP", function(data) {
				stopAgentVideoBlur(Public.sessionId);
			});
			ws.SetEventListener("AGENT_VIDEO_BG_START", function(data) {
				startAgentVideoBg(data.sessionId, data.img, ws);
			});
			ws.SetEventListener("AGENT_VIDEO_BG_CHANGE", function(data) {
				setBackground(data.img);
			});
			ws.SetEventListener("AGENT_VIDEO_BG_CHANGE_PROGRESS", function(data) {
				if(data.status) {
					c3jQuery("#vid-self-loading").hide();
				} else {
					c3jQuery("#vid-self-loading").show();
				}
			});
			ws.SetEventListener("AGENT_VIDEO_BG_STOP", function(data) {
				stopAgentVideoBg(Public.sessionId);
			});
			ws.SetEventListener("RTC_SERVER_CONF", function(data) {
				updateTurnDInfos(data);
			});
			ws.SetEventListener("AGENT_INIT_CO_BROWSING_TEST", function(data) {
				receiveCoBrowseRequest(data);
			})
			ws.SetEventListener("AGENT_INIT_CO_BROWSING", function(data) {
				var alertCallMsg = Public.I("Agent is requesting a co-browsing session with you, do you want to accept?");
				var clientAcceptance = confirm(alertCallMsg);
				if( c3jQuery("#CentionChatVideoCallWrapper").css('display') == 'none' ){
					ws.SendEvent('video-chat-error', {
						sessionId: Public.sessionId,
						type: "video-chat-error",
						data: {type: "INVALID_CLIENT_FEATURE", msg: Public.I("Client is not enabling video chat.")}
					});
				} else {
					if (clientAcceptance == true) {
						//Client Answer agent
						createCoBrowsingForAgent(data, Public.sessionId, ws);
					} else {
						/* ws.SendEvent('CLIENT_REJECT_VIDEO_CALL', {
							sessionId: Public.sessionId,
							type: "CLIENT_REJECT_VIDEO_CALL",
							data: Public.I("Client is not accepting video chat.")
						}); */
						console.log("Client reject co browsing session", Public.sessionId);
					}
				}
			});
			//^ These events are from agent
		}
	}
	that.bindEvents = function(socket, parameters) {
		socket.on('unsentmessages', function(){
			that.emitMessage();
		});
		socket.on('status', function(cb) {
			// Don't put any unicode characters in response - it
			// won't work on some versions of IE.
			var response = {
				forcelongpolling: socket.forcedLongPolling
			};
			cb(response);
		});
		socket.on('pong', function() {
			that.io.lastPong = (new Date()).getTime();
		});

		socket.on('connect_error',function(err){
			invokeAction('connect_error', err);
		});

		socket.on('connect',function(){
			if (!Public.sessionSecret) {
				that.register(parameters);
			} else {
				that.reconnect();
			}
		})

		socket.onJson('register', function (msg, cb) {
			that.connected = true;
			if (msg.status == 'OFFLINE') {
				if(offlineChat) {
					invokeAction('offline chat');
					offlineChatRegistered = true;
				} else {
					Public.disconnect();
				}
			} else if (msg.status == 'CLOSED') {
				invokeAction('chat closed');
			} else {
				Public.sessionSecret = msg.sessionSecret;
				Public.sessionId = msg.sessionId;
				Public.areaId = msg.areaId;
				Public.activeSession = true;
				Public.haveCookie = msg.haveCookie;

				invokeAction('connect', msg);
			}
			cb('client ' + msg.status);
		});

		socket.on('agent unavailable', function() {
			invokeAction('agent unavailable');
		});

		socket.on('queue full', function() {
			invokeAction('queue full');
		});

		socket.onJson('chat message', function(cm, ack) {
			var data
			, i
			, messageId
			, message
			, ackedids = []
			;
			if (!cm) return;

			data = cm.session;
			data.chat = cm.messages;
			var agentIds = cm.session.agentIds;
			for (i=0; i<cm.messages.length; i++) {
				message = cm.messages[i];
				if (message.fromClient) {
					if (!that.agentSeen.hasOwnProperty(message.id)) {
						that.agentSeen[message.id] = false;
					}
					message.sender = 'CLIENT';
					message.read = message.id <= data.amaxId;
					that.maybeFormat(message);
				} else if (message.aid > 0) {
					message.sender = 'AGENT';
					that.maybeFormat(message);
				} else {
					var oSysMsg = SystemMessage.format(message,data.clientId,agentIds);
					message.sender = oSysMsg.sender;
					message.text = oSysMsg.text;
					message.fromClient = oSysMsg.fromClient;
				}
			}
			actionHandlers['chat message'].call(null, data);
			for (i=0; i<cm.messages.length; i++) {
				messageId = cm.messages[i].id;
				// Remember that we have received this message
				if (isInt(messageId)) {
					that.mcount[messageId] = true;
				}

			}

			if (that.maxAcked < data.amaxId) {
				for (var id in that.agentSeen) {
					if (that.agentSeen[id]) {
						continue;
					}
					if (id <= data.amaxId) {
						that.agentSeen[id] = true;
						ackedids.push(id);
					}
				}
				that.maxAcked = data.amaxId;

				if (ackedids.length > 0) {
					invokeAction('message acked', {ids:ackedids});
				}
			}
			ack(Object.keys(that.mcount).length, cm.messages.length);
		});

		socket.onJson('agent preview', function(data) {
			invokeAction('agent preview', data);
		});

		socket.on('queue', function(n) {
			if (that.queuePosition == -1 || n < that.queuePosition) {
				that.queuePosition = n;
				invokeAction('queue', n);
			}
		});

		socket.on('finish chat session', function(data) {
			Public.activeSession = false;
			Public.disconnect();
			if (SHOW_STATUS_IF_EXPIRED == true && data && data.closedBy) {
				invokeAction('finish chat session', data.closedBy);
			} else {
				invokeAction('finish chat session');
			}
		});

		socket.onJson('session reconnect', function(msg) {
			that.connected = true;
			switch (msg.status) {
				case 'TRY_AGAIN':
					setTimeout(function(){
						log('Reconnection failed, retry sending reconnect..');
						that.reconnect();
					},4000);
					break;
				case 'OFFLINE':
					invokeAction('chat closed');
					break;
			}
		});

		socket.onJson('message acked', function(msg) {
			var i;
			for (i=0;i<msg.ids.length;i++) {
				if (that.maxAcked < msg.ids[i]) {
					that.maxAcked = msg.ids[i];
				}
				that.agentSeen[msg.ids[i]] = true;
			}
			invokeAction('message acked', msg);
		});
	};

	function invokeAction( action ) {
		var handler = actionHandlers[action];
		if( handler ) {
			return handler.apply(self, Array.prototype.slice.apply(arguments, [1]), Array.prototype.slice.apply(arguments, [2]));
		}
	};

	Public.registerAction = function(action, handler) {
		actionHandlers[action] = handler;
		if (action === 'message sent') {
			that.updateUnsentMessage = handler;
		}
	};

	that.io = {
		forcelongpolling: false,
		pongTimeout: 60*1000,
		lastPong: 0,
		watchDogEnabled: false
	};
	that.forceLongPolling = function() {
		if (!that.io.forcelongpolling) {
			if (chatSock) {
				chatSock.disconnect();
				chatSock = null;
			}
			that.io.forcelongpolling = true;
			Public.connect();
		}
	};
	that.connected = false; // true when we have successfully registered or reconnected
	that.reconnectTimeoutId = null;

	that.watchDog = function() {
		var now;

		if (!Public.activeSession) {
			return;
		}

		if (that.io.forcelongpolling) {
			return;
		}

		now = (new Date()).getTime();
		if ((now - that.io.lastPong) > that.io.pongTimeout) {
			that.forceLongPolling();
		} else {
			setTimeout(that.watchDog, that.io.pongTimeout);
		}
	};

	Public.connect = function(parameters, ioparams) {
		var ioOpt
		    , forcedLongPolling = false
		    ;
		that.connected = false;
		if (USING_WEBSOCKET) {
			if (parameters) {
				if (!Public.areaId) {
					Public.areaId = parameters.area;
				}
				if (parameters.langCode) {
					Public.langCode = parameters.langCode;
				}
				if(!parameters.baseURL) {
					parameters.baseURL = Public.baseURL;
				}
			}
			parameters.offlineChat = false;
			if(ALLOW_OFFLINE_CHAT) {
				parameters.offlineChat = offlineChat;
			}
			if(spacePrefix){
				parameters.baseURL = parameters.baseURL + spacePrefix;
			}
			var hostname = parameters.baseURL.replace(/(^\w+:|^)\/\//, '');
			ws = Socket('wss://'+hostname+"/external.ws");

			that.parameters = parameters;
			that.setupClientSocketListeners();
			ws.Dispatcher = that.wsDispatcher;
			ws.Connect();
			return;
		}
		if (!chatSock) {
			ioOpt = {
				path: spacePrefix + '/socket/external.io'
			};
			if (ioparams) {
				if (ioparams.forcelongpolling || that.io.forcelongpolling) {
					forcedLongPolling = true;
					ioOpt.transports = ['polling'];
				}
				if (typeof ioparams.multiplex !== 'undefined') {
					ioOpt.multiplex = ioparams.multiplex;
				}
			}
			if (parameters) {
				if (!Public.areaId) {
					Public.areaId = parameters.area;
				}
				if (parameters.langCode) {
					Public.langCode = parameters.langCode;
				}
				if(!parameters.baseURL) {
					parameters.baseURL = Public.baseURL;
				}
			}
			chatSock = SockWrap(io(Public.baseURL || window.location.origin, ioOpt), forcedLongPolling);
			that.bindEvents(chatSock, parameters);

			if (!that.io.forcelongpolling && !that.io.watchDogEnabled) {
				that.io.watchDogEnabled = true;
				setTimeout(that.watchDog, that.io.pongTimeout);
			}
		} else {
			chatSock.connect();
			if (!Public.sessionSecret) {
				that.register(parameters);
			} else {
				that.reconnect();
			}
		}

		clearTimeout(that.reconnectTimeoutId);
		that.reconnectTimeoutId = setTimeout(function() {
			if (that.connected) {
				return;
			}
			// TODO force long polling if there are many reconnection attempts
			that.forceLongPolling();
		}, 60*1000);
	};

	//Initiate video signaling
	Public.registerVideoCall = function(action, user, uiHandler) {
		if(action) {
			if (action === 'video ready') {
				//send signal to agent that client ready to accept/offer video call
				if (USING_WEBSOCKET) {
					ws.SendEvent("video-ready", {
						sessionId: Public.sessionId,
						type: "video-ready"
					});
					ws.SendEvent("RTC_SERVER_CONF_REQUEST", {
						sessionId: Public.sessionId,
						type: "RTC_SERVER_CONF_REQUEST"
					});
					uiHandler();
				}
			} else if (action === 'start camera') {
				startAction();
				uiHandler();
			} else if (action === 'video init') {
				//startAction();
				uiHandler();
			} else if (action === 'video offer') {
				callAction(ws, Public.sessionId, user);
				uiHandler();
			}
			else if (action === 'video-answer') {
				uiHandler();
			} else if(action === 'hangup') {
				hangupAction(Public.sessionId, ws, false);
				uiHandler();
			} else if(action === 'stopAudio') {
				stopAudio(Public.sessionId, ws);
				uiHandler();
			} else if(action === 'startAudio') {
				startAudio(Public.sessionId, ws);
				uiHandler();
			} else if(action === 'stopWebCam') {
				stopWebCam(Public.sessionId, ws);
				uiHandler();
			} else if(action === 'startWebCam') {
				reEnableWebCam(Public.sessionId, ws);
				uiHandler();
			} else if(action === 'start-screen-sharing') {
				startScreenSharing(Public.sessionId, ws);
				uiHandler();
			} else if(action === 'stop-screen-sharing') {
				stopScreenSharing(Public.sessionId, false, false, ws);
				uiHandler();
			} else if(action === 'stop-agent-screen-sharing') {
				handleStopAgentScreenShare(Public.sessionId, true, ws);
				uiHandler();
			} else if(action === 'start-screen-capture') {
				startScreenSharing(Public.sessionId, ws, true);
				uiHandler();
			} else if(action === 'stop-screen-capture') {
				stopScreenSharing(Public.sessionId, false, false, ws);
				uiHandler();
			}
		}
	};

	//End video calling code

	//Start audio calling code
	Public.registerCallAction = function(action, data, uiHandler) {
		if(action === 'CALLBACK_REQUEST_NOW') {
			const payload = { type: action, sessionId: Public.sessionId, info: data };
			ws.SendEvent(action, payload);
			uiHandler();
		} else if(action === 'CALLBACK_REQUEST_SCHEDULE') {
			const payload = { type: action, sessionId: Public.sessionId, info: data };
			ws.SendEvent(action, payload);
			uiHandler();
		}
	}
	//End audio calling code

	Public.tryResume = function(cb) {
		var url = Public.baseURL + spacePrefix + "/socket/external.api/canresume";
		c3jQuery.ajax({
			url: url,
			type: 'GET',
			xhrFields: {withCredentials: true}
		})
		.done(function(msg){
			if (msg.error) {
				cb(msg);
			} else {
				Public.connect({ callback: cb, resume: true, area: -1 });
			}
		})
		.fail(function(jqXHR, textStatus, errorThrown) {
			log("error loading " + url, textStatus, errorThrown);
			cb({'error':'resume failed'});
		});
	};

	Public.disconnect = function() {
		Public.activeSession = false;

		//disconnect video call if there's till ongoing
		if(typeof onVideoCall !== "undefined" && onVideoCall) {
			hangupAction(Public.sessionId, ws, false, true);
		}

		//stopped screen sharing session if ongoing
		if(typeof clientScreenShareMode !== "undefined" && clientScreenShareMode) {
			stopScreenSharing(Public.sessionId, true, false, ws);
		}

		if (USING_WEBSOCKET) {
			// do not disconnect websocket connection (always connected)
			// ws.Disconnect();
			return;
		}
		chatSock.disconnect();
		chatSock = null;


	};

	that.unsentMessageId = 0;
	that.unsentMessages = [];
	that.emitMessage = function() {
		var um;

		if (that.unsentMessages.length == 0) {
			return;
		}

		um = that.unsentMessages[0];
		if (USING_WEBSOCKET) {
			ws.SendEvent('chat message', {
				id: um.id,
				message: um.message,
				mcount: Object.keys(that.mcount).length
			}, function(ack) {
				if (ack.error) {
					switch(ack.error) {
						case 'ERR_NIL_CHAT':
							that.reconnect();
							// fall through
						case 'ERR_TRY_AGAIN':
							setTimeout(that.emitMessage, 4000);
							return;
						case 'ERR_INVALID_MESSAGE_ID':
							// This should not happen
							throw new Error("received ERR_INVALID_MESSAGE_ID: " + um.id);
						case 'ERR_ALREADY_SEEN':
							if (that.unsentMessages.length > 0 && um.id == that.unsentMessages[0].id) {
								um = that.unsentMessages.shift();
								um.messageId = 'already-seen-' + um.id;
								that.mcount[um.messageId] = true;
								that.updateUnsentMessage && that.updateUnsentMessage({
									id: um.messageId,
									umid: um.id,
									sentHuman: 'NN:NN' // FIXME make back end return the timestamp
								});
							}
							break;
						default:
							throw new Error("unhandled ack.error '" + ack.error + "'");
					}
				} else {
					um = that.unsentMessages.shift();
					if (um.id == ack.umid) {
						um.messageId = ack.id;
						um.sentHuman = ack.sentHuman;
						if (that.updateUnsentMessage) {
						}
						that.updateUnsentMessage && that.updateUnsentMessage({
							id: ack.id,
							umid: ack.umid,
							sentHuman: ack.sentHuman
						});
						if (!that.agentSeen.hasOwnProperty(ack.id)) {
							that.agentSeen[ack.id] = false;
						}
					}
				}
				if (that.unsentMessages.length > 0) {
					that.emitMessage();
				}
			});
			return;
		}
		chatSock.emit('chat message', {
			id: um.id,
			message: um.message,
			mcount: Object.keys(that.mcount).length
		}, function(ack) {
			if (ack.error) {
				switch(ack.error) {
					case 'ERR_NIL_CHAT':
						that.reconnect();
						// fall through
					case 'ERR_TRY_AGAIN':
						setTimeout(that.emitMessage, 4000);
						return;
					case 'ERR_INVALID_MESSAGE_ID':
						// This should not happen
						throw new Error("received ERR_INVALID_MESSAGE_ID: " + um.id);
					case 'ERR_ALREADY_SEEN':
						if (that.unsentMessages.length > 0 && um.id == that.unsentMessages[0].id) {
							um = that.unsentMessages.shift();
							um.messageId = 'already-seen-' + um.id;
							that.mcount[um.messageId] = true;
							that.updateUnsentMessage && that.updateUnsentMessage({
								id: um.messageId,
								umid: um.id,
								sentHuman: 'NN:NN' // FIXME make back end return the timestamp
							});
						}
						break;
					default:
						throw new Error("unhandled ack.error '" + ack.error + "'");
				}
			} else {
				um = that.unsentMessages.shift();
				if (um.id == ack.umid) {
					um.messageId = ack.id;
					um.sentHuman = ack.sentHuman;
					that.updateUnsentMessage && that.updateUnsentMessage({
						id: ack.id,
						umid: ack.umid,
						sentHuman: ack.sentHuman
					});
					if (!that.agentSeen.hasOwnProperty(ack.id)) {
						that.agentSeen[ack.id] = false;
					}
				}
			}

			if (that.unsentMessages.length > 0) {
				that.emitMessage();
			}
		});
	};

	that.getNewMessageId = function() {
		var timestamp = (new Date()).getTime();

		that.unsentMessageId++;
		return 'c-' + timestamp + '-' + that.unsentMessageId;
	};
	that.addUnsentMessage = function(message, offline) {
		var timestamp = (new Date()).getTime();
		var um
		    , id = that.getNewMessageId()
		    ;
		um = {
			message: message,
			id: id,
			timeSent: timestamp
		};
		if(!offline || onlineChatRegistered) {
			that.unsentMessages.push(um);
		}
		if (Public.activeSession) {
			if (that.unsentMessages.length == 1) {
				that.emitMessage();
			}
		}
		return um;
	};

	Public.message = function(message, offline) {
		return that.addUnsentMessage(message, offline);
	};

	Public.offlineMessage = function(eid, message, sent) {
		if (USING_WEBSOCKET) {
			ws.SendEvent('offline message', {
				eid: eid,
				area: Public.areaId,
				message: message,
				sent: sent,
			});
			return;
		}
	};

	//registering a chat message directly
	Public.registerNewChat = function(params) {
		that.register(params);
	};

	Public.preview = function(message) {
		if (Public.activeSession) {
			if (USING_WEBSOCKET) {
				ws.SendEvent('preview', message);
				return;
			}
			chatSock.emit('preview', message);
		}
	};

	that.reconnect = function() {
		if (USING_WEBSOCKET) {
			ws.SendEvent('session reconnect', {
				area: Public.areaId,
				sessionId: Public.sessionId,
				sessionSecret: Public.sessionSecret,
				langCode: Public.langCode
			});
			return;
		}
		chatSock.emit('session reconnect', {
			area: Public.areaId,
			sessionId: Public.sessionId,
			sessionSecret: Public.sessionSecret,
			langCode: Public.langCode
		});
	};

	Public.close = function(offline) {
		if(onlineChatRegistered || !offlineChatRegistered) {
			if (USING_WEBSOCKET) {
				ws.SendEvent('close chat');
				return;
			}
			chatSock.emit('close chat');
		}
	};

	that.register = function(parameters) {
		if (parameters){
			if (parameters.resume) {
				if (USING_WEBSOCKET) {
					ws.SendEvent('resume', {href:window.location.href,trackClientUrl:that.trackClientUrl}, function(msg) {
						that.connected = true;
						if (msg.error) {
							parameters.callback && parameters.callback(msg);
							return;
						}
						Public.sessionSecret = msg.sessionSecret;
						Public.sessionId = msg.sessionId;
						Public.areaId = msg.areaId;
						Public.activeSession = true;
						if (msg.langCode) {
							Public.langCode = msg.langCode;
						}
						parameters.callback && parameters.callback(msg);
						ws.SendEvent('sendchat', "resume existing chat", function(ack) {
							if (ack.error) {
								log('sendchat error', ack.error);
							}
						});
					});
					return;
				}
				chatSock.emit('resume', {href:window.location.href,trackClientUrl:that.trackClientUrl}, function(msg) {
					that.connected = true;
					if (msg.error) {
						chatSock.disconnect();
						chatSock = null;
						parameters.callback && parameters.callback(msg);
						return;
					}
					Public.sessionSecret = msg.sessionSecret;
					Public.sessionId = msg.sessionId;
					Public.areaId = msg.areaId;
					Public.activeSession = true;
					if (msg.langCode) {
						Public.langCode = msg.langCode;
					}
					parameters.callback && parameters.callback(msg);
					chatSock.emit('sendchat', "resume existing chat", function(ack) {
						if (ack.error) {
							log('sendchat error', ack.error);
						}
					});
				});
			} else {
				parameters.href = window.location.href;
				parameters.trackClientUrl = that.trackClientUrl;
				if (USING_WEBSOCKET) {
					if(!offlineChatRegistered) {
						ws.SendEvent('register', parameters);
					}else {
						ws.SendEvent('register', parameters);
						onlineChatRegistered = true;
					}
					return;
				}
				if(!offlineChatRegistered) {
					chatSock.emit('register', parameters);
				}
			}
		}
	};

	that.isWithinSizeLimit = function(estFileSize){
		var maxFileAllowed = CentionChatStatus.feature['chat.max-attachment-size'];
		if(typeof maxFileAllowed === 'undefined' || maxFileAllowed <= 0){
			return true // don't care about the file limits
		}
		if(estFileSize > maxFileAllowed) {
			return false;
		}
		return true;
	};

	that.JSONMessage = function(j) {
		return JSON_ENCODED + JSON.stringify(j);
	};

	that.parseJSONMessage = function(text) {
		return JSON.parse(text.replace(JSON_ENCODED, ''));
	};

	var loader =  document.getElementById('CentionUploadloader');
	Public.attachFile = function (file, callback) {
		if(loader) {
			loader.style.display = "block";
		}
		if (!file || typeof (file) === 'undefined') return;
		if (!that.isWithinSizeLimit(file.size)) {
			alert(Public.I("The uploaded file has exceeded the max allowed size."));
			loader.style.display = "none";
			return;
		}
		processFile(file, callback);
	};

	function processFile(file, callback) {
		var xhr =[];
		var formData = new FormData();
		var boundary = Math.floor(Math.random() * 6)+ '0'+ Math.floor(''+new Date() / 1000) ;
		formData.append("uploadfile", file);
		formData.append( 'random', parseFloat(boundary));
		formData.append( 'session', Public.sessionId);
		formData.append( 'area', Public.areaId);
		formData.append('sessionSecret', Public.sessionSecret);
		xhr[0] = new XMLHttpRequest();
		xhr[0].open("POST", Public.baseURL + spacePrefix + "/Cention/web/chat/client/uploadAttachment");
		xhr[0].onreadystatechange = function(ev){
			if (ev.target.readyState != 4) {
				return;
			}
			if (ev.target.status != 200) {
				alert(Public.I("We're sorry, an error occurred while uploading the file."));
				return;
			}
			var ro = JSON.parse(ev.target.responseText);
			/*Send callback to parent for every file upload*/
			if (USING_WEBSOCKET) {
				ws.SendEvent('add attachment', {
					area: Public.areaId,
					sessionSecret: Public.sessionSecret,
					file:ro
				}, function(ack) {
					var j = {
						"event": "FILE_UPLOAD",
						"fileDownload": ack.file.download + "?t=" + Public.sessionSecret,
						"fileName": ack.file.value,
						"sizeHuman": ack.file.sizeHuman,
						"error":ack.error
					}
					, um = Public.message(that.JSONMessage(j))
					;
					callback({
						unsent: true,
						text: renderFileUploadMessage(j),
						fromClient: true,
						sender: "CLIENT",
						id: "unsent-" + um.id
					});
					if(loader) {
						loader.style.display = "none";
					}
				});
				return;
			}
			chatSock.emit('add attachment', {
				area: Public.areaId,
				sessionSecret: Public.sessionSecret,
				file:ro
			}, function(ack) {
				var j = {
					"event": "FILE_UPLOAD",
					"fileDownload": ack.file.download + "?t=" + Public.sessionSecret,
					"fileName": ack.file.value,
					"sizeHuman": ack.file.sizeHuman,
					"error":ack.error
				}
				, um = Public.message(that.JSONMessage(j))
				;
				callback({
					unsent: true,
					text: renderFileUploadMessage(j),
					fromClient: true,
					sender: "CLIENT",
					id: "unsent-" + um.id
				});
			});
		};
		xhr[0].send(formData);
	}

	Public.attachImage = function(fileData) {
		var fd = new FormData();
		var boundary = Math.floor(Math.random() * 6)+ ''+ 1 +''+ Math.floor(''+new Date() / 1000);
		fd.append( 'uploadfile', fileData );
		fd.append( 'random', boundary);
		fd.append( 'area', Public.areaId);
		fd.append( 'session', Public.sessionId);
		fd.append( 'sessionSecret', Public.sessionSecret);

		return c3jQuery.ajax({
			url: Public.baseURL + spacePrefix + "/Cention/web/chat/client/uploadTempAttachment",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
		});
	};

	Public.moveImage = function(fileIds,fileNames) {
		var fd = new FormData();
		fd.append( 'fileIds', fileIds);
		fd.append( 'fileNames', fileNames);
		fd.append( 'session', Public.sessionId);

		return c3jQuery.ajax({
			url: Public.baseURL + spacePrefix + "/Cention/web/chat/client/moveTempAttachment",
			data: fd,
			processData: false,
			contentType: false,
			type: 'POST',
		});
	};

	function areaQuery(areaId, report, path, callback) {
		var url = Public.baseURL + spacePrefix + "/socket/external.api" + path;
		var allowOfflineChat = "false"; //not allowing offline chat by default
		if (ALLOW_OFFLINE_CHAT) {
			allowOfflineChat = "true";
		}
		c3jQuery.ajax({
			url: url,
			data: {area: areaId, report: report, allowOfflineChat: allowOfflineChat},
			type: 'GET',
			xhrFields: {withCredentials: true}
		})
		.done(function(data){ callback(data); }.bind(this))
		.fail(function(jqXHR, textStatus, errorThrown) {
			log("error loading " + url, textStatus, errorThrown);
			callback({'error':'chat connection failed'});
		});
	}

	Public.canChat = function(handleData, areaId, report, offline) {
		if(!offline) {
			areaQuery(areaId, report, "/canchat", function(data) {
				if(FOR_THIRD_PARTY_USE) {
					CentionChatStatus.feature = data.feature;
				}
				handleData(data);
			});
		} else {
			ws.SendEvent('can chat', {
				area: areaId,
				report: report
			}, function(ack) {
				handleData(ack);
			});
		}
	};

	Public.resetChat = function() {
		Public.sessionSecret = "";
		Public.sessionId = "";
		Public.activeSession = false;
		that.queuePosition = -1;
		that.maxAcked = 0;
		that.agentSeen = {};
		that.mcount = {};
	};

	return Public;
};
