const WIDGET = 1;
const CLASSIC = 2;
let useFAQChatInChat;
if(typeof FAQ_WITH_CHAT == "undefined" || FAQ_WITH_CHAT == false){
	useFAQChatInChat = false;
} else {
	useFAQChatInChat = true;
}

const SKIN_DEFAULT = 'default';
const SKIN_MODERN = 'modern';

//TODO: use this on all related width
const CHAT_WIDGET_WIDTH = "334px" ;
var CentionChatStatus = {};
//style script
function loadScripts(fileName) {
	var chatWidgetCSS = CloudFrontURL+"/chat/css/"+fileName;
	var widgetStyle = document.createElement("link");
	widgetStyle.setAttribute("rel", "stylesheet");
	widgetStyle.setAttribute("type", "text/css");
	widgetStyle.setAttribute("href", chatWidgetCSS);
	document.head.appendChild(widgetStyle);
	if(useFAQChatInChat){
		document.head.removeChild(widgetStyle); //TO CONFIRM: we remove to use FAQ's css
	}
}
function loadVideoCSS(fileName) {
	var chatWidgetCSS = CloudFrontURL+"/chat/css/"+fileName;
	var widgetStyle = document.createElement("link");
	widgetStyle.setAttribute("rel", "stylesheet");
	widgetStyle.setAttribute("type", "text/css");
	widgetStyle.setAttribute("href", chatWidgetCSS);
	document.head.appendChild(widgetStyle);
}
function setupCentionBaseURLandSpace(baseURL) {
	var urlInfo = {};
	urlInfo.CentionBaseURL = baseURL;
	if ( baseURL.indexOf("cloud.cention.com") || baseURL.indexOf("cloud.cust.cention.se") || baseURL.indexOf("api.cention.com")) {
		const i = baseURL.indexOf("/s/");
		if ( i != -1 ) {
			urlInfo.spacePrefix = baseURL.substr(i);
			urlInfo.CentionBaseURL = baseURL.substr(0, i);
		}
	}
	return urlInfo;
}

//setupCentionBaseURLandSpace(CentionBaseURL);
var uiHTML = 'widget.html';
var urlInfo = setupCentionBaseURLandSpace(CentionBaseURL);
CentionBaseURL = urlInfo.CentionBaseURL;
spacePrefix = urlInfo.spacePrefix;

loadVideoCSS("videoWidget.css")
loadScripts("chatWidget.css");
loadScripts("cention-icon.css");

var CentionChat = function(wrapper, params) {
	c3jQuery(function($) {
		const container = c3jQuery("#"+wrapper);
		const customChatBtn = params.customChatTriggerId;
		var areaId = params.areaId;
		var useDynamicArea = false;
		var translation;
		var hasChannel = false;
		
		if (c3jQuery.isArray(params.areaId)) {
			useDynamicArea = true;
			if (params.areaId.length > 0) {
				areaId = params.areaId[0].id; //auto selected for first time
			}
		}

		if(!spacePrefix){
			spacePrefix = "";
		}

		params.chatWidgetCfg = "";
		if(typeof params.faqWidgetCfg == "undefined"){
			params.faqWidgetCfg = "";
		}
		
		fetchChatConfig(areaId);
		function fetchChatConfig(areaId) {
			const url = CentionBaseURL+spacePrefix+"/cention/chat/chatWidgetConfig";
			c3jQuery.get(url, {a: areaId}).done(function( data ){
				console.log('data - ', data);
				if (data.error) {
					CentionChatBase();
					return;
				}
				if( data.chatWidgetCfg ) {
					params.chatWidgetCfg = data.chatWidgetCfg;
					CentionChatBase();
				}
			}.bind(this)).fail(function(error) {
				CentionChatBase();
			});
		}
		var uiSkin = SKIN_DEFAULT;
		function CentionChatBase() {
			var uiStyle = "";
			if(typeof params.ui != "undefined"){
				if(typeof params.ui.style != "undefined"){
					uiStyle = params.ui.style;
				}else {
					uiStyle = WIDGET;
				}
			}else {
				uiStyle = WIDGET;
			}

			if(typeof params.chatWidgetCfg.skin != "undefined") {
				if(params.chatWidgetCfg.skin == SKIN_MODERN) {
					uiSkin = SKIN_MODERN;
					uiHTML = 'widgetModern.html';
				}
			}
			
			if(typeof params.chatWidgetCfg.ui != "undefined") {
				if(params.chatWidgetCfg.ui.enableCallbackRequest) {
					loadScripts("jquery.datetimepicker.css");
				}
			}

			if (typeof params.translation !== 'object') {
				if(isJsonStringObj(params.faqWidgetCfg.translation)) {
					translation = JSON.parse(params.faqWidgetCfg.translation);
				}else if(isJsonStringObj(params.chatWidgetCfg.translation)) {
					translation = JSON.parse(params.chatWidgetCfg.translation);
				}else {
					translation = {};
				}
			}else{
				translation = params.translation;
			}

			if (!window.console) window.console = {};
			if (!window.console.log) window.console.log = function () { };
			if (!String.prototype.startsWith) {
				String.prototype.startsWith = function(searchString, position){
					position = position || 0;
					return this.substr(position, searchString.length) === searchString;
				};
			}

			if(uiStyle == WIDGET) {
				let widgetFile = "widget";
				if ( uiSkin === SKIN_MODERN ) {
					widgetFile = "widgetModern";
				}
				var standardChatWidgetHTML = CentionBaseURL+spacePrefix+"/cention/chat/"+widgetFile+"?a="+areaId
					+ (params.languageCode?"&lang="+params.languageCode:"");
				
				//fetching html from static.cention.com
				c3jQuery.get(CentionBaseURL+spacePrefix + "/chat/" + uiHTML)
				.then(function(html){
					console.log('html = ', html);
					container.html(html);
					loadWidget();
				});
				function loadWidget() {
					const t0 = performance.now();
					c3jQuery.ajax({
						url : standardChatWidgetHTML,
						dataType: 'json',
						xhrFields: {
							withCredentials: true
						}
					}).done(function(result) {
						console.log('data - ', result.data);
						const t1 = performance.now();
						console.log(`Widget loads took ${t1 - t0} milliseconds.`);
						var assignedText;
						if( params.hooks && params.hooks.onWidgetLoaded ) {
							params.hooks.onWidgetLoaded();
						}
						CentionChatStatus = result.data.CentionChatStatus;
						container.append(CentionChatStatus);
						if(c3jQuery('#CentionChatConnected').html()){
							assignedText = c3jQuery('#CentionChatConnected').html()
							.replace("{AGENT_NAME}",
								'<span id="CentionChatConnectedAgent"></span>');
							c3jQuery('#CentionChatConnected').html(assignedText);
						}
						if(CentionChatStatus && CentionChatStatus.error) {
							c3jQuery("#CentionChat").hide();
							if(useFAQChatInChat){
								c3jQuery("#FAQChatContainer").hide();
								c3jQuery("#ViewPage-Chat").hide();
							}
							console.log("Error: ", CentionChatStatus.error);
						}else {
							loadMiniChat(CentionBaseURL,areaId);
						}
						if(spacePrefix){
							const spaceName = spacePrefix.substring(spacePrefix.lastIndexOf('/') + 1);
							c3jQuery("#CentionChat").addClass(spaceName);
						}
					});
				}
			}
		}

		function isJsonStringObj(text){
			try{
				if(typeof JSON.parse(text) === "object"){
					return true;
				}else{
					return false;
				}
			} catch(error){
				return false;
			}
		}

		function loadMiniChat(baseURL, areaId) {
			const parser = new UAParser();
			const browserData = parser.getResult();
			function isMobile(ua) {
				// detectmobilebrowsers.com JavaScript Mobile Detection Script
				const dataString = ua || navigator.vendor || window.opera;
				return (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(dataString) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(dataString.substr(0, 4)));
			};

			const CentionChat = _CentionChat();
			CentionChat.baseURL = baseURL;
			CentionChat.areaId = areaId;
			if(!spacePrefix){
				spacePrefix = "";
			}
			CentionChat.I = function(text) {
				return translation[text]||text;
			};
			var cookieDisabled = false;
			const _preloaderURL = CloudFrontURL + "/chat/images/chat-loader.gif";
			var _chatIsMinimized = true;
			var useCustomChatTrigger = false;
			var _tempImageAttachments = [];
			const ccs = CentionChatStatus;
			// Use to indicates agent is assigned instead of data.agent field from 'chat message' event
			var agentResponded = false;
			var clientAvatar = "";
			var triggerSatisfaction = true;
			const defaultSkin = SKIN_DEFAULT;
			const defaultChatConfig = {
			// BEGIN_UI
				skin: SKIN_DEFAULT,
				buttonAwayEnable: false, //  buttonAwayEnable is whether to show the away button
				// buttonAwayLink is the link to
				// where it redirected to when clicked
				buttonAwayLink: "#",

				//  Position is where to position the chat widget. Possible values are:
				// 	bottomLeft
				// 	bottomRight
				// 	centerLeft
				// 	centerRight
				position: "bottomRight",
				zIndex: "",

				bottomPosition: 0, // bottomPosition is y position of the widget from the bottom, in pixels

				leftPosition: 0, // leftPosition is x position of the widget from the left side of the page, in pixels
				// Or:
				//rightPosition: 0, // rightPosition is x position of the widget from the right side of the page, in pixels

				extraFieldEnabled: false, // extraFieldEnabled is extra input field for chat start form
				extraFieldMandatory: false, // extraFieldMandatory is to set if the extra field is mandatory
				extraFieldDisplayName: "", // extraFieldDisplayName i extra input field label name

				chatRatingsEnabled: false, // showChatRatings is to enable customer to rate chat


				proactiveEnable: false, // proactiveEnable is whether proactive chat offer should appear
				proactiveDelayTime: 30, // proactiveDelayTime is how long after page load proactive chat offer should be appear
				proactiveTimeAfterDecline: 45, // proactiveTimeAfterDecline is how long after declined offer next offer should appear
				proactiveRepeatedContact: 2, // proactiveRepeatedContact is how many times proactive chat should be appear for visitor
				proactiveTimeout: 20, // proactiveTimeout is how long until proactive chat offer disappears by itself
				proactiveImmediateStart: false, // proactiveImmediateStart is whether proactive chat should start without forcing visitor to enter name/email/etc
				proactivePopupType: 1, //1 : Portrait, 2: Landscape
				proactiveImageURL: '',

				askEULA: false, // askEULA is to ask user to agree to the End User License Agreement before start chatting
				cookieWarning: true, //enable to show CookieWarning
				// hideChatWhenOffline is for not showing the chat widget
				// if or chat is not open and no agent available
				hideChatWhenOffline: false,
				//Hide list of agent's avatars on the initial widget startup page
				hideAgentAvatarList: false,

				showMenuAsDefault: false, // showMenuAsDefault will decide whether to show chat Menu as default before toggle it

				// if areaId option is less than 5, and this is TRUE,
				// it will show all areas by default using radio buttons
				showAllAreaList: false,
				avoidPreselectArea: false,
				// Show contact form when chat is not available
				showContactFormWhenAway: false,
				// Video chat support
				enableVideoChat: false,
				enableCallbackRequest: false,
			// END_UI
			// BEGIN_CSS
				// logo is shown at the top left hand side of the widget
				// if it starts with '<' then it is assumed to be font-awesome icon
				// otherwise it is treated as an image and will be put in <img src="{logo}">
				logo: "<i class='icon-chats'></i>",
				headerColor: "#eee",
				// headerBorderColor: "transparent",
				headerTextColor: "#000",
				mainColor: "#eee", // mainColor is chat widget main body color
				// mainBorderColor: "transparent", // mainBorderColor is chat window border color
				fontColor: "#000", // fontColor is message text color
				warningTextColor: "#da5f62", // warningTextColor is a warning text color inside input/label default to use light red color
				buttonStartColor: "#0000001a", // buttonStartColor is "Start chat" button background color
				buttonStartBorderColor: "transparent", // buttonStartBorderColor "Start chat" button border color
				buttonStartTextColor: "#000000", // buttonStartTextColor is "Start chat" text color
				buttonAwayColor: "#FFFFFF", //  buttonAwayColor is away button background color
				buttonAwayTextColor: "#000000", // buttonAwayTextColor is away button text color
				buttonAwayBorderColor: "#00000033", // buttonAwayBorderColor is away button border color
				buttonAwayCustomClass: "CentionAwayButton",
				buttonRetryTextColor: "#000",
				chatConversationBgColor: "#FFF", //background color of chat conversation body
				chatConversationTextColor: "#000", //overall text color of chat conversation body
				buttonToggleMenuColor: "#FFF", // buttonToggleMenuColor is the background button color that use to hide/expand the chat menu
				//Tofix: rename messageBackground -> chatConversationBgColor
				messageBackgroundColor: "#def8f9", // is CUSTOMER conversation bubble background color
				messageBackgroundColorAgent: "#f2f2f2", //is AGENT conversation bubble background color
				chatConversationInfoTextColor: '#888', //text sender & time from conversationBubble
				messageAwayTextColor: "#000", // messageAwayTextColor is away message text color
				toggleMenuTextColor: "#000", // toggleMenuTextColor is the text color of the buttonToggleMenuColor
				messageSendIconColor: "#000", // messageSendIconColor is the color of the send icon which default to arrow ->
				menuTextColor: "#000",
				footerTextColor: "#000",
				footerLink: "https://cention.com",
				footerText: "Powered by <img src='"+CloudFrontURL+"/img/cention-logo-black_smallest.png' style='width: 40%' />",
				askEULATextColor: "#000",
				feedbackBgColor: "#7fcdffe0",
				feedbackTextColor: "#FFFFFF",
				cookieWarningBgColor: "wheat",
				cookieWarningTextColor: "#da5f62",
				cookieWarningSymbolColor: "#da5f62",
				//feedback ModalBox
				feedbackBoxBgColor: '#FFBF19',
				feedbackBoxTitleColor: "#FFF",
				feedbackBoxTextareaBgColor: "#00000033",
				feedbackBoxTextareaTextColor: "#FFF",
				feedbackBoxBtnBgColor: "#FFF",
				feedbackBoxBtnTextColor: "#555",

				chatConversationTextareaBgColor: "#00000020",
				chatConversationTextareaColor: "#000",
				chatToggleMenuIconsColor: "#000", //color of icons on the footer
				chatToggleMenuIconsTextColor: "#000", //text below the icons

				tickKerningOffset: '-6px', // tickKerningOffset is kerning offset between the two ticks
				tickUnsentColor: 'lightgray',
				tickUnreadColor: 'lightgray',
				tickSentColor: 'green',
				tickReadColor: 'green',
				//proactive dialog color
				proactiveTitleTextColor: '#1f3c56',
				proactiveDescTextColor: '#1f3c56',
				proactiveDialogBgColor: '#FFF',


			// END_CSS

			// BEGIN_HOOKS
				onChatMaximize: function() {
					// This function is called when the chat is maximized
				},
				onChatMinimize: function() {
					// This function is called when the chat is minimized
				},
				onWidgetLoaded: function() {
					// This function is called when the chat widget is loaded
				},
			// END_HOOKS
			}

			const defaultChatText = {
			// BEGIN_TEXT
				// The following attributes names are also used as their respective html element id, so
				// you can inspect the html element on the text that you would like to change in order
				// to find out the corresponding entry to be customized here.
				textButtonStart: "", // textButtonStart is label for Start chat button
				textTitle: 'Chat', // textTitle is title for chat header
				textTitleProactive: 'Need a little help?', // textTitleProactive is title for proactive chat dialog
				// textButtonAway is the label for the link/button for
				// away text which only visible when
				// buttonAwayEnable is set to 'true' and
				// a link added to buttonAwayLink config.
				// This is displayed under textMessageAway text.
				textButtonAway: "Contact us",
				textButtonRetry: "Retry", // textButtonRetry is label for retry button
				textButtonProactiveStart: "Yes", // textButtonProactiveStart is label for Chat Now button in proactive chat dialog
				textButtonProactiveLater: "No", // textButtonProactiveLater is label for Maybe Later button in proactive chat dialog
				textAvailableNote: "We're available",
				textNoSelectArea: "Please select",
				textInputName: "Name", // textInputName is label for name input
				textInputEmail: "Email", // textInputEmail is label for email input
				textInputPhone: "Phone number", // textInputPhone is label for phone input
				textInputQuestion: "Question", // textInputQuestion is label for question input
				textScreenSharing: "You are sharing your screen", //textStatusConnecting is used in the screen preview when chat user share their screen
				textStatusConnecting: "Connecting...",
				textStatusQueue: "Please wait, an agent will assist you shortly ...",
				textStatusConnected: "You have been connected to",
				textMessageQueueing: "Please hold while we find an agent to assist you. Your current queue position is:",
				textMessageCurrentQueue: "Your current queue position is:",
				textMessageAgentBusy: "We are sorry, all our agents are busy at the moment.",
				textMessageAway: "We are sorry, either the chat is closed or we don't have any agents available at the moment.",
				textNameInvalid: "You must enter a valid name",
				textMessageEmailInvalid: "You must enter a valid email address",
				textQuestionInvalid: "You must enter a valid question",
				textMessageChatEnded: "Your chat has ended.",
				textMenuSendFile: "Send file", // textMenuSendFile is label for send file button
				textMenuNewChat: "New chat", // textMenuNewChat is label for new chat button
				textMenuFinishChat: "Finish chat", // textMenuFinishChat is label for finish chat button
				textMenuPrintChat: "Print", // textMenuPrintChat is label for print menu
				textMenuSaveChat: "Save a copy", // textMenuSaveChat is label for save a copy button
				textTickSent: '✓', // textTickSent is indicator for when message is received by the chat server
				textTickRead: '✓', // textTickRead is indicator for when agent receives the message
				// textEULA is the text displayed next to EULA checkbox if enabled through 'askEULA' settings
				textEULA: 'I consent to that information about this chat will be collected and stored in accordance with Data Privacy laws and directives.',
				textCookieWarning: "* Please allow cookie from {CENTION_BASE_URL} to make sure your chat continues if you browse to other pages during the chat.",
				//Satisfaction Feedback
				textSatisfactionMessage: "Enjoying the chat? Please rate it.",
				textSatisfactionReceived: "Thank you!",
				textFeedbackButton: "Leave a comment",
				textCommentBoxTitle: "Leave a comment (optional)",
				textFeedbackBtnSend: "Send",
				textFeedbackBtnCancel: "Cancel",
				//Contact Form
				textContactFormSentNotice: "Thank you <br>Your message has been successfully sent. <br>We will get back to you as soon as possible.",
				//Proactive
				textTitleProactive: "Hello, may I help you?",
				textMessageProactive: "",

				//Video call related
				textVideoCallHeader: "Video",

				//Callback request
				textMsgWhereToCall: "Where would you like me to call you?",
				textMsgCallNow: "Call me on the phone",
				textMsgCallSchedule: "Schedule a call",
				textMsgToCall: "When to call",
				textBtnSend: "Send request",
				textBtnCancel: "Cancel",
				textDateError: "Please select another date or time"

			// END_TEXT
			};

			//UI on Agent avatar
			function createAvatarUI(agents) {
				let counts = 0;
				c3jQuery(".agentAvatar").html("");
				c3jQuery.each(agents, function(k, q) {
					if (counts < 7){
						const imgRand = Math.floor(Math.random() * 5) + 1;
						let agentFaceURL = CloudFrontURL + "/img/avatar-"+imgRand+".png";
						if(q.avatar) {
							agentFaceURL = baseURL+q.avatar;
						}
						c3jQuery(".agentAvatar").append("<img src=\"" +agentFaceURL+"\" title=\"" +q.chatName +"\" alt='Avatar' class='avatar'>");
						counts ++;
					}
					if (counts == 7){
						c3jQuery(".agentAvatar.agentChat").append("<img src=\"" +agentFaceURL+ "\" title=\"" +q.chatName +"\" alt='Avatar' class='avatar'>");
					}
				});
			}

			//GET ALL AGENTS (NON-ACTIVE INLCUDED) AND GENERATE PROFILE PIC
			function generateAgentsAvatar(fd) {
				var xhr = new XMLHttpRequest();
				var url = baseURL + spacePrefix +"/socket/external.api/agentlist?areas=" +CentionChat.areaId;
				xhr.open("GET", url, true);
				xhr.onreadystatechange = function() {
					if (this.readyState == 4 && this.status == 200) {
						var resp = JSON.parse(this.responseText);
						createAvatarUI(resp.agents);
					}
				};
				xhr.send(fd);
			}

			//GET AGENT LIST
			function getAgentList(areaId) {
				var xhr =[];
				var formData = new FormData();
				formData.append( 'area', areaId);
				formData.append( 'includeOffline', false);
				formData.append( 'groupByArea', false);
				xhr = new XMLHttpRequest();
				xhr.open("GET",baseURL + spacePrefix +"/socket/external.api/agentlist?areas=" +areaId+"&who=active");
				xhr.onreadystatechange = function(ev){
					if (ev.target.readyState == 4 && ev.target.status == 200) {
						var ro = JSON.parse(ev.target.responseText);
						if (ro){
							if(ro.agents.length > 0) {
								createAvatarUI(ro.agents);
							} else {
								c3jQuery(".agentStatus > .logged-in").hide();
								// c3jQuery(".agentStatus > .logged-out").show();
								c3jQuery(".agentAvatar").html("");
								generateAgentsAvatar(formData)
							}
						}
					}
				};
				xhr.send(formData);
			}
			//FAQ SKIN CONFIG
			function getCustomSkin() {
				var value = "";
				if(typeof params.skin != "undefined"){
					//check if objects value legit
					if(params.skin) {
						value = params.skin;
					}else {
						value = defaultSkin;
					}
				}else if(typeof params.faqWidgetCfg.skin != "undefined"){
					if(params.faqWidgetCfg.skin){
						value = params.faqWidgetCfg.skin;
					}else {
						value = defaultSkin;
					}
				}else if(typeof params.chatWidgetCfg.skin != "undefined"){
					if(params.chatWidgetCfg.skin){
						value = params.chatWidgetCfg.skin;
					}else {
						value = defaultSkin;
					}
				}else{
					value = defaultSkin;
				}
				return value;
			}
			function getCustomConfig(name) {
				var value = "";
				if(typeof params.css != "undefined"){
					if(typeof name != "undefined" && name == "logo") {
						if(params.css[name]) {
							if(params.css[name][0] == "<"){
								value = params.css[name];
							}else{
								value = "<img src="+params.css[name]+">";
							}
						}else{
							value = defaultChatConfig[name];
						}
					} else{
						if(params.css[name]) {
							value = params.css[name];
						}else {
							value = defaultChatConfig[name];
						}
					}
				}else if(typeof params.faqWidgetCfg.css != "undefined"){
					if(typeof name != "undefined" && name == "logo") {
						if(params.faqWidgetCfg.customize && params.faqWidgetCfg.css[name]) {
							if(params.faqWidgetCfg.css[name][0] == "<"){
								value = params.faqWidgetCfg.css[name];
							}else{
								value = "<img src="+params.faqWidgetCfg.css[name]+">";
							}
						}else{
							value = defaultChatConfig[name];
						}
					} else{
						if(params.faqWidgetCfg.customize && params.faqWidgetCfg.css[name]) {
							value = params.faqWidgetCfg.css[name];
						}else {
							value = defaultChatConfig[name];
						}
					}
				}else if(typeof params.chatWidgetCfg.css != "undefined"){
					if(typeof name != "undefined" && name == "logo") {
						if(params.chatWidgetCfg.customize && params.chatWidgetCfg.css[name]) {
							if(params.chatWidgetCfg.css[name][0] == "<"){
								value = params.chatWidgetCfg.css[name];
							}else{
								value = "<img src="+params.chatWidgetCfg.css[name]+">";
							}
						}else{
							value = defaultChatConfig[name];
						}
					} else{
						if(params.chatWidgetCfg.customize && params.chatWidgetCfg.css[name]) {
							value = params.chatWidgetCfg.css[name];
						}else {
							value = defaultChatConfig[name];
						}
					}
				}else{
					value = defaultChatConfig[name];
				}
				return value;
			}
			function getCustomUI(name) {
				var value = "";
				if(typeof params.ui != "undefined"){
					if(params.ui[name] ||( params.ui[name] == false)){
						value = params.ui[name];
					}else {
						value = defaultChatConfig[name];
					}
				}else if(typeof params.faqWidgetCfg.ui != "undefined"){
					if(params.faqWidgetCfg.customize && (params.faqWidgetCfg.ui[name] ||( params.faqWidgetCfg.ui[name] == false))){
						value = params.faqWidgetCfg.ui[name];
					}else {
						//if said config not exist in FAQ, check if config exist in chat
						if(typeof params.chatWidgetCfg.ui != "undefined"){
							if(params.chatWidgetCfg.customize && (params.chatWidgetCfg.ui[name] ||( params.chatWidgetCfg.ui[name] == false))){
								value = params.chatWidgetCfg.ui[name];
							}
						} else {
							value = defaultChatConfig[name];
						}
					}
				}else if(typeof params.chatWidgetCfg.ui != "undefined"){
					if(params.chatWidgetCfg.customize && (params.chatWidgetCfg.ui[name] ||( params.chatWidgetCfg.ui[name] == false))){
						value = params.chatWidgetCfg.ui[name];
					}else {
						value = defaultChatConfig[name];
					}
				}else {
					value = defaultChatConfig[name];
				}
				return value;
			}
			function getCustomText(name) {
				var value = "";
				if(typeof params.text != "undefined"){
					if(params.text[name]) {
						value = params.text[name];
					}else {
						value = defaultChatText[name];
					}
				}else if(typeof params.faqWidgetCfg.text != "undefined" && typeof params.faqWidgetCfg.text[name] != "undefined"){
					if(params.faqWidgetCfg.customize && params.faqWidgetCfg.text[name]) {
						value = params.faqWidgetCfg.text[name];
					}else {
						value = defaultChatText[name];
					}
				}else if(typeof params.chatWidgetCfg.text != "undefined"){
					if(params.chatWidgetCfg.customize && params.chatWidgetCfg.text[name]) {
						value = params.chatWidgetCfg.text[name];
					}else {
						value = defaultChatText[name];
					}
				}else{
					value = defaultChatText[name];
				}
				return value;
			}

			var hasChatEnableCallBack = false;
			if(typeof params.chatWidgetCfg.ui != "undefined"){
				if(ccs.feature['sip.enabled'] && params.chatWidgetCfg.customize){
					hasChatEnableCallBack = params.chatWidgetCfg.ui.enableCallbackRequest;
				}
			}

			if(!cookieDisabled) {
				if(!hasChatEnableCallBack) {
					localStorage.setItem("chatName", "");
					localStorage.setItem("chatEmail", "");
				}
			}

			var previewTimeout = null;
			var previewCount = 0;

			//Hide chat entirely when this setting is `true`
			if(getCustomUI("hideChatWhenOffline")){
				//agentsAvailable equal to 0 also means chat queue is disabled
				if (!ccs.chatOpen || ccs.agentsAvailable === 0) {
					c3jQuery("#cention-chat-container").hide();
					return;
				}
			}

			let selectedDateTime, plainSelectedDateTime;
			function reFormatDateToUnix(dateStr) {
				const date = new Date(dateStr);
				return Math.floor(date.getTime() / 1000);
			}

			function checkWorkingHours(day, time, min) {
				workingTime = ccs.WorkingTimeRules;
				let isOpen = false, strTime = "", strMin = "";
				for (let d in workingTime) {
					const dayInfo = workingTime[d];
					const startHours = dayInfo.startHours+":"+dayInfo.startMinutes;
					const endHours = dayInfo.endHours+":"+dayInfo.endMinutes;
					if(day == d) {
						if(dayInfo.working) {
							if (time === 0) {
								strTime = "00";
							} else {
								if (time < 10) {
									strTime = "0"+time.toString();
								} else {
									strTime = time.toString();
								}
							}
							if (min === 0) {
								strMin = "00";
							} else {
								strMin = min.toString();
							}
							const selectedTimeStr = strTime+":"+strMin;
							if(selectedTimeStr >= startHours && selectedTimeStr <= endHours) {
								isOpen = true;
								c3jQuery(".date-error").hide();
								break;
							} else {
								console.log("outside working hours which is", startHours, " - " ,endHours);
								c3jQuery(".date-error").text(getCustomText("textDateError"));
								c3jQuery(".date-error").show();
								break;
							}
						} else {
							console.log("agent not working on ", day);
							c3jQuery(".date-error").text(getCustomText("textDateError"));
							c3jQuery(".date-error").show();
							break;
						}
					}
				}
				return isOpen;
			}

			let isValidDate = false;
			function onSelectDateTime(datetime) {
				const selectedDay = datetime.getDay();
				let day = "monday";
				switch( selectedDay ) {
					case 1:
						day = "monday";
						break;
					case 2:
						day = "tuesday";
						break;
					case 3:
						day = "wednesday";
						break;
					case 4:
						day = "thursday";
						break;
					case 5:
						day = "friday";
						break;
					case 6:
						day = "saturday";
						break;
					case 0:
						day = "sunday";
						break;
				}
				const time = datetime.getHours();
				const min =  datetime.getMinutes();
				let isOpen = checkWorkingHours(day, time, min);
				if(!isOpen) {
					c3jQuery('#CentionMsgSend').prop('disabled', true);
					isValidDate = false;
				} else {
					const now = new Date();
					if(datetime > now) {
						isValidDate = true;
						c3jQuery('#CentionMsgSend').prop('disabled', false);
					} else {
						c3jQuery('#CentionMsgSend').prop('disabled', true);
						console.log("you can't select past time");
						isValidDate = false;
					}
				}
			}

			const today = new Date();
			const zeroPad = (n, digits) => n.toString().padStart(digits, '0');
			function formatDate(date, format) {
				const map = {
					mm: zeroPad(date.getMonth() + 1, 2),
					dd: zeroPad(date.getDate(), 2),
					yyyy: date.getFullYear(),
					hh: zeroPad(date.getHours(), 2),
					MM: zeroPad(date.getMinutes(), 2),
				}
				return format.replace(/mm|dd|yyyy|hh|MM/gi, matched => map[matched])
			}

			const callbackEnabled = hasChatEnableCallBack;
			if (callbackEnabled) {
				c3jQuery("#textInputPhone").show();
				c3jQuery("#CentionChatAudioCallWrapper").show();

				const todayDate = formatDate(today, 'yyyy/mm/dd');
				c3jQuery('.CentionDateSelect').datetimepicker({
					ownerDocument: document,
					contentWindow: window,
					value: '',
					rtl: false,
					format: 'Y/m/d H:i',
					formatTime: 'H:i',
					formatDate: 'Y/m/d',
					defaultTime:false,
					defaultDate:todayDate,
					minDate:todayDate,
					maxDate:false,
					minTime: false,
					maxTime: false,
					minDateTime: false,
					maxDateTime: false,
					onSelectDate:function (date) {
						onSelectDateTime(date);
						selectedDateTime = reFormatDateToUnix(date);
						plainSelectedDateTime = date;
						c3jQuery(".CentionDateSelect").val(formatDate(date, 'yyyy/mm/dd hh:MM'));
					},
					onSelectTime:function (time) {
						onSelectDateTime(time);
						selectedDateTime = reFormatDateToUnix(time);
						plainSelectedDateTime = time;
						c3jQuery(".CentionDateSelect").val(formatDate(time, 'yyyy/mm/dd hh:MM'));
					},
					onChangeDateTime:function (datetime) {
						onSelectDateTime(datetime);
						selectedDateTime = reFormatDateToUnix(datetime);
						plainSelectedDateTime = datetime;
					},
					todayButton: true,
					prevButton: true,
					nextButton: true,
					defaultSelect: true,
					scrollMonth: true,
					scrollTime: true,
					allowBlank: false
				});
			}

			//Show channel when there's channel added to the config
			if (!c3jQuery('#CentionChatChannelView').is(':visible')) {
				const messengerChat = "messengerChat";
				const telegramChat = "telegramChat";
				const whatsAppChat = "whatsAppChat";
				const chatChat = "chatChat";
				if((getCustomText(messengerChat) && getCustomText(messengerChat).trim() !== "") ||
				   (getCustomText(telegramChat) && getCustomText(telegramChat).trim() !== "") ||
				   (getCustomText(whatsAppChat) && getCustomText(whatsAppChat).trim() !== "")
				){
					c3jQuery("#CentionStartView").hide();
					c3jQuery("#CentionChatChannelView").show();
					var ChannelURL = "";
					const ChannelClass = "channelStyle";
					const ChannelListClass = "channelListStlye";
					const ChannelLabelClass = "channelLabelStyle";
					if(getCustomText(messengerChat) && getCustomText(messengerChat).trim() !== "") {
						ChannelURL = getCustomText("messengerChat").trim();
						const ChannelStyleClass = ChannelClass + " " + messengerChat;
						const ChannelListStyleClass = ChannelListClass + " " + "icon-v5-messenger";
						c3jQuery(".channelList").append("<a href=\"" +ChannelURL+"\" class=\"" +ChannelStyleClass +"\" target='_blank'><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">Messenger</p></a>");
					}
					if(getCustomText(telegramChat) && getCustomText(telegramChat).trim() !== "") {
						ChannelURL = getCustomText("telegramChat").trim();
						const ChannelStyleClass  = ChannelClass + " " + telegramChat;
						const ChannelListStyleClass = ChannelListClass + " " + "icon-telegram";
						c3jQuery(".channelList").append("<a href=\"" +ChannelURL+"\" class=\"" +ChannelStyleClass +"\" target='_blank'><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">Telegram</p></a>");
					}
					if(getCustomText(whatsAppChat) && getCustomText(whatsAppChat).trim() !== "") {
						ChannelURL = getCustomText("whatsAppChat").trim();
						const ChannelStyleClass  = ChannelClass + " " + whatsAppChat;
						const ChannelListStyleClass = ChannelListClass + " " + "icon-v5-whatsapp";
						c3jQuery(".channelList").append("<a href=\"" +ChannelURL+"\" class=\"" +ChannelStyleClass +"\" target='_blank'><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">WhatsApp</p></a>");
					}
					const ChannelStyleClass  = ChannelClass + " " + chatChat;
					const ChannelListStyleClass = ChannelListClass + " " + "icon-chat";
					c3jQuery(".channelList").append("<div id='chatWithUs' class=\"" +ChannelStyleClass +"\"><div class=\"" +ChannelListStyleClass +"\"></div><p class=\"" +ChannelLabelClass +"\">Chat With Us</p></div>");
				}
			}

			if((getCustomText("messengerFAQ") && getCustomText("messengerFAQ").trim() !== "") ||
			   (getCustomText("telegramFAQ") && getCustomText("telegramFAQ").trim() !== "") ||
			   (getCustomText("whatsAppFAQ") && getCustomText("whatsAppFAQ").trim() !== "")
			){
				hasChannel = true;
			}

			/**************************************************************/
			/* Apply Basic Custom Style If Provided By Third Party Client */
			/**************************************************************/


			//For Chat Start View
			//Header
			const getPosition  = getCustomUI("position");
			const bottomPosition = getCustomUI("bottomPosition");
			const leftPosition = getCustomUI("leftPosition");
			const rightPosition = getCustomUI("rightPosition");

			getAgentList(CentionChat.areaId);

			function widgetLocation(){
				var left, right;
				var widgetLocation = [];

				if(getPosition == 'bottomLeft'){
					if(leftPosition && rightPosition){ // both left and right exist
						left  = leftPosition + "px";
						right = "inherit";
					}else if(leftPosition && rightPosition == undefined){ // if only leftPosition available
						left  = leftPosition + "px";
						right = "";
					}else if(leftPosition == undefined && rightPosition){ // if only rightPosition available
						left  = "inherit";
						right = rightPosition + "px";
					}
				}else if(getPosition == 'bottomRight'){
					if(leftPosition && rightPosition){ // both left and right available
						left  = "inherit";
						right = rightPosition + "px";
					}else if(leftPosition && rightPosition == undefined){ // if only leftPosition available
						left  = leftPosition + "px";
						right = "inherit";
					}else if(leftPosition == undefined && rightPosition){ // if only rightPosition available
						left  = "";
						right = rightPosition + "px";
					}
				}
				widgetLocation.push(left, right);
				return widgetLocation;
			}

			//for cobrowsing feature
			if(ccs.feature['chat.allow-co-browsing']) {
				//append class .cention-client-wrapper at client's page body html, for future ref
				const centionClientWrapper = document.body;
				centionClientWrapper.classList.add("cention-client-wrapper");

				//add custom cursor element
				c3jQuery("body").append('<div class="cention-custom-cursor"></div>');
			}

			//send file feature
			if(ccs.feature["chat.attachment"]){
				c3jQuery("[id*='CentionChatSendFileButtonWrapper']").show();
			} else {
				c3jQuery("[id*='CentionChatSendFileButtonWrapper']").hide();
			}

			c3jQuery("#CentionProactiveButton > span.icon").html(getCustomConfig("logo"));
			if(getCustomText("textMessageProactive") != "") {
				//show proactive msg description
				c3jQuery("#CentionProactiveBody > #textMessageProactive > span.text").text(getCustomText("textMessageProactive"));
			} else {
				//hide it
				c3jQuery("#CentionProactiveBody,#textMessageProactive").hide();
				c3jQuery("#CentionProactiveTextWrapper").addClass("center");
			}

			//c3jQuery("#textButtonProactiveStart").css("cssText", "color:"+getCustomConfig("buttonStartTextColor")+" !important;border-color:"+getCustomConfig("buttonStartBorderColor")+"!important;background-color:"+getCustomConfig("buttonStartColor")+"!important");
			c3jQuery("#textButtonProactiveStart").val(getCustomText("textButtonProactiveStart"));

			// c3jQuery("#textButtonProactiveLater").css("color", getCustomConfig("fontColor"));
			c3jQuery("#textButtonProactiveLater").text(getCustomText("textButtonProactiveLater"));

			c3jQuery("#CentionChatHeader").css("background-color", getCustomConfig("headerColor"));
			c3jQuery("#CentionProactiveButton").css("background-color", getCustomConfig("headerColor"));
			c3jQuery("#CentionChatHeader").css({
				'bottom' : bottomPosition + 'px',
				'display' : 'flex',
				'left'   : widgetLocation()[0],
				'right'  : widgetLocation()[1]
			});
			c3jQuery("#CentionChatHeader > span.icon").css("border-right", "0px");
			// c3jQuery("#CentionChatHeader > span.text").css("width", "150px");

			var _chatTitle = getCustomText("textTitle");
			if(ccs.resumable) {
				renderChatLoaderHeader();
				c3jQuery('#CentionChatStatus').show();
			}else {
				c3jQuery("#CentionChatHeader > span.text").text(_chatTitle);
				c3jQuery("#CentionChatWelcome").show();
			}
			c3jQuery("#CentionChatHeader > #iconLogo").html(getCustomConfig("logo"));
			c3jQuery("#CentionChatHeader").css("color", getCustomConfig("headerTextColor"));
			c3jQuery("#CentionProactiveButton").css("color", getCustomConfig("headerTextColor"));
			if(getCustomUI("position") == "bottomRight"){
				c3jQuery("#Skin-Modern").addClass("bottomRight");
				c3jQuery("#CentionChatHeader").addClass("bottomRight");
				c3jQuery("#CentionChatBody").addClass("bottomRight");
				c3jQuery("#CentionWidgetFooter > a").addClass("fixRight");
				c3jQuery('#CentionChatView').addClass("bottomRight");

				//proactive dialog re-positioning
				c3jQuery("#CentionChatProactiveDialog").addClass("bottomRight");
				c3jQuery("#CentionProactiveButton").addClass("bottomRight");
			}
			if(getCustomUI("position") == "bottomLeft"){
				c3jQuery("#Skin-Modern").addClass("bottomLeft");
				c3jQuery("#CentionChatHeader").addClass("bottomLeft");
				c3jQuery("#CentionChatBody").addClass("bottomLeft");
				c3jQuery("#CentionWidgetFooter > a").addClass("fixLeft");

				c3jQuery("#CentionChatProactiveDialog").addClass("bottomLeft");
				c3jQuery("#CentionProactiveButton").addClass("bottomLeft");
			}
			if(getCustomUI("position") == "centerRight"){
				c3jQuery("#CentionChatHeader,#CentionProactiveButton").css({
					'bottom' : '20%'
				});
				c3jQuery("#CentionChatHeader").addClass("setupFloatButtonRight");
				c3jQuery("#CentionChatBody").addClass("rightCenter");
				c3jQuery("#CentionWidgetFooter > a").addClass("fixRight");

				c3jQuery("#CentionChatProactiveDialog").addClass("rightCenter");
				c3jQuery("#CentionProactiveButton").addClass("setupFloatButtonRight");
			}
			if(getCustomUI("position") == "centerLeft"){
				c3jQuery("#CentionChatHeader,#CentionProactiveButton").css({
					'bottom' : '20%'
				});
				c3jQuery("#CentionChatHeader").addClass("setupFloatButtonLeft");
				c3jQuery("#CentionChatBody").addClass("leftCenter");
				c3jQuery("#CentionWidgetFooter > a").addClass("fixLeft");

				c3jQuery("#CentionChatProactiveDialog").addClass("leftCenter");
				c3jQuery("#CentionProactiveButton").addClass("setupFloatButtonLeft");
			}
			c3jQuery("#textStatusConnecting").text(getCustomText("textStatusConnecting"));

			//Z-Index
			const zIndex = getCustomUI("zIndex");
			if(zIndex !== "") {
				if(uiSkin == SKIN_MODERN){
					c3jQuery("#CentionChat>#Skin-Modern").css("z-index", zIndex);
				} else {
					c3jQuery("#CentionChat>#Skin-Default>#CentionChatHeader").css("z-index", zIndex);
					c3jQuery("#CentionChat>#Skin-Default>#CentionChatBody").css("z-index", zIndex);
				}
				c3jQuery("#CentionProactiveButton").css("z-index", zIndex);
				c3jQuery("#CentionChatProactiveDialog").css("z-index", zIndex);
			}
			//Body
			c3jQuery("#CentionChatBody").css("background-color", getCustomConfig("mainColor"));
			// c3jQuery("#CentionChatBody").css("color", getCustomConfig("fontColor"));

			c3jQuery("[id=textAvailableNote]").text(getCustomText("textAvailableNote"));
			c3jQuery('#textInputName').attr("placeholder", getCustomText("textInputName")+" *");
			c3jQuery('#textInputEmail').attr("placeholder", getCustomText("textInputEmail")+" *");
			c3jQuery('#textInputPhone').attr("placeholder", getCustomText("textInputPhone")+" *");
			c3jQuery('#textInputQuestion').attr("placeholder", getCustomText("textInputQuestion")+" *");

			c3jQuery("#textStatusQueue").text(getCustomText("textStatusQueue"));
			c3jQuery("#textButtonRetry").css("color", getCustomConfig("buttonRetryTextColor"));
			c3jQuery("#textButtonRetry").text(getCustomText("textButtonRetry"));

			//Form warning color
			const warningColor = getCustomConfig("warningTextColor");
			var warningStyle = 'input.warning:-moz-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'input.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'input.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'textarea.warning:-moz-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'textarea.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';
			warningStyle += 'textarea.warning::-webkit-input-placeholder {color: ' + warningColor + ';}';

			const warningStyleBlock = '<style id="cention-warning-style">' + warningStyle + '</style>';
			c3jQuery('head').append(warningStyleBlock);

			//Start button
			c3jQuery("#textButtonStart").css("cssText", "color:"+getCustomConfig("buttonStartTextColor")+" !important;border-color:"+getCustomConfig("buttonStartBorderColor")+"!important;background-color:"+getCustomConfig("buttonStartColor")+"!important");
			if(getCustomText("textButtonStart")){
				c3jQuery("#textButtonStart").val(getCustomText("textButtonStart"));
				c3jQuery("#textButtonStart").html(getCustomText("textButtonStart"));
				c3jQuery("#textButtonStart").addClass("text");
			}
			if(getCustomText("messengerChat")){
				console.log(getCustomText("messengerChat"));
			}
			c3jQuery("#textEULA").html(getCustomText("textEULA"));
			c3jQuery("#textEULA").css("color", getCustomConfig("askEULATextColor"));
			//Away button
			c3jQuery("#CentionNoAgentsView > div > p").css("color", getCustomConfig("messageAwayTextColor"));
			c3jQuery("#textButtonAway").css("background-color", getCustomConfig("buttonAwayColor"));
			c3jQuery("#textButtonAway").css("color", getCustomConfig("buttonAwayTextColor"));
			c3jQuery("#textButtonAway").css("border-color", getCustomConfig("buttonAwayBorderColor"));
			c3jQuery("#textButtonAway").attr("href", getCustomUI("buttonAwayLink"));
			c3jQuery("#textButtonAway").addClass(getCustomConfig("buttonAwayCustomClass"));
			c3jQuery("#textButtonAway").text(getCustomText("textButtonAway"));
			//Footer
			if (!ccs.feature['chat.hide-cention-logo']){
				c3jQuery("#CentionWidgetFooter > a").css("color", getCustomConfig("footerTextColor"));
				c3jQuery("#CentionWidgetFooter > a").attr("href", getCustomConfig("footerLink"));
				c3jQuery("#CentionWidgetFooter > a").html(getCustomConfig("footerText"));
			}
			//Send Icon
			c3jQuery("#SubmitChat").css("color", getCustomConfig("messageSendIconColor"));
			//Chat Menu
			// c3jQuery("#CentionChatView > .centionChatMenu > span > a").css("color", getCustomConfig("menuTextColor"));
			c3jQuery("#CentionChatChannelView").css("background-color", getCustomConfig("chatConversationBgColor"));
			c3jQuery("#CentionChatView").css("background-color", getCustomConfig("chatConversationBgColor"));
			c3jQuery("#CentionChatViewHeader").css("background-color", getCustomConfig("chatConversationBgColor"));
			c3jQuery("#CentionChatList").css("background-color", getCustomConfig("chatConversationBgColor"));
			c3jQuery("#CentionChatList").css("color", getCustomConfig("chatConversationTextColor"));

			//Modern skin condition
			if(getCustomSkin() == SKIN_MODERN){
				c3jQuery("#CentionStartView").css("background-color", getCustomConfig("chatConversationBgColor"));
				c3jQuery("#CentionNoAgentsView").css("background-color", getCustomConfig("chatConversationBgColor"));
				c3jQuery("#CentionChatFooter").css("background-color", getCustomConfig("chatConversationBgColor"));
				c3jQuery("#CentionToggleableChatMenu").css("background-color", getCustomConfig("chatConversationBgColor"));
			} else {
				//Toggle Menu (the + and x button)
				c3jQuery("#CentionToggleMenuButton").css("background-color", getCustomConfig("buttonToggleMenuColor"));
			}

			c3jQuery("#textMenuSendFile").text(getCustomText("textMenuSendFile"));
			c3jQuery("#textMenuNewChat").text(getCustomText("textMenuNewChat"));
			c3jQuery("#textMenuFinishChat").text(getCustomText("textMenuFinishChat"));
			c3jQuery("#textMenuPrintChat").text(getCustomText("textMenuPrintChat"));
			c3jQuery("#textMenuSaveChat").text(getCustomText("textMenuSaveChat"));
			//Satisfaction Feedback
			c3jQuery("#SatisfactionText").text(getCustomText("textSatisfactionMessage"));
			c3jQuery(".satisfactionWrapper").css({"background-color": getCustomConfig("feedbackBgColor"), "color": getCustomConfig("feedbackTextColor")});
			c3jQuery("#FeedbackButton").text(getCustomText("textFeedbackButton"));
			c3jQuery("#FeedbackButton").css("border-color", getCustomConfig("feedbackTextColor"));
			c3jQuery("#FeedbackButton").hover(
				function(){ c3jQuery(this).css({"background-color": getCustomConfig("feedbackTextColor"), "color": getCustomConfig("feedbackBgColor")}) }, //onHover
				function(){ c3jQuery(this).css({"background-color": getCustomConfig("feedbackBgColor"), "color": getCustomConfig("feedbackTextColor")}) } //onLeave
			);
			c3jQuery("#textCommentBoxTitle").text(getCustomText("textCommentBoxTitle"));
			c3jQuery("#buttonOngoingSend").text(getCustomText("textFeedbackBtnSend"));
			c3jQuery("#buttonOngoingCancel").text(getCustomText("textFeedbackBtnCancel"));
			c3jQuery("#textTitleProactive").text(getCustomText("textTitleProactive"));
			//Feedback ModalBox
			c3jQuery("#SatisfactionFeedback").css("background-color", getCustomConfig("feedbackBoxBgColor"));
			c3jQuery("#textCommentBoxTitle").css("color", getCustomConfig("feedbackBoxTitleColor"));
			c3jQuery("#ongoingContent").css("background-color", getCustomConfig("feedbackBoxTextareaBgColor"));
			c3jQuery("#ongoingContent").css("color", getCustomConfig("feedbackBoxTextareaTextColor"));
			c3jQuery(".satisfactionButton").css("background-color", getCustomConfig("feedbackBoxBtnBgColor"));
			c3jQuery(".satisfactionButton").css("color", getCustomConfig("feedbackBoxBtnTextColor"));

			c3jQuery("#CentionChatQuestion").css("background-color", getCustomConfig("chatConversationTextareaBgColor"));
			c3jQuery("#CentionChatQuestion").css("color", getCustomConfig("chatConversationTextareaColor"));
			c3jQuery("#CentionToggleableChatMenu i").css("color", getCustomConfig("chatToggleMenuIconsColor"));
			c3jQuery("#CentionToggleableChatMenu span").css("color", getCustomConfig("chatToggleMenuIconsTextColor"));

			//proactive UI
			c3jQuery("#CentionChatProactiveDialog,#CentionProactiveHeader").css("background-color", getCustomConfig("proactiveDialogBgColor"));
			c3jQuery("#textTitleProactive").css("color", getCustomConfig("proactiveTitleTextColor"));
			c3jQuery("#textMessageProactive > .text").css("color", getCustomConfig("proactiveDescTextColor"));
			//check if avatar has value, if not, hide (proactiveImageURL)
			if(getCustomUI("proactiveImageURL") !== "") {
				c3jQuery("#CentionProactiveAvatar").attr("src", getCustomUI("proactiveImageURL"));
			} else {
				c3jQuery("#CentionProactiveAvatarWrapper").hide();
			}
			if(uiSkin === SKIN_DEFAULT) {
				c3jQuery("#CentionChatProactiveDialog").addClass("default");
				c3jQuery("#CentionProactiveButton").addClass("default");
				proactiveChatTitle = "<span id='textTitle' class='text'>"+_chatTitle+"</span>";
				c3jQuery('#CentionProactiveButton').append(proactiveChatTitle);
			}
			//proactive popup layout proactivePopupType
			if(getCustomUI("proactivePopupType") === 1) {
				//portrait
				c3jQuery("#CentionChatProactiveDialog").addClass("proactive-portrait");
			} else {
				//landscape
				c3jQuery("#CentionChatProactiveDialog").addClass("proactive-landscape");
			}

			//Cookie Warnings
			c3jQuery("#showCookieWarning").css("color", getCustomConfig("cookieWarningSymbolColor"));
			c3jQuery("#CentionCookieWarning").css({
				"color": getCustomConfig("cookieWarningTextColor"),
				"background-color": getCustomConfig("cookieWarningBgColor")
			});

			//Video call UI setup
			c3jQuery("#CentionChatVideoCallWrapper").hide();
			c3jQuery("#CentionChatVideoFrame").hide(); //make this appear once video button clicked
			c3jQuery("#CentionChatVideoButtonStop").hide();

			if(getCustomUI("enableVideoChat") || ccs.feature['chat.allow-screen-sharing'] || ccs.feature['chat.allow-co-browsing']) {
				const vidHTML = "<div id='CentionChatVideoFrame'>"+
								"<div class='cention-video-header'>"+
									"<span><i class='icon-video-call'></i>"+
										getCustomText("textVideoCallHeader")+
									"</span>"+
									"<div id='CentionCloseVid'><i class='icon-close'></i></div>"+
								"</div>"+
								"<div id='CentionChatVideoFrameWrapper'>"+
									//local cam
									"<div id='CentionChatVideoLocalWrapper' style='display:none'>"+
										"<video id='CentionChatVideoFrameLocal' autoplay playsinline muted></video>"+
										"<div id='AvatarOverlayLocal' class='avatar-overlay local' style='display:none'><i class='icon-user-circle'></i></div>"+
										"<div class='vid-local-info'><span class='vid-local-name'></span><i class='icon-microphone-mute client-mute' style='display:none'></i></div>"+
									"</div>"+
									//remote cam
									"<div id='CentionChatVideoRemoteWrapper' style='display:none'>"+
										"<video id='CentionChatVideoFrameRemote' width='300px' height='200px' autoplay playsinline></video>"+
										"<div id='AvatarOverlayRemote' class='avatar-overlay remote' style='display:none'><i class='icon-user-circle'></i></div>"+
										"<canvas id='agentBlurCanvas' hidden></canvas>"+
										"<div id='bg-container' hidden>"+
											"<div id='vid-bg-container'></div>"+
											"<i id='vid-self-loading' title='Extracting background' class='icon-spinner rotating' hidden></i>"+
											"<canvas id='canvasBg'></canvas>"+
										"</div>"+
										"<div class='vid-remote-info'><span class='vid-remote-name'></span><i class='icon-microphone-mute agent-mute' style='display:none'></i></div>"+
									"</div>"+
									//local screen
									"<div id='CentionChatClientScreenShareWrapper' style='display:none'>"+
										"<div class='screen-share-preview'>"+
											"<video id='client-widget-display-view' autoplay loop playsinline muted></video>"+
											"<div class='screen-self-view-info'>"+
												getCustomText("textScreenSharing")+
											"</div>"+
										"</div>"+
									"</div>"+
									//remote screen
									"<div id='CentionChatAgentScreenShareWrapper' style='display:none'>"+
										"<video id='agent-widget-display-view' autoplay loop playsinline muted></video>"+
										"<div id='CloseAgentVid'><i class='icon-close'></i></div>"+
									"</div>"+
								"</div>"+
								"<div id='CentionChatRemoteDisplay' class='screen-share-wrapper'>"+
								"</div>"+
								"<div id='CentionCoBrowsingStatus'>"+
								CentionChat.I("Co-browsing session status")+
								": <span id='coBrowsingStatusTxt'></span></div>"+
								"<div id='CentionChatVideoBtns' class='btn-wrapper'>"+
									"<div id='screenShare' title='Start Screen Sharing'><i class='icon-sharescreen'></i></div>"+
									"<div id='stopScreenShare' title='Stop Screen Sharing' hidden><i class='icon-sharescreen'></i></div>"+
									"<div id='coBrowsing' title='Start Co Browsing' hidden><i class='icon-cobrowse'></i></div>"+
									"<div id='stopCoBrowsing' title='Stop Co Browsing' hidden><i class='icon-cobrowse'></i></div>"+
									"<div id='toggleRemoteControl' title='Toggle remote control access' hidden><i class='icon-control'></i>"+
										"<div id='toggleRemoteControlListOpt' hidden>"+
											"<span id='toggleRemoteKeyboard' title='Click to toggle keyboard control for agent'>Keyboard <i class='icon-single-tick1 allowed'></i><i class='icon-close disallowed'></i></span>"+
											"<span id='toggleRemoteMouse' title='Click to toggle mouse control for agent'>Mouse <i class='icon-single-tick1 allowed'></i><i class='icon-close disallowed'></i></span></span>"+
										"</div>"+
									"</div>"+
									"<div id='disableVidButton' title='Disable Video'><i class='icon-video-call'></i></div>"+
									"<div id='enableVidButton' title='Enable Video'><i class='icon-video-call-disable'></i></div>"+
									"<div id='disableAudioButton' title='Disable Mic'><i class='icon-microphone'></i></div>"+
									"<div id='enableAudioButton' title='Enable Mic'><i class='icon-microphone-mute'></i></div>"+
									"<div id='startButton' title='Start Camera'><i class='icon-video-call'></i></div>"+
									"<div id='callButton' title='Call'><i class='icon-call'></i></div>"+
									"<div id='hangupButton' title='Hangup Call'><i class='icon-call'></i></div>"+
									"<div id='maximizeVideo' title='"+CentionChat.I("Enter full screen")+"'><i class='icon-fullscreen' id='centionMaxIcon'></i></div>"+
									"<div id='hideVidPreview' title='Hide Video Preview'><i class='icon-chevron-down' id='centionHidePreviewIcon'></i></div>"+
									"<span id='callTimer'>0:00:00</span>"+
								"</div>"+
							"</div>";
				c3jQuery('#cention-video-container').append(vidHTML);
				c3jQuery('#maximizeVideo').click(function(e){
					toggleFullScreen();
				});
				c3jQuery("#CentionChatVideoFrame").css("background-color", getCustomConfig("mainColor"));
				c3jQuery("#CentionChatVideoBtns").css("background-color", getCustomConfig("mainColor"));

			}
			if(ccs.feature["chat.allow-video-call"] && getCustomUI("enableVideoChat")) {
				c3jQuery("#startButton").show();
			} else {
				c3jQuery("#startButton").hide();
			}

			//hide/show other video preview
			c3jQuery("#hideVidPreview").attr("title", CentionChat.I("Hide video preview"));
			c3jQuery('#hideVidPreview').click(function() {
				c3jQuery("#CentionChatVideoFrameWrapper").toggleClass("minimized");
				if(c3jQuery(this).hasClass("clicked")) {
					if(fullScreenMode) {
						c3jQuery("#hideVidPreview").attr("title", CentionChat.I("Show video preview"));
						c3jQuery("#centionHidePreviewIcon").removeClass("icon-chevron-down");
						c3jQuery("#centionHidePreviewIcon").addClass("icon-chevron-up");
						c3jQuery(this).removeClass("clicked");
					}
				} else {
					if(fullScreenMode) {
						c3jQuery(".cVidThumbnail").not(".avatar-overlay").show();
						c3jQuery("#CentionChatVideoLocalWrapper").show();
						c3jQuery("#CentionChatVideoRemoteWrapper.thumbnail").show();
						if(blurActivated || bgActivated) {
							//dont show original remote vid
							c3jQuery("#CentionChatVideoFrameRemote").hide();
						}
						if(currentFS !== FS_DISPLAY_LOCAL) {
							c3jQuery(".screen-self-view-info").show();
						}
						c3jQuery("#hideVidPreview").attr("title", CentionChat.I("Hide video preview"));
						c3jQuery("#centionHidePreviewIcon").removeClass("icon-chevron-up");
						c3jQuery("#centionHidePreviewIcon").addClass("icon-chevron-down");
						c3jQuery(this).addClass("clicked");
					}
				}
			});

			//toggle mouse control
			c3jQuery('#toggleRemoteMouse').click(function() {
				if(c3jQuery(this).hasClass("disabled")) {
					c3jQuery(this).removeClass("disabled");
					toggleAgentMouseAccess(true);
					c3jQuery(".cention-custom-cursor").show();
				} else {
					c3jQuery(this).addClass("disabled");
					toggleAgentMouseAccess(false);
					c3jQuery(".cention-custom-cursor").hide();
					removeCoBrowseFocus();
				}
			});
			//toggle keyboard control
			c3jQuery('#toggleRemoteKeyboard').click(function() {
				if(c3jQuery(this).hasClass("disabled")) {
					c3jQuery(this).removeClass("disabled");
					toggleAgentKeyboardAccess(true);
					//todo: indicate (UI) that input are handled by agent
				} else {
					c3jQuery(this).addClass("disabled");
					toggleAgentKeyboardAccess(false);
				}
			});

			//make main video frame draggable
			function handle_drag(e){
				if(!fullScreenMode) {
					window.my_dragging = {};
					my_dragging.pageX0 = e.pageX;
					my_dragging.pageY0 = e.pageY;
					my_dragging.elem = this;
					my_dragging.offset0 = c3jQuery(this).offset();
					function handle_dragging(e){
						var left = my_dragging.offset0.left + (e.pageX - my_dragging.pageX0);
						var top = my_dragging.offset0.top + (e.pageY - my_dragging.pageY0);
						c3jQuery(my_dragging.elem)
						.offset({top: top, left: left});
					}
					function handle_mouseup(e){
						c3jQuery('body')
						.off('mousemove', handle_dragging)
						.off('mouseup', handle_mouseup);
					}
					c3jQuery('body')
					.on('mouseup', handle_mouseup)
					.on('mousemove', handle_dragging);
				}
			}
			c3jQuery('#CentionChatVideoFrame').mousedown(handle_drag);

			function handle_thumbnailDrag(e){
				if(fullScreenMode) {
					window.dragVid = {};
					dragVid.pageX0 = e.pageX;
					dragVid.pageY0 = e.pageY;
					dragVid.elem = this;
					dragVid.offset0 = c3jQuery(this).offset();
					function handle_dragging(e){
						var left = dragVid.offset0.left + (e.pageX - dragVid.pageX0);
						var top = dragVid.offset0.top + (e.pageY - dragVid.pageY0);
						c3jQuery(dragVid.elem)
						.offset({top: top, left: left});
					}
					function handle_mouseup(e){
						c3jQuery('body')
						.off('mousemove', handle_dragging)
						.off('mouseup', handle_mouseup);
					}
					c3jQuery('body')
					.on('mouseup', handle_mouseup)
					.on('mousemove', handle_dragging);
				}
			}

			//disable draggable since we can hide thumbnail/preview during fullscreen
			//c3jQuery('#CentionChatVideoFrameLocal').mousedown(handle_thumbnailDrag);
			//c3jQuery('#CentionChatVideoFrameRemote').mousedown(handle_thumbnailDrag);

			c3jQuery("#enableAudioButton").hide();
			c3jQuery("#enableVidButton").hide();
			c3jQuery("#callButton").hide();
			c3jQuery("#hangupButton").hide();
			c3jQuery("#disableVidButton").hide();
			c3jQuery("#disableAudioButton").hide();
			c3jQuery("#hideVidPreview").hide();
			c3jQuery("[id*='coBrowsing']").hide();
			c3jQuery("[id*='stopCoBrowsing']").hide();
			c3jQuery("#CentionCoBrowsingStatus").hide();
			c3jQuery("#toggleRemoteControl").hide();

			//Support custom element to trigger chat
			let customChatElemExist;
			if( typeof customChatBtn != "undefined" && customChatBtn != "" ) {
				customChatElemExist = c3jQuery('#'+customChatBtn).length;
				if ( customChatElemExist > 0 ) {
					c3jQuery('#'+customChatBtn).addClass("centionChatTrigger");
					useCustomChatTrigger = true;
					c3jQuery("#CentionChatHeader").hide();
				}
			}

			if(ccs.feature['chat.allow-screen-sharing']) {
				c3jQuery("#screenShare").show();
			} else {
				c3jQuery("#screenShare").hide();
			}
			c3jQuery("#stopScreenShare").hide();

			function toggleLocalFS() {
				c3jQuery("#CentionChatVideoFrameWrapper > div").removeClass("no-thumbnail");
				c3jQuery("#CentionChatVideoLocalWrapper").addClass("no-thumbnail");
				c3jQuery("#CentionChatVideoFrameWrapper").find("video").removeClass("cVidFullScreen");
				c3jQuery("#CentionChatVideoFrameLocal").addClass("cVidFullScreen").removeClass("cVidThumbnail")
			}

			function toggleRemoteFS() {
				c3jQuery("#CentionChatVideoFrameWrapper > div").removeClass("no-thumbnail");
				c3jQuery("#CentionChatVideoRemoteWrapper").addClass("no-thumbnail");
				c3jQuery("#CentionChatVideoFrameWrapper").find("video").removeClass("cVidFullScreen");
				c3jQuery("#CentionChatVideoFrameRemote").addClass("cVidFullScreen").removeClass("cVidThumbnail")
			}

			function toggleLocalShareFS() {
				c3jQuery("#CentionChatVideoFrameWrapper > div").removeClass("no-thumbnail");
				c3jQuery("#CentionChatClientScreenShareWrapper").addClass("no-thumbnail");
				c3jQuery("#CentionChatVideoFrameWrapper").find("video").removeClass("cVidFullScreen");
				c3jQuery("#client-widget-display-view").addClass("cVidFullScreen").removeClass("cVidThumbnail")
			}

			function toggleRemoteShareFS() {
				c3jQuery("#CentionChatVideoFrameWrapper > div").removeClass("no-thumbnail");
				c3jQuery("#CentionChatAgentScreenShareWrapper").addClass("no-thumbnail");
				c3jQuery("#CentionChatVideoFrameWrapper").find("video").removeClass("cVidFullScreen");
				c3jQuery("#agent-widget-display-view").addClass("cVidFullScreen").removeClass("cVidThumbnail")
			}

			function toggleFullScreen(e) {
				if(!fullScreenMode) {
					fullScreenMode = true;
					c3jQuery("body").addClass("cention-fullscreen");

					c3jQuery('#CentionChatVideoFrame').css("cursor", "default");
					c3jQuery(".cention-video-header").hide();
					c3jQuery("#CentionChatVideoFrameRemote").toggleClass("cVidFullScreen");
					c3jQuery("#CentionChatVideoRemoteWrapper").addClass("no-thumbnail");
					c3jQuery("#CentionChatVideoFrameWrapper").find("video").addClass("cVidThumbnail");
				} else {
					fullScreenMode = false;
					c3jQuery("body").removeClass("cention-fullscreen");

					c3jQuery('#CentionChatVideoFrame').css("cursor", "move");
					c3jQuery(".cention-video-header").show();
					c3jQuery("#CentionChatVideoFrameLocal").show();
				}

				if(fullScreenMode) {
					c3jQuery("#CentionChatVideoFrame").addClass("fullscreen-mode");
					c3jQuery("#centionMaxIcon").addClass("icon-fullscreen-end");
					c3jQuery("#maximizeVideo").attr("title", CentionChat.I("Exit full screen"));
					c3jQuery("#centionMaxIcon").removeClass("icon-fullscreen");
							//no screen sharing
					if(onVideoCall) {
						c3jQuery("#CentionChatVideoFrameRemote").addClass("cVidFullScreen");

						c3jQuery("#CentionChatVideoFrameLocal").addClass("cVidThumbnail");
						c3jQuery("#agent-widget-display-view").removeClass("cVidFullScreen");
						c3jQuery("#CentionChatAgentScreenShareWrapper").removeClass("fullscreen");
						c3jQuery("#CentionChatVideoFrameRemote").removeClass("cVidThumbnail left");
						c3jQuery("#AvatarOverlayRemote").removeClass("cVidThumbnail left");
						c3jQuery("#CentionChatClientScreenShareWrapper").removeClass("thumbnail");
						c3jQuery("#CentionChatClientScreenShareWrapper").removeClass("fullscreen");
						c3jQuery("#CentionChatAgentScreenShareWrapper").removeClass("thumbnail");
						c3jQuery("#CentionChatVideoRemoteWrapper").removeClass("thumbnail");

						c3jQuery("#CentionChatVideoFrameLocal").removeClass("cVidThumbnail side-right");
						c3jQuery("#AvatarOverlayLocal").removeClass("cVidThumbnail side-right");
						c3jQuery("#CentionChatVideoFrameRemote").removeClass("cVidThumbnail side-left");
						c3jQuery("#AvatarOverlayRemote").removeClass("cVidThumbnail side-left");

						c3jQuery("#CentionChatVideoFrameLocal").addClass("cVidThumbnail");

					}
					c3jQuery('#CentionChatVideoBtns').css({
						'width': '100%',
						'left': '0',
					});
					c3jQuery("#CentionCloseVid").hide();

					if( c3jQuery(".cVidThumbnail").is(":visible") == true ){
						c3jQuery("#hideVidPreview").show();
					} else {
						c3jQuery("#hideVidPreview").hide();
					}
					if(currentFS === FS_REMOTE){ //tk checkback
						if(bgActivated) {
							onBgFullScreen();
						} else if(blurActivated) {
							onBlurFullScreen();
						}
					} else {
						if(bgActivated) {
							onBgRemote();
						} else if(blurActivated){
							onBlurRemote();
						}
					}
					switch (currentFS) {
						case FS_REMOTE:
							toggleRemoteFS();
							break;
						case FS_LOCAL:
							toggleLocalFS();
							break;
						case FS_DISPLAY:
							toggleRemoteShareFS();
							break;
						case FS_DISPLAY_LOCAL:
							toggleLocalShareFS();
							break;
						case FS_DEFAULT:
						default:
							toggleLocalFS();
							break;
					}
				} else {
					c3jQuery('#AvatarOverlayRemote').removeClass("cVidFullScreen");
					resetVidUI();
				}
				c3jQuery("#CentionChatVideoBtns").toggleClass("cVidToolbar");
				c3jQuery("#CentionChat").toggleClass("chatContainerHidden");

			}

			function resetVidUI() {
				c3jQuery("#centionMaxIcon").removeClass("icon-fullscreen-end");
				c3jQuery("#centionMaxIcon").addClass("icon-fullscreen");
				c3jQuery("#maximizeVideo").attr("title", CentionChat.I("Enter full screen"));

				c3jQuery("#CentionCloseVid").show();
				c3jQuery("#hideVidPreview").hide();

				document.getElementById("CentionChatVideoFrame").className = "";
				document.getElementById("CentionChatVideoFrameWrapper").className = "";

				document.getElementById("CentionChatVideoRemoteWrapper").className = "";
				document.getElementById("CentionChatVideoFrameRemote").className = "";
				document.getElementById("AvatarOverlayRemote").className = "avatar-overlay remote";
				document.getElementById("CentionChatVideoLocalWrapper").className = "";
				document.getElementById("CentionChatVideoFrameLocal").className = "";
				document.getElementById("AvatarOverlayLocal").className = "avatar-overlay local";

				document.getElementById("CentionChatRemoteDisplay").className = "screen-share-wrapper";
				document.getElementById("CentionChatClientScreenShareWrapper").className = "";
				document.getElementById("client-widget-display-view").className = "";
				document.getElementById("CentionChatAgentScreenShareWrapper").className = "";
				document.getElementById("agent-widget-display-view").className = "";

				if(onVideoCall) {
					c3jQuery("#CentionChatVideoLocalWrapper").show();
					c3jQuery("#CentionChatVideoFrameLocal").show();
					if(bgActivated){
						exitBgFullScreen();
						onBgRemote();
					} else if(blurActivated){
						exitBlurFullScreen();
						onBlurRemote();
					} else {
						onResetRemoteVideo();
					}
				}
			}

			document.onkeydown = function (e) {
				e = e || window.event;
				if(fullScreenMode) {
					if(e.key === "Escape" || e.key === "Esc") {
						toggleFullScreen(e);
					}
				}
			};

			if(useFAQChatInChat){
				//CHAT CONFIGS
				c3jQuery("#CentionChatBody").css("background-color", "transparent");
				if(!callbackEnabled) {
					c3jQuery("#CentionStartView").addClass("withoutCallback");
					if(useDynamicArea) {
						c3jQuery("#CentionStartView").addClass("withAreas");
					}
				}
			}

			//Status of the arrow icon
			if (c3jQuery('#CentionChatBody').is(':visible')) {
				c3jQuery( "#toggleChatHeader" ).removeClass( "fa-chevron-up");
				c3jQuery( "#toggleChatHeader" ).addClass( "fa-chevron-down");
			}else {
				c3jQuery( "#toggleChatHeader" ).addClass( "fa-chevron-up");
				c3jQuery( "#toggleChatHeader" ).removeClass( "fa-chevron-down");
			}

			//Setup extra field setup if provided in the config
			var extraFieldParam = {};
			if(getCustomUI("extraFieldEnabled")){
				const extraFieldlabel = getCustomUI("extraFieldDisplayName") ? getCustomUI("extraFieldDisplayName") : "";
				extraFieldParam = {
					field:[
						{
							id: "textInputExtraField",
							label: extraFieldlabel,
							required: getCustomUI("extraFieldMandatory") ? getCustomUI("extraFieldMandatory") : false,
						},
					],
					template: "<div><span>"+extraFieldlabel+" : </span><span> {EXTRA_FIELD_VALUE} </span></div>"
				}
				c3jQuery("#textInputEmail").after("<input id="+extraFieldParam.field[0].id+" type='text'>");
				if(extraFieldParam.field[0].required) {
					c3jQuery('#textInputExtraField').attr("placeholder", extraFieldParam.field[0].label+" *");
				}else {
					c3jQuery('#textInputExtraField').attr("placeholder", extraFieldParam.field[0].label);
				}
			}

			//EULA Acceptance setup
			if(getCustomUI("askEULA")){
				c3jQuery("#EULAAcceptance").show();
			}else{
				c3jQuery("#EULAAcceptance").hide();
			}

			//Hide list of agent's avatars on the chat form
			if(getCustomUI("hideAgentAvatarList")){
				c3jQuery("#agentAvatarList").hide();
			}else{
				c3jQuery("#agentAvatarList").show();
			}

			//Work on dynamic area
			if(useDynamicArea){
				const noPreSelectArea = getCustomUI("avoidPreselectArea");
				const areaCounts = params.areaId.length;
				if (c3jQuery('#selectAreaId')) {
					c3jQuery.each(params.areaId, function(index, item) {
						var itemHTML;
						if(areaCounts <= 4 && getCustomUI("showAllAreaList")){
							c3jQuery('#selectAreaId').css({'display': 'none'});
							itemHTML = "<label>";
							itemHTML += "<input type='radio' name='centionAreaSelector' class='centionAreaSelector' id=area_"+item.id+" value="+item.id+">";
							itemHTML += "<div>"+item.name+"</div></label>";
							c3jQuery("#area_"+areaId).prop("checked", true);
							c3jQuery('#selectAreaIdOpts').append(itemHTML);
						}else{
							c3jQuery('#selectAreaIdOpts').css({'display': 'none'});
							itemHTML = '<option value="{ID}">{NAME}</option>'
							.replace("{ID}", item.id)
							.replace("{NAME}", item.name);
							c3jQuery('#selectAreaId').append(itemHTML);
						}
					});
					if(noPreSelectArea) {
						if(!getCustomUI("showAllAreaList")){
							let noArea = '<option value="-1" selected>{NAME}</option>'
								.replace("{NAME}", getCustomText("textNoSelectArea"));
							c3jQuery('#selectAreaId').prepend(noArea);
						}
					}
				}
			}else{
				c3jQuery('#selectAreaId').css({'display': 'none'});
				c3jQuery('#selectAreaIdOpts').css({'display': 'none'});
			}


			// This function used to reset the position and width of
			// the header which is glued to the bottom of the page.
			// This function is called when clicking on the header
			// and when the start button is clicked.
			function CentionChatResetBodySize(status) {
				var headerWidth = '175px';
				if(getCustomSkin() == SKIN_MODERN){
					headerWidth = CHAT_WIDGET_WIDTH;
				}
				if(getPosition == "bottomRight" || getPosition == "bottomLeft") {
					if(status == "resumeSession") {
						c3jQuery('#CentionChatHeader').css({
							'bottom': bottomPosition ? bottomPosition + 'px' : '0px'
						});
						c3jQuery('#CentionChatBody').hide();
						c3jQuery('#CentionChatHeader').removeClass("maximized")
					}else if (status == "resumeFailed"){
						c3jQuery('#CentionChatHeader').css({
							'bottom': bottomPosition ? bottomPosition + 'px' : '0px'
						});
						c3jQuery('#CentionChatBody').css({
							'display': 'none'
						});
					}else {
						c3jQuery('#CentionChatHeader').css({
							'bottom' : bottomPosition ? bottomPosition + c3jQuery('#CentionChatBody').innerHeight() + 'px' : c3jQuery('#CentionChatBody').innerHeight() + 'px',
							'width': headerWidth,
							'display': 'flex'
						});
						c3jQuery('#CentionChatBody').css({
							'bottom' : bottomPosition ? bottomPosition + 'px' : 0,
							'left'   : widgetLocation()[0],
							'right'  : widgetLocation()[1],
						});

					}
				}else {
					if(getPosition == "centerRight"){
						if(c3jQuery('#CentionChatBody').is(':visible')) {
							c3jQuery('#CentionChatHeader').css({
								'right': '-60px',
								'display': 'flex'
							});
							if(status == "connected") {
								c3jQuery('#CentionChatHeader').css({
									'right': c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px',
									'display': 'flex'
								});
								c3jQuery('#CentionChatBody').css({
									'right': '0px',
									'display': 'block',
								});
							}else {
								if(status == "restart") {
									c3jQuery('#CentionChatHeader').css({
										'right': c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px',
										'display': 'flex'
									});
								} else {
									c3jQuery('#CentionChatHeader').css({
										'right': -73 + 'px',
										'display': 'flex'
									});
									c3jQuery('#CentionChatBody').css({
										'right': '0px',
										'display': 'none'
									});
								}
							}
						}else {
							if(c3jQuery('#CentionNoAgentsView').css('display') == 'none'){
								c3jQuery('#CentionChatBody').css({
									'right': '0px',
									'display': 'block',
								});
							}else{
								c3jQuery('#CentionChatBody').css({
									'right': '0px',
									'display': 'block',
								});
							}
							c3jQuery('#CentionChatHeader,#CentionProactiveButton').css({
								'right': c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px',
								'display': 'flex'
							});
						}
					}else {
						//left
						if(c3jQuery('#CentionChatBody').is(':visible')){
							if(status == "connected"){
								c3jQuery('#CentionChatBody').css({
									'left': '0px',
									'display': 'block',
									});
								c3jQuery('#CentionChatHeader').css({
									'left': c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px',
									'display': 'flex'
								});
							}else {
								if(status == "restart") {
									c3jQuery('#CentionChatHeader').css({
										'left': c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px',
										'display': 'flex'
									});
								}else {
									c3jQuery('#CentionChatHeader').css({
										'left': -73 + 'px',
										'display': 'flex'
									});
									c3jQuery('#CentionChatBody').css({
										'left': '0px',
										'display': 'none'
									});
								}
							}
						}else {
							if(c3jQuery('#CentionNoAgentsView').css('display') == 'none'){
								c3jQuery('#CentionChatBody').css({
									'left': '0px',
									'display': 'block',
								});
							}else{
								c3jQuery('#CentionChatBody').css({
									'left': '0px',
									'display': 'block',
								});
							}
							c3jQuery('#CentionChatHeader').css({
								'left': c3jQuery('#CentionChatBody,#CentionProactiveButton').outerWidth() - 73 + 'px',
								'display': 'flex'
							});
						}
					}
				}
			}
			//The sendPreview function call occurs when user is typing a message.
			//It sends information to the system that user is typing a message.
			function sendPreview () {
				//message is pass through CentionChat.preview(message) function to send notification that user is writing a message.
				// And system shows this notification at agent side.
				CentionChat.preview(c3jQuery('#CentionChatQuestion').html());
				//previewCount is reset after sending information. It sends information to system after 5 previewCount.
				previewCount = 0;
			}

			/****************************************/
			/* Register Cention chat event handlers */
			/****************************************/

			// The "connect error" event occurs if it wasn't possible to connect
			// the web browser to the Cention chat server.
			CentionChat.registerAction('connect_error', function(err) {
				console.log("Connecting...");
				renderChatLoaderHeader();
				c3jQuery('#CentionChatStatus').show();
				c3jQuery('#CentionChatConnected').hide();
			});

			if (ccs.resumable) {
				// When there's still active chat, the chat will expanded automatically
				// Unless minimized by client
				CentionChat.connect({
					resume: true,
					area: -1,
					callback: function(msg) {
						if(msg.sessionId) {
							// c3jQuery('#CentionChatView').css({
							// 	"border-top-left-radius" : "20px"
							// });
							c3jQuery('#CentionStartView').hide();
							showChatBody();
							c3jQuery('#CentionChatView').show();
							c3jQuery('#CentionChatChannelView').hide();
							CentionChatResetBodySize("resumeSession");
							c3jQuery('#textInputName').val(msg.clientName);

							//todo @sue check if co browse , if yes, re initiated the co browsing session?
							//console.log("TODO Resume co browse ? ", msg.coBrowse);
							if(msg.coBrowse) {
								resumeCoBrowsing(msg.sessionId);
							}
							if(ccs.feature["chat.attachment"]){
								c3jQuery("[id*='CentionChatSendFileButtonWrapper']").show();
							} else {
								c3jQuery("[id*='CentionChatSendFileButtonWrapper']").hide();
							}
						}else {
							console.log(msg.error);
							if (c3jQuery('#CentionChatChannelView, #CentionChannelView').is(':visible')) {
								c3jQuery('#CentionStartView').hide();
							} else {
								c3jQuery('#CentionStartView').show();
							}
							c3jQuery('#CentionChatView').hide();
							hideChatBody();
							if (!c3jQuery('#CentionChatBody').is(':visible')) {
								if(getCustomUI("position") == "bottomRight" || getCustomUI("position") == "bottomLeft" ){
									c3jQuery('#CentionChatHeader').css({
										bottom : getCustomUI("bottomPosition") ? c3jQuery("#CentionChatHeader").css("bottom", getCustomUI("bottomPosition")) : ''
									});
								}else {
									if(getCustomUI("position") == "centerLeft"){
										c3jQuery('#CentionChatHeader').css({
											left: "-105px"
										});
									}else {
										c3jQuery('#CentionChatHeader').css({
											right: "-105px"
										});
									}
								}
							}
						}
					}
				});
			}
			// This event occures when queue is full.
			// If Cention Contact Center is configured to allow clients to enter a queue and wait for an agent then
			// this event occurs if the maximum amount of chats that can be queued at the same time has been reached.
			CentionChat.registerAction('queue full', function() {
				const AGENT_BUSY = getCustomText("textMessageAgentBusy");
				c3jQuery('#CentionChatList').append('<div class="system" id="textMessageAgentBusy">'+Linker.linkifyUrls(AGENT_BUSY)+'</div>');
			});

			function expandFAQChat(){
				c3jQuery("#CentionFAQ").show();
				c3jQuery("#trigger-panel-open").hide();
				c3jQuery("#trigger-panel-close").show();

				c3jQuery("#ViewPage-Chat").show();
				c3jQuery("#iconReturnPrevious").hide();
				c3jQuery("#iconBackHeader").show();
				c3jQuery("#FAQBreadcrumbs").show();

				if(CentionChatStatus.error) {
					c3jQuery('#CentionStartView').hide();
					c3jQuery("#CentionChatView").hide();
					c3jQuery("#CentionNoAgentsView").hide();
					c3jQuery("#CentionErrorInfo").show();
					c3jQuery("#CentionErrorInfo").text("Error: "+CentionChatStatus.error);
				}else {
					c3jQuery("#CentionChatView").show();
					c3jQuery("#CentionNoAgentsView").hide();
					c3jQuery("#CentionErrorInfo").hide();
				}
				c3jQuery("#ViewPage-Home").hide();
				c3jQuery("#FAQStandardContainer").hide();
				c3jQuery("#FAQChatContainer").show();
			}

			function expandConnectedChat(){
				CentionChatResetBodySize("connected");
				showChatBody();
				c3jQuery( "#toggleChatHeader" ).removeClass( "fa-chevron-up");
				c3jQuery( "#toggleChatHeader" ).addClass( "fa-chevron-down");

				if(useFAQChatInChat) {
					expandFAQChat();
				}
			}

			const classPrefix = 'Cention';
			function className(name) {
				return classPrefix + name;
			}

			const twoticks = [
				'<span class="' + className('twoticks') + '" style="display:none;margin-left:3px;">',
				'<span id="textTickSent" class="'+ className('message_sent') + ' " style="color:' + getCustomConfig('tickUnsentColor') + ';">' + getCustomText('textTickSent') + '</span>',
				'<span id="textTickRead" class="'+ className('message_read') + ' " style="color:' + getCustomConfig('tickUnreadColor') + ';margin-left:' + getCustomConfig('tickKerningOffset') + ';">' + getCustomText('textTickRead') + '</span>',
				'</span>'
			].join('')
			, colorSent = getCustomConfig('tickSentColor')
			, colorRead = getCustomConfig('tickReadColor')
			, selectorTicksSent = '.' + className('twoticks') + ' .' + className('message_sent')
			, selectorTicksRead = '.' + className('twoticks') + ' .' + className('message_read')
			, selectorTwoTicks = '.' + className('twoticks')
			;

			function markAsRead(je) {
				markTickRead(je.find(selectorTicksRead));
			}
			function markTickSent(je) {
				je.css({color:colorSent});
			}
			function markTickRead(je) {
				je.css({color:colorRead});
			}

			function addNewMessage(msg, clientName, agentAvatar) {
				// Everytime new message arrives, chat should be automatically expanded.
				if(_chatIsMinimized){
					expandConnectedChat();
				}
				const chatList = c3jQuery('#CentionChatList');
				var klass = "text"
					, messageClass = ''
					, id = ''
					, newMsg
					, msgAvatar
					;
				msgAvatar = '<div class="message-avatar"><i class="icon-user-circle"></i></div>';
				var chatWebroot = RegExp('/Cention/web/chat/client', 'g');
				switch(msg.sender) {
					case "AGENT":
						sender = msg.agent;
						if(agentAvatar){
							msgAvatar = '<div class="message-avatar"><img src='+CentionChat.baseURL+spacePrefix+agentAvatar+'></div>';
						}
						break;
					case "SYSTEM":
						sender = "System";
						break;
					case "CLIENT":
						sender = clientName;
						messageClass = 'fromClient';
						if(clientAvatar){
							if(clientAvatar.startsWith("/Cention/web/chat/client")) {
								clientAvatar = clientAvatar.replace(chatWebroot,CentionChat.baseURL+spacePrefix+"/Cention/web/chat/client");
							}
							msgAvatar = '<div class="message-avatar"><img src='+clientAvatar+'></div>';
						}
						break;
					default:
						if(msg.fromClient) {
							messageClass = 'fromClient';
						}
						sender = msg.sender;
				}

				if(msg.text != null ) {
					if (msg.unsent) {
						// Render it as unsent.
						klass += ' unsent-message';
					}

					if (msg.id) {
						id = msg.id;
					}

					newMsg = c3jQuery('<div class="message ' + messageClass + '">' +
					'<div class="message-content">' +
					'<div id="' + id + '" class="' + klass + '"><span class="textMsg">' + Linker.linkifyUrls(msg.text) + '</span></div>' +
					'<div class="info"><span class="sender">' + sender + '</span> &middot; ' +
					'<span class="sent">' + (msg.unsent? "__:__" : msg.sentHuman) + '</div>' +'</div>'+
					'</div>');

					var chatAvatar = c3jQuery(msgAvatar);
					newMsg.append(chatAvatar);

					if (msg.sender === "CLIENT") {
						newMsg.find('.info').append(twoticks);
						if (!msg.unsent) {
							markTickSent(newMsg.find(selectorTicksSent));
						}
						if (msg.read) {
							markTickRead(newMsg.find(selectorTicksRead));
						}
						newMsg.find(selectorTwoTicks).show();
					}
					chatList.append(newMsg);
				}

				// Message Background Color - Client msg, use custom if specified
				//c3jQuery("#CentionChatList > div.fromClient > div > .text > span.textMsg").css("background-color",getCustomConfig("messageBackgroundColor")); original
				c3jQuery("#CentionChatList > div.message > div.message-content").css("background-color",getCustomConfig("messageBackgroundColorAgent"));
				c3jQuery("#CentionChatList > div.message.fromClient > div.message-content").css("background-color",getCustomConfig("messageBackgroundColor"));

				c3jQuery("#CentionChatList > div.message > div.message-content .info").css("color",getCustomConfig("chatConversationInfoTextColor"));
				// Scroll the message list to the bottom
				chatList.scrollTop(chatList.prop('scrollHeight'));
			}

			function updateChatHeader(agentData) {
				var html;
				if (agentData) {
					if(_chatTitle.length > 23){
						_chatTitle = _chatTitle.substring(0,23) + '...';
					}
					html = _chatTitle+" -<i>"+agentData+"</i>";
				} else {
					html = _chatTitle
				}
				// c3jQuery("#CentionChatHeader > span.text").css("width", "150px");
				c3jQuery('#CentionChatHeader > .text').html(html);
			}

			function showQueuePosition(pos) {
				if (c3jQuery('#ChatQueuePosition')) {
					c3jQuery('#CentionChatList').children('#ChatQueuePosition').remove();
				}
				if (pos> 0) {
					c3jQuery('#CentionChatList').append('<div class="system" id="ChatQueuePosition">'+getCustomText("textMessageQueueing")+" "+pos+'</div>');
				}
			}

			if (c3jQuery('#CentionChatWelcome').is(':visible')) {
				c3jQuery('#CentionChatStatus').show();
				renderChatLoaderHeader();
			}
			else {
				c3jQuery('#CentionChatStatus').hide();
			}

			// The "chat message" even occurs both when a new message from the agent has been received
			// and when the client has sent a message. The data structure contains information about
			// the whole chat session and new messages sent by the client and agent.
			CentionChat.registerAction('chat message', function(data) {
				// Show agent assigned only if agent responded to the chat
				if (!agentResponded) {
					c3jQuery.each(data.chat, function(index, msg){
						if (msg.aid != 0) {
							agentResponded = true;
							return false;
						}
					});
				}
				// Display information about who the client was connected to
				if (agentResponded && data.agent) {
					verifyCookie();
					c3jQuery('#CentionChatWelcome').hide();
					c3jQuery(".agentConnectedTo").show();
					c3jQuery('#CentionChatConnectedAgent').html(data.agent);
					updateChatHeader(data.agent);
					var connectedTxt = c3jQuery("#textStatusConnected").text();
					connectedTxt = getCustomText("textStatusConnected")+" "+data.agent+".";
					c3jQuery(".vid-remote-name").text(data.agent);
					const clientName = c3jQuery("#textInputName").val() || "You";
					c3jQuery(".vid-local-name").text(clientName);
					c3jQuery("#textStatusConnected").text(connectedTxt);
					c3jQuery('#CentionChatConnected').show();
					c3jQuery('#CentionChatStatus').hide();
					if(getCustomUI('chatRatingsEnabled')){
						if(triggerSatisfaction){
							c3jQuery('#Satisfaction').slideDown();
						}
					}
					if(CentionChat.haveCookie === "no") {
						// To re-adjust position after the cookie warning msg rendered.
						CentionChatResetBodySize("connected");
					}
					showQueuePosition(0);

					// Send signal to agent to indicate client ready to accept video call / screen sharing
					if(ccs.feature['chat.allow-video-call'] || ccs.feature['chat.allow-screen-sharing'] || ccs.feature['chat.allow-co-browsing']) {
						//Only request when turndToken empty
						if(turndToken === "") {
							CentionChat.registerVideoCall('video ready', user, function() {
								if(getCustomUI("enableVideoChat")) {
									c3jQuery("#CentionChatVideoCallWrapper").show();
								}
							});
						}
					}
				}else {
					// We are connected but agent has yet to respond to it
					c3jQuery('#CentionChatStatus').hide();
					c3jQuery('#CentionChatWelcome').show();
					c3jQuery("#CentionChatConnected").hide();
					renderChatLoaderHeader();
					updateChatHeader(null);
				}
				// Remove agent typing notification message from the message list
				c3jQuery('#CentionChatList').children('.agentTyping').remove();
				// Walk through the new messages from the client and the agent
				// and add them to the message list so that they are shown to
				// the client
				c3jQuery.each(data.chat, function(index, msg){
					var sender
					, unsent
					;
					if (document.getElementById(msg.id)) {
						// This message is already rendered.
						return;
					}
					if (msg.umid) {
						unsent = document.getElementById('unsent-'+msg.umid);
						if (unsent) {
							// This is a message that is sent by
							// client but somehow failed to
							// receive the corresponding 'message
							// sent' acknowledgement. Mark it as
							// sent.
							unsent.setAttribute('id', msg.id);
							c3jQuery(unsent).removeClass('unsent-message');
							c3jQuery(unsent).find('.sent').html(msg.sentHuman);
							return;
						}
					}
					if(data.clientAvatar){
						clientAvatar = data.clientAvatar.url;
						var avatarClientOverlay = '<img class="photo" src='+baseURL+clientAvatar+'>';
						if(clientAvatar) {
							c3jQuery("#AvatarOverlayLocal").html(avatarClientOverlay);
						}
					}

					var replyAgentAvatar = "";
					if(msg.aid){
						var listAvatar = data.agentsAvatar;
						if(listAvatar.length > 0){
							for (var i = 0; i < listAvatar.length; i++) {
								var avt = listAvatar[i];
								if (msg.aid === avt.id) {
									replyAgentAvatar = avt.avatar;
								}
							}
						}
					}
					var avatarAgentPath = "";
					if(replyAgentAvatar){
						if(avatarAgentPath !== replyAgentAvatar) {
							avatarAgentPath = replyAgentAvatar;
							var avatarAgentOverlay = '<img class="photo" src='+CentionChat.baseURL+spacePrefix+avatarAgentPath+'>';
							c3jQuery("#AvatarOverlayRemote").html(avatarAgentOverlay);
						}
					}
					addNewMessage(msg, data.client ,replyAgentAvatar);
				});
			});

			CentionChat.registerAction('message acked', function(msg) {
				for (var i=0;i<msg.ids.length;i++) {
					markAsRead(c3jQuery('#'+msg.ids[i]).parent());
				}
			});

			// This 'agent preview' event occurs when agent is typing a message. It shows notification message at the client side.
			// Notification shows as a message 'agent is typing a message' in the bottom of the message list.
			CentionChat.registerAction('agent preview', function ( data ) {
				// If message length is zero, remove the notification message.
				if(c3jQuery('.agentTyping').length && data.messageLen == 0) {
					// Remove the notification message if agent is not typing anything.
					c3jQuery('#CentionChatList').children('.agentTyping').remove();
				}
				// If the message length is greater than zero, show the notification message.
				if(!c3jQuery('.agentTyping').length && data.messageLen > 0) {
					// Append the notification message to message list when agent is typing a message.
					c3jQuery('#CentionChatList').append('<div class="agentTyping">'+CentionChat.I("Agent is typing a message...")+'</div>');

					// When agent start typing, use it to determine agent assigned and attending to it
					agentResponded = true;
					c3jQuery('#CentionChatWelcome').hide();
					c3jQuery("#CentionChatConnected").show();
				}
				// Scroll the message list to the bottom
				c3jQuery('#CentionChatList').scrollTop(c3jQuery('#CentionChatList').prop('scrollHeight'));
			});

			// The "queue" event occurs when the client's position in the queue changes.
			CentionChat.registerAction('queue', function(n) {
				c3jQuery('#CentionChatStatus').html("");
				c3jQuery('#CentionChatWelcome').show();
				c3jQuery('#textMessageCurrentQueue').text(getCustomText("textMessageCurrentQueue")+" "+n);
				showQueuePosition(n);
			});
			// The "finish chat session" event occurs if the client disconnects, the agent ends
			// the chat or if the chat expires due to inactivity.
			// Inactivity is determined by when the last message was sent.
			CentionChat.registerAction('finish chat session', function(data) { // data.closedBy = client, agent or expired
				c3jQuery('#CentionChatList').append('<div class="system chatEnded" id="textMessageChatEnded">'+getCustomText("textMessageChatEnded")+'</div>');
				deactivateChatUI();
			});
			// The 'agent unavailable' occurs when no active agent for chat
			CentionChat.registerAction('agent unavailable', function() {
				c3jQuery('#CentionChatList').append('<div class="system chatEnded" id="textMessageChatEnded">'+getCustomText("textMessageChatEnded")+'</div>');
				deactivateChatUI();
			});

			CentionChat.registerAction('message sent', function(um) {
				var div = document.getElementById('unsent-'+um.umid)
				, je
				;
				if (div) {
					div.setAttribute('id', um.id);
					c3jQuery(div).removeClass('unsent-message');
					je = c3jQuery(div.parentNode);
					je.find('.sent').html(um.sentHuman);
					markTickSent(je.find(selectorTicksSent));
				}
			});

			/******************************/
			/* Regster DOM event handlers */
			/******************************/

			// Register even handler for when the chat header
			// is clicked which will either show or hide
			// the chat.

			function showChatLoader() {
				c3jQuery('#CentionChatBody').css("background-image","url("+_preloaderURL+")");
			}

			function hideChatLoader() {
				c3jQuery('#CentionChatBody').css("background-image","");
			}

			function renderChatLoaderHeader() {
				const iconSpinner = "icon-spinner rotating";
				c3jQuery('#CentionChatHeader > .text').html(`<i class="${iconSpinner}"></i> `+getCustomText("textStatusConnecting"));

			}

			function renderChatNotOpen(status, agentsOnline) {
				retryConn(false);
				const getPosition = getCustomUI("position");
				c3jQuery( "#toggleChatHeader" ).removeClass( "fa-chevron-up");
				c3jQuery( "#toggleChatHeader" ).addClass( "fa-chevron-down");

				if(!status){
					if(useDynamicArea) {
						c3jQuery("#selectAreaId").insertBefore("#textInputName");
						c3jQuery("#selectAreaIdOpts").insertBefore("#textInputName");
					}
				}else{
					if(useDynamicArea) {
						c3jQuery("#selectAreaId").insertBefore("#CentionChatRetryWrapper");
						c3jQuery("#selectAreaIdOpts").insertBefore("#CentionChatRetryWrapper");
					}
				}

				if(getPosition == "bottomRight" || getPosition == "bottomLeft" ) {
					hideChatLoader();
					showChatBody();
					c3jQuery('#CentionStartView').hide();
					c3jQuery('#CentionChatView').hide();
					if(agentsOnline <= 0 || typeof agentsOnline == "undefined"){
						c3jQuery('#CentionNoAgentsView').show();
						c3jQuery('#CentionChatChannelView').hide();
					}else {
						c3jQuery('#CentionStartView').show();
					}
					c3jQuery("#textMessageAway").text(getCustomText("textMessageAway"));
					if(getCustomUI("buttonAwayEnable") && !getCustomUI("showContactFormWhenAway")){
						c3jQuery('div > a#textButtonAway').show();
					}else {
						c3jQuery('div > a#textButtonAway').hide();
					}
					CentionChatResetBodySize("disconnected");
				}else {
					hideChatLoader();
					//should expand left or right
					c3jQuery('#CentionStartView').hide();
					c3jQuery('#CentionChatView').hide();
					c3jQuery('#CentionChatChannelView').hide();
					c3jQuery('#CentionNoAgentsView').show();
					c3jQuery("#textMessageAway").text(getCustomText("textMessageAway"));
					if(getCustomUI("buttonAwayEnable") && !getCustomUI("showContactFormWhenAway")){
						c3jQuery('div > a#textButtonAway').show();
					}else{
						c3jQuery('div > a#textButtonAway').hide();
					}
					CentionChatResetBodySize("disconnected");
				}
				if(getCustomUI("showContactFormWhenAway")) {
					c3jQuery("#CentionNoAgentsView").addClass("showForm");
					c3jQuery('#CentionContactForm').show();
					c3jQuery('#CentionChatRetryWrapper').hide();
				}
				c3jQuery("#CentionChatProactiveDialog").hide();
				c3jQuery("#CentionProactiveButton").hide();
			}

			function verifyCookie(num) {
				if(getCustomUI("cookieWarning")){
				//verify cookie usage
					if(CentionChat.haveCookie === "no") {
						const cookieWarning = (getCustomText("textCookieWarning"));
						c3jQuery("#CentionCookieWarning > .cookieText").text(cookieWarning.replace("{CENTION_BASE_URL}", baseURL));
						c3jQuery("#CentionCookieWarning").show();
						cookieDisabled = true;
					}else {
						c3jQuery("#CentionCookieWarning").hide();
					}
				}
			}

			function runWidgetMinimizeHooks(hide){
				if(hide) {
					if(params.hooks && params.hooks.onChatMinimize) {
						params.hooks.onChatMinimize();
					}
				}else{
					if(params.hooks && params.hooks.onChatMaximize) {
						params.hooks.onChatMaximize();
					}
				}
			}

			function showChatBody() {
				if (c3jQuery('#CentionChatBody').is(':hidden')) {
					c3jQuery('#CentionChatBody').show( 0, function() {
						runWidgetMinimizeHooks(false);
					});
				}
				c3jQuery("#CentionChatHeader").addClass("maximized");
				c3jQuery("#CentionChatHeader").removeClass("minimized");
				c3jQuery('#askEULA').prop('checked', false);
				c3jQuery("#CentionProactiveButton").hide();
			}

			function hideChatBody() {
				if (c3jQuery('#CentionChatBody').is(':visible')) {
					c3jQuery('#CentionChatBody').hide( 0, function() {
						runWidgetMinimizeHooks(true);
					});
				}
				c3jQuery("#CentionChatHeader").addClass("minimized");
				c3jQuery("#CentionChatHeader").removeClass("maximized");
			}

			function renderChatOpen(retry){
				verifyCookie();
				const getPosition = getCustomUI("position");
				c3jQuery( "#toggleChatHeader" ).removeClass( "fa-chevron-up");
				c3jQuery( "#toggleChatHeader" ).addClass( "fa-chevron-down");

				if(!cookieDisabled) {
					if(localStorage.getItem("chatName") != ""){
						c3jQuery("#textInputName").val(localStorage.getItem("chatName"));
					}
					if(localStorage.getItem("chatEmail") != ""){
						c3jQuery("#textInputEmail").val(localStorage.getItem("chatEmail"));
					}
				}

				hideChatLoader();
				if(getPosition == "bottomRight" || getPosition == "bottomLeft" ) {
					showChatBody();
					if(CentionChat.sessionId == "" ) {
						if (c3jQuery('#CentionChatView').is(':visible')) {
							c3jQuery('#CentionStartView').hide();
						}else{
							if(retry) {
								c3jQuery('#CentionChatView').show();
								c3jQuery('#CentionStartView').hide();
							}else{
								if (c3jQuery('#CentionChatChannelView, #CentionChannelView').is(':visible')) {
									c3jQuery('#CentionStartView').hide();
								} else {
									c3jQuery('#CentionStartView').show();
								}
								c3jQuery('#CentionChatView').hide();
							}
						}
					}else {
						c3jQuery('#CentionChatView').show();
					}
					if(retry == "reconnecting" && CentionChat.sessionId) {
						c3jQuery('#CentionChatStatus').show();
						c3jQuery('#CentionChatConnected').hide();
					}
					c3jQuery('#CentionNoAgentsView').hide();
					CentionChatResetBodySize("disconnected");
				}else {
					showChatBody();
					if(CentionChat.sessionId == "") {
						if (c3jQuery('#CentionChatView').is(':visible')) {
							c3jQuery('#CentionStartView').hide();
						} else {
							if (c3jQuery('#CentionChatChannelView, #CentionChannelView').is(':visible')) {
								c3jQuery('#CentionStartView').hide();
							} else {
								c3jQuery('#CentionStartView').show();
							}
						}
					}else {
						c3jQuery('#CentionChatView').show();
					}
					if(retry == "reconnecting" && CentionChat.sessionId) {
						c3jQuery('#CentionChatStatus').show();
						c3jQuery('#CentionChatConnected').hide();
					}
					c3jQuery('#CentionNoAgentsView').hide();
					CentionChatResetBodySize("connected");
				}
				if(useDynamicArea){
					c3jQuery("#selectAreaId").insertBefore("#textInputName");
					c3jQuery("#selectAreaIdOpts").insertBefore("#textInputName");
				}

				c3jQuery("#CentionChatProactiveDialog").hide();
				c3jQuery("#CentionProactiveButton").hide();
			}

			function retryConn(status) {
				if(!status) {
					c3jQuery('#RetryLoader').html("<img src="+_preloaderURL+" />");
					c3jQuery('#RetryLoader').show();
					c3jQuery('#textButtonRetry').hide();

					window.setTimeout(function(){
						c3jQuery('#RetryLoader').hide();
						c3jQuery('#textButtonRetry').show();
					},3000);
				}
			}

			function retryChat(retry) {
				//Calling canChat here is to check whether there's
				//available agents or chat is open
				//whenever chat customer is clicking chat widget header
				//to initiate chat session.

				//This only triggered when chat session not started
				//by clicking widget header
				//or clicking "Retry" button

				//The main purpose was to check if chat is available
				//before need to reloaded the page.
				CentionChat.canChat(function(o) {
					renderChatNotOpen(retry, o.agentsAvailable);
					if (o.error) {
						// It is not possible to start a chat
						if(CentionChat.sessionId) {
							renderChatOpen("reconnecting");
						}else {
							renderChatNotOpen("chatError");
						}
						if(retry) {
							retryConn(false);
						}
						return;
					}

					if (!o.chatOpen) {
						// Chat is not open (outside of working hours)
						renderChatNotOpen("chatClosed");
						if(retry) {
							retryConn(false);
						}
						return;
					}

					if (o.agentsAvailable <= 0) {
						// No agents available
						renderChatNotOpen("agentsNotAvailable");
						if(retry) {
							retryConn(false);
						}
						return;
					}

					if (o.agentsAvailable > 0) {
						renderChatOpen(false);
					}
				}, CentionChat.areaId, true);
			}

			//set selected areaId from areaId list provided
			c3jQuery('#selectAreaId').on('change', function() {
				const areaID = parseInt(this.value);
				if(areaID != -1) {
					c3jQuery('#selectAreaId').removeClass("warning");
				}
				CentionChat.areaId = areaID;
				retryChat(false);
				getAgentList(areaID);
			});

			c3jQuery(".centionAreaSelector").on('change', function() {
				CentionChat.areaId = parseInt(this.value);
				retryChat(false);
			});

			var proactiveOffers = 0;
			var proactiveChatOfferTimeout = 0;
			function scheduleProactiveOffer() {
				setTimeout(function() {
					if (c3jQuery('#CentionChatBody').is(':visible')) {
						scheduleProactiveOffer();
					} else {
						//Calling canChat here is to check whether there's
						//available agents or chat is open
						//to make sure proactive offer popup
						//only appear if agents available and chat is open
						CentionChat.canChat(function(o) {
							if(o.resumable){
								scheduleProactiveOffer();
							}else{
								if (o.chatOpen && o.agentsAvailable > 0) {
									proactiveOffers++;
									c3jQuery("#CentionChatProactiveDialog").show();
									c3jQuery("#CentionProactiveButton").show();
									c3jQuery("#CentionChatHeader").hide();
									proactiveChatOfferTimeout = setTimeout(function() {
										c3jQuery("#CentionProactiveButton").hide();
										c3jQuery("#CentionProactiveButton").hide();
										c3jQuery("#CentionChatHeader").show();
										if (proactiveOffers < getCustomUI("proactiveRepeatedContact")) {
											scheduleProactiveOffer();
										}
									}, getCustomUI("proactiveTimeout") * 1000);
								} else {
									scheduleProactiveOffer();
								}
							}
						}, CentionChat.areaId, false);
					}
				}, (proactiveOffers == 0
					? getCustomUI("proactiveDelayTime") * 1000
					: getCustomUI("proactiveTimeAfterDecline") * 1000));
			}

			if (getCustomUI("proactiveEnable")) {
				if(CentionChat.sessionId == ""){
					scheduleProactiveOffer();
				}
			}

			c3jQuery("#textButtonProactiveLater").click(function(e) {
				e.preventDefault();
				c3jQuery("#CentionChatProactiveDialog").hide();
				c3jQuery("#CentionProactiveButton").hide();
				c3jQuery("#CentionChat").show();
				c3jQuery("#CentionChatHeader").show();
				if (proactiveOffers < getCustomUI("proactiveRepeatedContact")) {
					clearTimeout(proactiveChatOfferTimeout);
					proactiveChatOfferTimeout = 0;
					scheduleProactiveOffer();
				}
			});

			c3jQuery("#CentionProactiveButton,#CentionChatProactiveDialog").click(function(e) {
				e.preventDefault();
				CentionChat.resetChat();
				agentResponded = false;
				clearTimeout(proactiveChatOfferTimeout);
				proactiveChatOfferTimeout = 0;
				c3jQuery("#CentionChatProactiveDialog").hide();
				c3jQuery('#CentionProactiveButton').hide();
				c3jQuery("#CentionChat").show();
				c3jQuery("#CentionChatHeader").show();
				c3jQuery('#Satisfaction').hide()
				activateChatUI();
				if (!c3jQuery('#CentionChatBody').is(':visible')) {
					CentionChatResetBodySize();
					showChatBody();
					if(c3jQuery('#CentionChatChannelView').is(':visible')){
						c3jQuery("#CentionStartView").hide();
					} else {
						c3jQuery("#CentionStartView").show();
					}
				}

				if (getCustomUI("proactiveImmediateStart")) {
					showChatBody();
					c3jQuery("#CentionStartView").hide();
					c3jQuery("#CentionChatView").show();
					c3jQuery('#CentionStartView > input').not(':input[type=button]').removeClass("warning");
					c3jQuery('#CentionStartView > textarea').removeClass("warning");
					c3jQuery("#textStatusConnecting").text(getCustomText("textStatusConnecting"));
					c3jQuery("#CentionChatList").children('.chatEnded').remove();
					c3jQuery(".agentConnectedTo").hide();
					c3jQuery("#CentionChatList").children('.message').remove();

					const clientName = "anonymous-" + Math.random().toString(36).substring(2, 5);

					renderChatLoaderHeader();
					CentionChat.connect({
						area: CentionChat.areaId,         // Unique identifier for the Cention area in which the chat should be started.
						// Determines among other things who can be assigned the chat.
						// This identifier is provided by Cention.
						name: clientName,       // The name of the client
						email: clientName,     // The client's email address
						message: "", // The client's initial question
						externalData: "", // The client's extra info, if provided
						clientDeviceInfo: {
							browserName: browserData.browser.name ? browserData.browser.name : '',
							browserVersion: browserData.browser.version ? browserData.browser.version : '',
							deviceVendor: browserData.device.vendor ? browserData.device.vendor : '',
							deviceModel: browserData.device.model ? browserData.device.model : '',
							deviceType: browserData.device.type ? browserData.device.type : '',
							osName: browserData.os.name ? browserData.os.name : '',
							osVersion: browserData.os.version ? browserData.os.version : '',
							ua: browserData.ua ? browserData.ua : '',
							isMobile: isMobile(browserData.ua)
						}
					});
					c3jQuery('#CentionStartView').hide();
					c3jQuery('#CentionChatStatus').show();
					c3jQuery('#CentionChatView').show();
					c3jQuery('#CentionChatFinishButtonWrapper').show();
					if(ccs.feature["chat.attachment"]){
						c3jQuery("[id*='CentionChatSendFileButtonWrapper']").show();
					} else {
						c3jQuery("[id*='CentionChatSendFileButtonWrapper']").hide();
					}
					c3jQuery('#CentionNewChatButtonWrapper').hide();
					CentionChatResetBodySize("connected");
				}
			});

			c3jQuery(".centionChatTrigger").on('click', function() {
				const hasAgent = ccs.agentsAvailable;
				const getPosition = getCustomUI("position");
				if (c3jQuery('#CentionChatBody').is(':visible')) {
					c3jQuery( "#toggleChatHeader" ).addClass( "fa-chevron-up");
					c3jQuery( "#toggleChatHeader" ).removeClass( "fa-chevron-down");
					if(getPosition == "bottomRight" || getPosition == "bottomLeft" ) {
						hideChatBody();
						c3jQuery('#CentionChatHeader').css({
							'bottom' : getCustomUI("bottomPosition") ? getCustomUI("bottomPosition") : '' ,
							'width'  : ''
						});
					}else {
						CentionChatResetBodySize("disconnected");
					}
					_chatIsMinimized = true;
					if ( useCustomChatTrigger ) {
						c3jQuery('#CentionChatHeader').hide();
					}
				} else {
					if(ccs.resumable) {
						renderChatOpen(true);
					}else {
						CentionChatResetBodySize("init");
						showChatBody();
						//No need to call retry/canChat
						//if there's session
						if(CentionChat.sessionId == ""){
							retryChat(false, hasAgent);
						}else{
							renderChatOpen(true);
						}
					}
					if (c3jQuery('#CentionChatStatus').is(':visible')) {
						retryChat(true);
					}
					_chatIsMinimized = false;
				}
			});

			c3jQuery("#chatWithUs").on("click", function(){
				// c3jQuery("#agentAvatarList").show();
				c3jQuery("#CentionStartView").show();
				c3jQuery("#CentionChatChannelView").hide();
				c3jQuery("#CentionChatHeader").css({
					'bottom' :c3jQuery('#CentionChatBody').innerHeight() + 'px',
				});
			});

			c3jQuery("#CentionChatAudioCallButton").on("click", function(){
				//show want to call now on phone number
				//or schedule a call
				const callbackOpt = c3jQuery('#CentionCallbackList');
				callbackOpt.show();
				c3jQuery("#CentionChatList").append(callbackOpt);
				c3jQuery("#CentionWhereToCall").show();
				c3jQuery("#CentionScheduleCall").hide();

				//assigning custom text
				c3jQuery("#CentionMsgWhereToCallTxt").text(getCustomText("textMsgWhereToCall"));
				c3jQuery('#CentionMsgToCall').text(getCustomText("textMsgToCall"));
				c3jQuery('#CentionCallPhoneNow').val(getCustomText("textMsgCallNow"));
				c3jQuery('#CentionCallScheduleLater').val(getCustomText("textMsgCallSchedule"));
				c3jQuery('#CentionMsgSend').val(getCustomText("textBtnSend"));
				c3jQuery('#CentionMsgCancel').val(getCustomText("textBtnCancel"));

				scrollToBottom();

			});

			c3jQuery("#CentionCallScheduleLater").on("click", function(){
				c3jQuery("#CentionScheduleCall").show();
				scrollToBottom();
			});

			c3jQuery("#CentionCallPhoneNow").on("click", function(e){
				let phone = "";
				if(localStorage.getItem("chatPhone") != ""){
					phone = localStorage.getItem("chatPhone");
				}
				callNowNotif();

			});

			function callNowNotif() {
				const data = { phone: localStorage.getItem("chatPhone") };
				CentionChat.registerCallAction('CALLBACK_REQUEST_NOW', data, function() {
					console.log("call now requested");
				});
			}

			function callLaterNotif(datetime) {
				const data = { phone: localStorage.getItem("chatPhone"), datetime: datetime };
				CentionChat.registerCallAction('CALLBACK_REQUEST_SCHEDULE', data, function() {
					console.log("call scheduled requested");
				});
			}

			//scroll to call options
			function scrollToBottom() {
				const objDiv = document.getElementById("CentionChatList");
				objDiv.scrollTop = objDiv.scrollHeight;
			}

			//send the callback request
			c3jQuery('#CentionMsgSend').on('click', function(e) {
				if(!isValidDate) {
					return false;
				}
				const formName = localStorage.getItem("chatName");
				const formEmail = localStorage.getItem("chatEmail");
				const formPhone = localStorage.getItem("chatPhone");
				const formQuestion = localStorage.getItem("chatSubject");
				const subj = formQuestion.substring(0, 30);
				const createErrandURL = baseURL + spacePrefix + "/socket/external.api/createerrand";
				c3jQuery.post( createErrandURL, {"area": areaId, "name": formName, "from": formEmail, "phone": formPhone, "subject": subj, "body": formQuestion, "callbackSchedule": selectedDateTime, channel: "VOICE"})
				.done(function( data ) {
					callLaterNotif(selectedDateTime);
				})
				.fail(function( err ) {
					console.log("Error", err);
				});
			});

			c3jQuery("#CentionMsgCancel").on("click", function(){
				c3jQuery("#CentionScheduleCall").hide();
			});

			//only in FAQ_WIDGET part , TO FIX: better implemetation

			// notify/retry if there is no agent in FAQ
			if(useFAQChatInChat){
				c3jQuery('#iconContact, #BrowseContact').on('click', function() {
						if(CentionChat.sessionId == ""){
							retryChat(false);
						}else{
							renderChatOpen(true);
						}
						if (c3jQuery('#CentionChatStatus').is(':visible')) {
							retryChat(true);
						}
						_chatIsMinimized = false;
				});

				c3jQuery('#FAQChatLauncher').on('click', function() {
						if(CentionChat.sessionId == ""){
							retryChat(false);
						}else{
							renderChatOpen(true);
						}
						if (c3jQuery('#CentionChatStatus').is(':visible')) {
							retryChat(true);
						}
						_chatIsMinimized = false;
				});

				c3jQuery("#ViewPage-Answer").on("click", ".FeedbackDownvote", function(){
					if(CentionChat.sessionId == ""){
						retryChat(false);
					}else{
						renderChatOpen(true);
					}
					if (c3jQuery('#CentionChatStatus').is(':visible')) {
						retryChat(true);
					}
					_chatIsMinimized = false;
				});

				c3jQuery("#ViewPage-BrowseTree").on("click", ".FeedbackDownvote", function(){
					if(CentionChat.sessionId == ""){
						retryChat(false);
					}else{
						renderChatOpen(true);
					}
					if (c3jQuery('#CentionChatStatus').is(':visible')) {
						retryChat(true);
					}
					_chatIsMinimized = false;
				});
			}
			//end of FAQ WIDGET part

			//Restart Chat
			c3jQuery('#CentionNewChatButton').on('click', function() {
				agentResponded = false;
				showChatBody();
				c3jQuery("#CentionChatView").hide();
				c3jQuery("#CentionCallbackList").hide();
				if(hasChannel){
					c3jQuery("#CentionChatChannelView").show();
					c3jQuery("#CentionChannelView").show();
					c3jQuery("#CentionStartView").hide();
					c3jQuery("#CentionChatHeader").css({
						'bottom' :c3jQuery('#CentionChatBody').innerHeight() + 'px',
					});
				}
				c3jQuery("#CentionChatHeader").css({
					'bottom' :c3jQuery('#CentionChatBody').innerHeight() + 'px',
				});
				retryChat(false);
				CentionChat.resetChat();
			});

			//Retry Chat
			c3jQuery('#textButtonRetry').on('click', function() {
				retryChat(true);
			});

			// Check email validity
			function isValidEmail(emailAddress) {
				const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i;
				return re.test(emailAddress);
			}

			// Check phone number validity
			function isValidPhone(phone) {
				const validOnePhoneRegex = /[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*/;
				return validOnePhoneRegex.test(phone);
			}

			function safeHTML(html) {
				/* Based on guideless here : https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html */
				let safehtml = html;
				safehtml = safehtml.replaceAll('&', '&amp;');
				safehtml = safehtml.replaceAll('<', '&lt;');
				safehtml = safehtml.replaceAll('>', '&gt;');
				safehtml = safehtml.replaceAll('"', '&quot;');
				safehtml = safehtml.replaceAll("'", '&#x27;');
				safehtml = safehtml.replaceAll('/', '&#x2F;');
				return safehtml;
			}

			function verifyTxtField(txt) {
				let isTxtHtml = isHTML(txt);
				if(isTxtHtml) {
					let verifiedTxt = txt;
					verifiedTxt = safeHTML(txt);
					return verifiedTxt;
				}
				return txt;
			}

			// Verify start input
			var clientName = "", email = "", startQuestion = "", extraFieldDiv = "";
			function verifyStartInput() {
				clientName = c3jQuery("#textInputName").val();
				email = c3jQuery("#textInputEmail").val();
				phone = c3jQuery("#textInputPhone").val();
				startQuestion = c3jQuery("#textInputQuestion").val();
				extraFieldDiv = c3jQuery('#textInputExtraField');

				//checking area
				const noPreSelectArea = getCustomUI("avoidPreselectArea");
				if(noPreSelectArea) {
					//an area must be selected
					const selectedArea = c3jQuery("#selectAreaId").val();
					if(selectedArea == -1) {
						console.log("Please select area");
						c3jQuery("#selectAreaId").addClass("warning");
						return false;
					}
				}

				if(extraFieldDiv.length > 0 && extraFieldParam.field[0].required) {
					var extraField = escapeHTML(c3jQuery('#textInputExtraField').val());
				}
				if(callbackEnabled && phone == "" ) {
					c3jQuery("#textInputPhone").addClass("warning");
					return false;
				}
				if((clientName == "") || (email == "") || (startQuestion == "") || (typeof extraField !== "undefined" && extraField == "")) {
					if(clientName == ""){
						c3jQuery("#textInputName").addClass("warning");
					}
					if(email == ""){
						c3jQuery("#textInputEmail").addClass("warning");
					}
					if(startQuestion == ""){
						c3jQuery("#textInputQuestion").addClass("warning");
					}
					if(extraField == ""){
						c3jQuery("#textInputExtraField").addClass("warning");
					}
					return false;
				}else {
					if(clientName) {
						const nameInvalid = isHTML(clientName);
						if(nameInvalid) {
							c3jQuery("#textInputName").addClass("warning");
							c3jQuery('#textInputName').attr("placeholder", getCustomText("textNameInvalid"));
							c3jQuery("#textInputName").val("");
							return false;
						} else {
							const emailValid = isValidEmail(email);
							if(emailValid) {
								var questionInvalid = isHTML(startQuestion);
								if(questionInvalid) {
									c3jQuery("#textInputQuestion").addClass("warning");
									c3jQuery('#textInputQuestion').attr("placeholder", getCustomText("textQuestionInvalid"));
									c3jQuery("#textInputQuestion").val("");
							return false;
								}
								if(!cookieDisabled) {
									localStorage.setItem("chatName", clientName);
									localStorage.setItem("chatEmail", email);
									localStorage.setItem("chatPhone", phone);
									localStorage.setItem("chatSubject", startQuestion);
								}
								//If EULA enabled force user to check before start
								if(getCustomUI("askEULA")){
									if(c3jQuery("#askEULA").is(':checked')){
										c3jQuery("#EULAAcceptance").removeClass("warning-label");
										return true;
									}else{
										c3jQuery("#EULAAcceptance").addClass("warning-label");
										c3jQuery(".warning-label").css("color", getCustomConfig("warningTextColor"));
										return false;
									}
								}
								return true;
							}else {
								c3jQuery("#textInputEmail").addClass("warning");
								c3jQuery('#textInputEmail').attr("placeholder", getCustomText("textMessageEmailInvalid"));
								c3jQuery('#textInputEmail').val("");
								return false;
							}
						}
					}
				}
			}

			// Register event handler for when the start button is clicked.
			c3jQuery('#textButtonStart').on('click', function() {
				CentionChat.canChat(function(o) {
					if(o.agentsAvailable > 0){
						agentResponded = false;
						c3jQuery('#CentionStartView > input').not(':input[type=button]').removeClass("warning");
						c3jQuery('#CentionStartView > textarea').removeClass("warning");
						c3jQuery("#textStatusConnecting").text(getCustomText("textStatusConnecting"));
						c3jQuery("#CentionChatList").children('.chatEnded').remove();
						c3jQuery(".agentConnectedTo").hide();
						c3jQuery("#CentionChatList").children('.message').remove();

						// Connect to the Cention chat server
						const inputPass = verifyStartInput();
						if(inputPass) {
							renderChatLoaderHeader();
							var externalData = "";
							if(c3jQuery('#textInputExtraField').length > 0) {
								const extraField = escapeHTML(c3jQuery('#textInputExtraField').val());
								if (isJsonStringObj(extraField)){
									externalData = extraField;
								} else {
									externalData = verifyTxtField(externalData);
									externalData = extraFieldParam.template;
									externalData = externalData.replace("{EXTRA_FIELD_VALUE}",extraField);
								}
							}
							CentionChat.connect({
								area: CentionChat.areaId,         // Unique identifier for the Cention area in which the chat should be started.
								// Determines among other things who can be assigned the chat.
								// This identifier is provided by Cention.
								name: verifyTxtField(clientName),       // The name of the client
								email: c3jQuery('#textInputEmail').val(),     // The client's email address
								phone: c3jQuery('#textInputPhone').val(),     // The client's phone number
								message: verifyTxtField(startQuestion),
								externalData: externalData, // The client's extra info, if provided
								clientDeviceInfo: {
									browserName: browserData.browser.name ? browserData.browser.name : '',
									browserVersion: browserData.browser.version ? browserData.browser.version : '',
									deviceVendor: browserData.device.vendor ? browserData.device.vendor : '',
									deviceModel: browserData.device.model ? browserData.device.model : '',
									deviceType: browserData.device.type ? browserData.device.type : '',
									osName: browserData.os.name ? browserData.os.name : '',
									osVersion: browserData.os.version ? browserData.os.version : '',
									ua: browserData.ua ? browserData.ua : '',
									isMobile: isMobile(browserData.ua)
								}
							});
							c3jQuery('#CentionStartView').hide();
							c3jQuery('#CentionChatStatus').show();
							c3jQuery('#CentionChatView').show();
							c3jQuery('#CentionChatFinishButtonWrapper').show();
							if(ccs.feature["chat.attachment"]){
								c3jQuery("#CentionChatSendFileButtonWrapper").show();
							}else{
								c3jQuery("#CentionChatSendFileButtonWrapper").hide();
							}
							c3jQuery('#CentionNewChatButtonWrapper').hide();
							CentionChatResetBodySize("connected");

							c3jQuery('#CentionChatQuestion').attr('contenteditable', true);
							activateChatUI();
						}
					}else{
						c3jQuery('#CentionStartView').hide();
						c3jQuery('#CentionChatView').hide();
						c3jQuery('#CentionChatChannelView').hide();
						c3jQuery('#CentionNoAgentsView').show();
					}
				}, CentionChat.areaId, true);
			});

			function escapeHTML(s) {
				const strippedText = c3jQuery("<div/>").html(s).text();
				return strippedText;
			}

			c3jQuery('#textButtonAway').on('click', function(e) {
				e.preventDefault();
				// Redirect to whatever it linked to that defined by customer
				if(getCustomUI("buttonAwayLink") !== "#" && getCustomUI("buttonAwayLink") != "") {
					window.open(getCustomUI("buttonAwayLink"), '_blank');
				}
			});

			if(getCustomUI("showMenuAsDefault")){
				c3jQuery("#CentionToggleMenuButton").append("<i class='icon-close'></i>");
				c3jQuery("#CentionToggleableChatMenu").show();
				c3jQuery("#CentionChatList").toggleClass("shrinked");
				c3jQuery("#CentionChatFooter").toggleClass("expanded");
				c3jQuery("#CentionFAQFooterContainer").toggleClass("expanded");
				c3jQuery("#CentionFAQConversationContainer").addClass("shrinked");
			}else{
				c3jQuery("#CentionToggleMenuButton").append("<i class='icon-add'></i>");
				c3jQuery("#CentionToggleableChatMenu").hide();
			}
			//Register event handler for when the expand button is clicked, this expands more options
			c3jQuery("#CentionToggleMenuButton").click(function(e){
				e.preventDefault();
				if(c3jQuery("i", this).hasClass("icon-add")) {
					c3jQuery("i", this).toggleClass("icon-close").removeClass("icon-add");
					c3jQuery("#CentionFAQConversationContainer").addClass("shrinked");
					c3jQuery("#CentionChatList").addClass("shrinked");
					c3jQuery("#CentionChatFooter").addClass("expanded");
				}
				else {
					c3jQuery("i", this).toggleClass("icon-add").removeClass("icon-close");
					c3jQuery("#CentionFAQConversationContainer").removeClass("shrinked");
					// c3jQuery("#CentionChatList").removeClass("expanded");
					c3jQuery("#CentionChatList").removeClass("shrinked");
					c3jQuery("#CentionChatFooter").removeClass("expanded");
				};
				c3jQuery("#CentionToggleableChatMenu").toggle();

				if(getCustomUI("position") == "bottomRight" || getCustomUI("position") == "bottomLeft" ){
					c3jQuery("#CentionChatHeader").css({
						'bottom' :c3jQuery('#CentionChatBody').innerHeight() + 'px',
					});
				}

				if(useFAQChatInChat){
					// c3jQuery("#CentionChatList").toggleClass("shrinked");
					c3jQuery("#CentionFAQFooterContainer").toggleClass("expanded");
				}
			});

			c3jQuery('#closeCookieWarning').on('click', function (e) {
				e.preventDefault();
				c3jQuery('#CentionCookieWarning').hide();
				c3jQuery('#showCookieWarning').show();
			})
			c3jQuery('#showCookieWarning').on('click', function (e) {
				e.preventDefault();
				c3jQuery('#showCookieWarning').hide();
				c3jQuery('#CentionCookieWarning').show();
			})

			const enableRatings = getCustomUI('chatRatingsEnabled');
			//Start of Satisfaction Meter Section
			if(enableRatings){
				const ratingUrl = baseURL + spacePrefix + "/socket/external.api/chatrating";

				c3jQuery('#Satisfaction').hide();
				c3jQuery('#SatisfactionFeedback').hide();
				c3jQuery('#FeedbackButton').hide();

				//keeping for test purpose
				// c3jQuery("#CentionChatQuestion").click(function(){
				// 	c3jQuery('#Satisfaction').slideDown();
				// });

				c3jQuery('#SatisfactionClose').click(function(e){
					e.preventDefault();
					triggerSatisfaction = false;
					c3jQuery('#Satisfaction').slideUp();
					c3jQuery('#SatisfactionFeedback').hide();
				});

				// Clicking thumbs/rating icon

				const thumbsUpSolid = "icon-thumbs-up-fill";
				const thumbsUpRegular = "icon-thumbs-up";
				const thumbsDownSolid = "icon-thumbs-down-fill";
				const thumbsDownRegular = "icon-thumbs-down";

				//animate text to give a feel/response to the client
				function animateSatisfactionText(){
					c3jQuery('#SatisfactionText').fadeOut("fast").text((getCustomText("textSatisfactionReceived"))).fadeIn();
				}

				function startLeaveComment() {
					if (ccs.feature['chat.allow-rating-text-comment']) {
						c3jQuery('#FeedbackButton').fadeIn();
					}
				}

				c3jQuery('#OngoingLikedChat').click(function(e){
					e.preventDefault();
					animateSatisfactionText();
					//thumnbs up to solid/active
					c3jQuery( "#OngoingLikedChat" ).removeClass(thumbsUpRegular).addClass(thumbsUpSolid);
					//thumbs down back to regular/inactive
					c3jQuery( "#OngoingUnlikedChat" ).removeClass(thumbsDownSolid).addClass(thumbsDownRegular);

					startLeaveComment();
					c3jQuery.post(ratingUrl, {"session": CentionChat.sessionId, "action": "likechat"}, function(data){
						console.log(data);
					});

				});

				c3jQuery('#OngoingUnlikedChat').click(function(e){
					e.preventDefault();
					animateSatisfactionText();
					//thumbs up back regular/inactive
					c3jQuery( "#OngoingLikedChat").removeClass(thumbsUpSolid).addClass(thumbsUpRegular);
					//thumbs down to solid/active
					c3jQuery( "#OngoingUnlikedChat").removeClass(thumbsDownRegular).addClass(thumbsDownSolid);

					startLeaveComment();
					c3jQuery.post(ratingUrl, {"session": CentionChat.sessionId, "action": "unlikechat"}, function(data){
						console.log(data);
					});

				});

				//Clicking Feedback/Comment

				c3jQuery('#FeedbackButton').click(function(e){
					e.preventDefault();
					c3jQuery('#SatisfactionFeedback').fadeIn();
					c3jQuery('#ongoingContent').val('');
				});

				if(c3jQuery('#ongoingContent').val() == ''){
					c3jQuery('#buttonOngoingSend').addClass("disabled");
				}

				c3jQuery('#ongoingContent').on("keyup",function(){
					if(c3jQuery('#ongoingContent').val().length > 0){
						c3jQuery('#buttonOngoingSend').removeClass("disabled");
					}else{
						c3jQuery('#buttonOngoingSend').addClass("disabled");
					}
				});

				//Feedback form buttons
				c3jQuery('#buttonOngoingSend').click(function(e){
					e.preventDefault();
					const message  = c3jQuery('#ongoingContent').val();

					var action = "";
					if(c3jQuery("#OngoingLikedChat").attr("class") === thumbsUpSolid)
						action = "likechat";
					else if(c3jQuery("#OngoingLikedChat").attr("class") === thumbsDownSolid)
						action = "unlikechat";

					c3jQuery.post(ratingUrl, {"session": CentionChat.sessionId, "action": action, "message": message}, function(data){
					});
					c3jQuery('#SatisfactionFeedback').fadeOut("fast");
					c3jQuery('#buttonOngoingSend').addClass("disabled");
					animateSatisfactionText();
				});

				c3jQuery('#buttonOngoingCancel').click(function(e){
					e.preventDefault();
					c3jQuery('#ongoingContent').val('');
					c3jQuery('#SatisfactionFeedback').fadeOut();
					c3jQuery('#buttonOngoingSend').addClass("disabled");
				});

				//End of Satisfaction Meter Sections
			}else{
				c3jQuery('#Satisfaction').hide();
			}

			//Function to submit chat conversation
			function submitChat(event) {
				var chatMsg = c3jQuery('#CentionChatQuestion').html();
				var fromClient = true, sender = "CLIENT";
				let msgIsHTML = isHTML(chatMsg);
				if(msgIsHTML) {
					/**
					 * We must still supporting html to support pasted formatted text and images, and dragged images
					 * so carefully stripping unnecessary html tags and attributes seems reasonable here
					 * **/
					if(HtmlSanitizer) {
						HtmlSanitizer.AllowedAttributes['id'] = true;
						HtmlSanitizer.AllowedAttributes['class'] = true;
						HtmlSanitizer.AllowedAttributes['data-lightbox'] = true;
						chatMsg = HtmlSanitizer.SanitizeHtml(chatMsg);
					}
				}

				var textLengthCheck = 0;
				var imageContainsDragImg = false;
				const msgHtmlString = c3jQuery.parseHTML('<div>'+chatMsg+'</div>');
				const msgHtmlNode = c3jQuery(msgHtmlString);
				if(msgHtmlNode.find('#draggedImgPreviewContainer').length > 0){
					imageContainsDragImg = true;
				}
				if(imageContainsDragImg) {
					const jqhtml = c3jQuery.parseHTML('<div>'+chatMsg+'</div>');
					var html = c3jQuery(jqhtml);
					_tempImageAttachments.forEach(function(tempImages){
							html.find('#imgPreviewLink-'+tempImages.id).attr('href', CentionChat.baseURL + spacePrefix + escape(tempImages.download) + '?t='+CentionChat.sessionSecret);
							html.find('img#'+tempImages.id).attr('src', CentionChat.baseURL + spacePrefix + escape(tempImages.download) + '?t='+CentionChat.sessionSecret);
							html.find('img#'+tempImages.id).css('max-width',"200px");
							html.find('img#'+tempImages.id).css('max-height',"200px");
					});
					chatMsg = html[0].innerHTML;
					sending = CentionChat.message(chatMsg);
					addNewMessage({
						unsent: true,
						text: sending.message,
						fromClient: fromClient,
						sender: sender,
						id: "unsent-" + sending.id
					}, c3jQuery('#textInputName').val());
					c3jQuery('#CentionChatQuestion').html("");
					event.preventDefault();
					previewTimeout = setTimeout(sendPreview,400);
				} else {
					textLengthCheck = c3jQuery('#CentionChatQuestion').text().trim().length;
					if(chatMsg != "" && textLengthCheck > 0) {
						sending = CentionChat.message(chatMsg);
						addNewMessage({
							unsent: true,
							text: sending.message,
							fromClient: fromClient,
							sender: sender,
							id: "unsent-" + sending.id
						}, c3jQuery('#textInputName').val());
						c3jQuery('#CentionChatQuestion').html("");
						event.preventDefault();
						previewTimeout = setTimeout(sendPreview,400);
					}else{
						event.preventDefault();
					}
				}
			}

			// Register event handler for when the enter key is pressed
			// within the <textarea> where the client can input a new message.
			// When the enter key is pressed then what the client has entered
			// in the text box will be sent as a new message.
			// To make a line break hold down the shift key when pressing enter.

			c3jQuery('#CentionChatQuestion').on('keydown', function(e) {
				previewCount++;
				if (previewCount > 5) {
					sendPreview();
				}
				if (e.keyCode == 13 && e.shiftKey == false) {
					e.preventDefault();
					submitChat(e);
				}
				clearTimeout(previewTimeout);
			});

			// submit new message by button click
			c3jQuery("#SubmitChat").click(function(e){
				e.preventDefault();
				submitChat(e);
			});


			function getTimeZoneOffset(){
				return new Date().getTimezoneOffset() * -60;
			}
			// Register event handler for when the save as copy button is clicked.
			// User can save and download the chat history by this event.
			const user = c3jQuery('#textInputName').val();
			c3jQuery('#CentionChatSaveButton').on('click', function(e) {
				e.preventDefault();
				var url = CentionChat.baseURL + spacePrefix + "/socket/external.api/savehistory?area="+ CentionChat.areaId +"&secret=" + CentionChat.sessionSecret + "&timezoneoffset=" + getTimeZoneOffset() + "&host=" + CentionChat.baseURL;
				if(typeof params.languageCode !== "undefined") {
					url += "&lang="+params.languageCode;
				}
				window.open(url, '_blank');
			});

			// Register event handler for when the print button is clicked.
			// User can print the chat history by clicking print button. The popup window will appear with save botton to print the chat history.
			c3jQuery('#CentionChatPrintButton').on('click', function(e) {
				e.preventDefault();
				var url = CentionChat.baseURL + spacePrefix + "/socket/external.api/printhistory?area="+ CentionChat.areaId +"&secret=" + CentionChat.sessionSecret + "&timezoneoffset=" + getTimeZoneOffset() + "&host=" + CentionChat.baseURL;
				if(typeof params.languageCode !==  "undefined") {
					url += "&lang="+params.languageCode;
				}
				var popup = window.open(url,
				'window',
				'width=640,height=480,scrollbars=yes,status=no');
				if( window.focus ) {
					popup.focus();
				}
			});

			// Register event handler for when the chat finish button is clicked.
			// FIXME: When socket upgrading, no action taken (backend) on finish chat.
			c3jQuery('#CentionChatFinishButton').on('click', function(e) {
				e.preventDefault();
				// CentionChat.close() function close chat connection between user and agent.
				CentionChat.close();
				deactivateChatUI();
				if(getCustomUI('chatRatingsEnabled')){
					c3jQuery('#Satisfaction').slideDown();
				}
			});

			/** VIDEO CALL SEGMENT **/
			/*-=-=-=-=-=-=-=-=--=-=-*/

			//Open Video Call UI
			//Request to open camera right away (not to waste another click)
			c3jQuery('#CentionChatVideoButton').click(function(e){
				if(coBrowseMode) {
					//just bring back the frame
					c3jQuery("#CentionChatVideoFrame").show();
					c3jQuery("#CentionChatVideoButtonStop").show();
					c3jQuery("#CentionChatVideoButton").hide();
					return;
				}
				c3jQuery("#CentionChatVideoFrame").show();
				e.preventDefault();
				const user = c3jQuery('#textInputName').val();
				// Start video
				CentionChat.registerVideoCall('video init', user, function() {
					c3jQuery("#CentionChatVideoButton").hide();
					c3jQuery("#CentionChatVideoButtonStop").show();

					c3jQuery("#disableVidButton").hide();
					c3jQuery("#enableVidButton").hide();
					c3jQuery("#disableAudioButton").hide();
					c3jQuery("#enableAudioButton").hide();
					c3jQuery("#hangupButton").hide();
					c3jQuery("#callButton").hide();
					if(ccs.feature["chat.allow-video-call"] && getCustomUI("enableVideoChat")) {
						c3jQuery("#startButton").show();
					}
				});
			});

			//Stopping the video UI
			c3jQuery('#CentionChatVideoButtonStop').click(function(e) {
				e.preventDefault();
				if(coBrowseMode) {
					//hide the frame again
					c3jQuery("#CentionChatVideoFrame").hide();
					c3jQuery("#CentionChatVideoButton").show();
					c3jQuery("#CentionChatVideoButtonStop").hide();
					return;
				}
				c3jQuery("#CentionChatVideoButton").show();
				c3jQuery("#CentionChatVideoButtonStop").hide();
				c3jQuery("#CentionChatVideoFrame").hide();
				disableWebCam();
				if(onVideoCall) {
					CentionChat.registerVideoCall('hangup', user, function() {
					});
				}
				if(clientScreenShareMode) {
					CentionChat.registerVideoCall('stop-screen-sharing', user, function() {
					});
				}
				if(agentScreenShareMode) {
					CentionChat.registerVideoCall('stop-agent-screen-sharing', user, function() {
					});
				}
			});

			c3jQuery('#CentionCloseVid').click(function(e) {
				e.preventDefault();
				if(coBrowseMode) {
					const coBrowseAlertMsg = CentionChat.I("Do you want to close current co-browsing session ?");
					const clientCoBrowseExit = confirm(coBrowseAlertMsg);
					if (clientCoBrowseExit == true) {
						stopVideoWhenClose();
					} else {
						return;
					}
				} else {
					stopVideoWhenClose();
				}
			});

			function stopVideoWhenClose() {
				c3jQuery("#CentionChatVideoButton").show();
				c3jQuery("#CentionChatVideoButtonStop").hide();
				c3jQuery("#CentionChatVideoFrame").hide();
				disableWebCam();
				if(onVideoCall) {
					CentionChat.registerVideoCall('hangup', user, function() {
					});
				}
				if(clientScreenShareMode) {
					CentionChat.registerVideoCall('stop-screen-sharing', user, function() {
					});
				}
				if(agentScreenShareMode) {
					CentionChat.registerVideoCall('stop-agent-screen-sharing', user, function() {
					});
				}
			}

			c3jQuery("#CloseAgentVid").click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('stop-agent-screen-sharing', user, function() {
					//c3jQuery("#startButton").hide();
				});
			});

			//start share screen for co browsing, with agent
			c3jQuery("[id*='coBrowsing']").click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('start-screen-capture', user, function() {
					c3jQuery("[id*='coBrowsing']").hide();
					c3jQuery("[id*='stopCoBrowsing']").show();
					c3jQuery("[id*='screenShare']").hide();
					c3jQuery("#coBrowsingStatusTxt").show();
					c3jQuery("#coBrowsingStatusTxt").text("Connecting...");
					c3jQuery("#coBrowsing").removeClass( "cobrowse-need-click" );
					c3jQuery("#toggleRemoteControl").show();
				});
			});

			c3jQuery("[id*='stopCoBrowsing']").click(function(e) {
				console.log("dbg: stop co browse");
				c3jQuery("#coBrowsingStatusTxt").text("Stopped");
				e.preventDefault();
				CentionChat.registerVideoCall('stop-screen-capture', user, function() {
					c3jQuery("[id*='coBrowsing']").show();
					c3jQuery("[id*='stopCoBrowsing']").hide();
					c3jQuery("#toggleRemoteControl").hide();
				});
			});

			c3jQuery("#toggleRemoteControl").click(function(e) {
				c3jQuery("#toggleRemoteControlListOpt").toggle();
			});

			//Start local camera
			c3jQuery('#startButton').click(function(e) {
				e.preventDefault();
				const user = c3jQuery('#textInputName').val();
				// Start video
				//CentionChat.registerVideoCall('video init', user, function() {
				CentionChat.registerVideoCall('start camera', user, function() {
					c3jQuery("#CentionChatVideoFrameWrapper").show();
					c3jQuery("#CentionChatVideoLocalWrapper").show();
					c3jQuery("#callButton").show();
					c3jQuery("#maximizeVideo").show();
					c3jQuery("#startButton").hide();

					c3jQuery("#CentionChatVideoLocalWrapper").show();
					c3jQuery("#CentionChatVideoFrameRemote").show();
					c3jQuery("#AvatarOverlayLocal").hide();
				});
			})

			//Starting to call
			c3jQuery('#callButton').click(function(e) {
				e.preventDefault();
				const user = c3jQuery('#textInputName').val();
				CentionChat.registerVideoCall('video offer', user, function() {
					c3jQuery("#startButton").hide();
				});
				vidCallInProgress = true;
				c3jQuery('#callButton').addClass("disabled");
				c3jQuery("#hangupButton").show();
				c3jQuery("#disableVidButton").show();
				c3jQuery("#disableAudioButton").show();
			})

			c3jQuery('#screenShare').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('start-screen-sharing', user, function() {
					c3jQuery("#screenShare").hide();
					c3jQuery("#stopScreenShare").show();
					//c3jQuery('#screenShare').addClass("disabled");
					if(ccs.feature['chat.allow-co-browsing']) {
						c3jQuery("#coBrowsing").show();
					}
				});
			})

			c3jQuery('#stopScreenShare').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('stop-screen-sharing', user, function() {
					c3jQuery("#stopScreenShare").hide();
					c3jQuery("#screenShare").show();
					if(ccs.feature['chat.allow-co-browsing']) {
						c3jQuery("#coBrowsing").hide();
					}
				});

			})

			//Stop the call
			c3jQuery('#hangupButton').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('hangup', user, function() {
					c3jQuery("#startButton").show();
					c3jQuery("#hangupButton").hide();
					c3jQuery("#disableVidButton").hide();
					c3jQuery("#enableVidButton").hide();
					c3jQuery("#disableAudioButton").hide();
					c3jQuery("#enableAudioButton").hide();
				});
				vidCallInProgress = false;
				if(fullScreenMode) {
					toggleFullScreen();
				}
				c3jQuery('#callButton').removeClass("disabled");
				c3jQuery('#callButton').hide();
			})

			//Disable video
			//make this only appear when session ongoing
			c3jQuery('#disableVidButton').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('stopWebCam', user, function() {
					c3jQuery("#hangupButton").show();
					c3jQuery("#enableVidButton").show();
					c3jQuery("#enableVidButton").show();
					c3jQuery("#disableVidButton").hide();
					c3jQuery("#disableAudioButton").show();
					c3jQuery("#maximizeVideo").show();
				});
			})

			c3jQuery('#enableVidButton').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('startWebCam', user, function() {
					c3jQuery("#hangupButton").show();
					c3jQuery("#disableVidButton").show();
					c3jQuery("#enableVidButton").hide();
				});
			})

			//Disable audio
			//make this only appear when session ongoing
			c3jQuery('#disableAudioButton').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('stopAudio', user, function() {
					c3jQuery("#hangupButton").show();
					c3jQuery("#disableAudioButton").hide();
					c3jQuery("#enableAudioButton").show();
				});
			})

			//Re-enable audio
			//make this only appear when session ongoing
			c3jQuery('#enableAudioButton').click(function(e) {
				e.preventDefault();
				CentionChat.registerVideoCall('startAudio', user, function() {
					c3jQuery("#hangupButton").show();
					c3jQuery("#disableAudioButton").show();
					c3jQuery('#enableAudioButton').hide();
				});
			})

			//Client receive answer from Agent Video request
			CentionChat.registerAction('video-answer', function ( data ) {
				console.log("Client received video answer signal", data);
			});

			function activateChatUI() {
				//// Enable the chat editor and buttons for inputing new message
				c3jQuery('#CentionChatQuestion').attr('contenteditable', true);
				c3jQuery('#CentionChatQuestion').removeClass('disabled');
				c3jQuery('#SubmitChat').removeClass('disabled');
			}

			function deactivateChatUI() {
				// Scroll the message list to the bottom
				c3jQuery('#CentionChatList').scrollTop(c3jQuery('#CentionChatList').prop('scrollHeight'));

				// Disable the chat editor for inputing new message
				c3jQuery('#CentionChatQuestion').attr('contenteditable', false);
				c3jQuery('#CentionChatQuestion').addClass('disabled');
				c3jQuery('#SubmitChat').addClass('disabled');

				//Remove agent name from header
				c3jQuery('#CentionChatHeader > .text').html(_chatTitle);

				//hide finish button, show restart chat button
				c3jQuery("#CentionNewChatButtonWrapper").show();
				c3jQuery("#CentionChatFinishButtonWrapper").hide();
				c3jQuery("[id*='CentionChatSendFileButtonWrapper']").hide();
				c3jQuery("#CentionChatVideoCallWrapper").hide();

				// hide connected message
				c3jQuery('#CentionChatStatus').hide();
				c3jQuery('#CentionChatWelcome').hide();
				c3jQuery("#CentionChatConnected").hide();
				ccs.resumable = false;
				clientAvatar = "";

				//video chat stuff
				c3jQuery("#CentionChatVideoFrame").hide();
			}

			//Register event handler for when the chat send file button is clicked.
			c3jQuery('#CentionChatSendFileInput').on('change',function( event ) {
				var fileInput = document.getElementById('CentionChatSendFileInput');
				if(fileInput.files.length > 0){
					for (var i = 0; i < fileInput.files.length; i++) {
						/*Send callback to parent for every file upload*/
						CentionChat.attachFile(fileInput.files[i], function(um) {
							addNewMessage(um, c3jQuery('#textInputName').val());
						});
					}
				}
			});

			c3jQuery('#CentionChatQuestion').on('drop',function( e ) {
				e.preventDefault();
				var file = e.originalEvent.dataTransfer.files[0];
				uploadChatTempImage(file);
			});

			c3jQuery('#CentionChatQuestion').on('dragenter',function( e ) {
				e.preventDefault();
			});

			c3jQuery('#CentionChatQuestion').on('dragover',function( e ) {
				e.preventDefault();
			});

			c3jQuery('#CentionChatQuestion').on('paste',function( e ) {
				handlepaste(e);
			});
			c3jQuery('#CentionChatVideoFrameLocal').on('click', function() {
				if(fullScreenMode) {
					toggleLocalFS();
				}
			});
			c3jQuery('#CentionChatVideoFrameRemote').on('click', function() {
				if(fullScreenMode) {
					toggleRemoteFS();
				}
			});
			c3jQuery('#client-widget-display-view').on('click', function() {
				if(fullScreenMode) {
					toggleLocalShareFS();
				}
			});
			c3jQuery('#agent-widget-display-view').on('click', function() {
				if(fullScreenMode) {
					toggleRemoteShareFS();
				}
			});

			//NOTES:
			//Firefox pasted image as base64 data.
			//Safari pasted image as blob url for example, blob://localhost.com/<identifier>
			var editableDiv = document.getElementById("CentionChatQuestion");
			const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
			const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
			var isUsingSafari = false;
			var isUsingChrome = false;
			if (isChrome) isUsingChrome = true;
			if (isSafari) isUsingSafari = true;

			function handlepaste (e) {
				var types, pastedData, savedContent;
				// Browsers that support the 'text/html' type in the Clipboard API
				if (e && e.clipboardData && e.clipboardData.types && e.clipboardData.getData) {
					types = e.clipboardData.types;
					if (((types instanceof DOMStringList) && types.contains("text/html")) ||
					(types.indexOf && types.indexOf('text/html') !== -1)) {
						pastedData =  e.clipboardData.getData('text/html');
						processPaste(editableDiv, pastedData);
							e.stopPropagation();
							e.preventDefault();
						return false;
					}
				}else {
					if(isUsingChrome) {
						if(e.clipboardData) {
							const items = e.clipboardData.items;
							const blob = items[0].getAsFile();
							if(blob) {
								const reader = new FileReader();
								reader.onload = function(event){
									pastedData = "<img src="+event.target.result+" />";
									processPaste(editableDiv, pastedData, event.target.result);
								};
								reader.readAsDataURL(blob);
							}
						}
					}
				}
				// Everything else: Move existing element contents to a DocumentFragment for safekeeping
				savedContent = document.createDocumentFragment();
				while(editableDiv.childNodes.length > 0) {
					savedContent.appendChild(editableDiv.childNodes[0]);
				}
				// Then wait for browser to paste content into it and cleanup
				waitForPastedData(editableDiv, savedContent, "");
				return true;
			}
			function waitForPastedData (elem, savedContent) {
				// If data has been processes by browser, process it
				if (elem.childNodes && elem.childNodes.length > 0) {
					// Retrieve pasted content via innerHTML
					// (Alternatively loop through elem.childNodes or elem.getElementsByTagName here)
					var pastedData = elem.innerHTML;
					// Restore saved content
					elem.innerHTML = "";
					elem.appendChild(savedContent);
					// Call callback
					processPaste(elem, pastedData, "");
				}
				// Else wait 20ms and try again
				else {
					setTimeout(function () {
						waitForPastedData(elem, savedContent, "")
					}, 20);
				}
			}
			function dataURItoBlob(dataURI) {
				// convert base64 to raw binary data held in a string
				// doesn't handle URLEncoded DataURIs
				const byteString = atob(dataURI.split(',')[1]);
				// separate out the mime component
				const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
				// write the bytes of the string to an ArrayBuffer
				const ab = new ArrayBuffer(byteString.length);
				// create a view into the buffer
				const ia = new Uint8Array(ab);
				// set the bytes of the buffer to the correct values
				for (var i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}
				// write the ArrayBuffer to a blob, and you're done
				var blob = new Blob([ab], {type: mimeString});
				return blob;
			}
			function blobToFile(theBlob, fileName){
				theBlob.lastModifiedDate = new Date();
				theBlob.name = fileName;
				return theBlob;
			}
			function isHTML(str) {
				const a = document.createElement('div');
				a.innerHTML = str;
				for (var c = a.childNodes, i = c.length; i--; ) {
					if (c[i].nodeType == 1) return true;
				}
				return false;
			}
			var walk_the_DOM = function walk(node, func) {
				func(node);
				node = node.firstChild;
				while (node) {
					walk(node, func);
					node = node.nextSibling;
				}
			};
			function sanitizeHTML(htmlString){
				var doc = document.createElement('div');
				doc.innerHTML = htmlString;
				walk_the_DOM(doc, function(el) {
					if(el.removeAttribute) {
						el.removeAttribute('style');
						el.removeAttribute('class');
					}
				});
				return doc.innerHTML;
			}

			function hasOnlyImg(div) {
				if (div.children.length != 1)
					return false;
				return (div.children[0].tagName == "IMG" || div.children[0].tagName == "img" );
			}

			function convertImgToCanvasAndUploadTemp(img) {
				if (!img.src) return;
				var canvas = document.createElement('canvas');
				var ctx = canvas.getContext('2d');
				canvas.width = img.width;
				canvas.height = img.height;
				ctx.drawImage(img, 0, 0);
				canvas.toBlob(function(blob){
					if(blob != null) {
						const myFile = blobToFile(blob, "pasted-image.png");
						uploadChatTempImage(myFile);
					}
				});
			}

			function processPaste (elem, pastedData, base64Data) {
				elem.focus();
				var tmp = document.createElement('div');
				tmp.innerHTML = pastedData;
				if(pastedData && isUsingSafari) {
					var pastedImgWrapper= document.createElement('div');
					pastedImgWrapper.innerHTML= pastedData;
					if(hasOnlyImg(pastedImgWrapper)){
						var alt = "";
						alt = pastedImgWrapper.children[0].getAttribute("alt");
						var url = pastedImgWrapper.children[0].getAttribute("src");
						if(url.startsWith("blob:")){
							var img = tmp.getElementsByTagName('img')[0];
							if(typeof img !== "undefined") {
								convertImgToCanvasAndUploadTemp(img);
							}
						}else{
							pastedData = "<div id=\"draggedImgPreviewContainer\" class=\"imgDraggedPreviewContainer\">" +
								"<a id=\"imgPreviewLink-" + alt + "\" " +
									"class=\"imgPreviewLink\" " +
									"data-lightbox=\"imgPreviewLink" + alt + "\" "+
									"href=\""+ url +"\" >" +
								"<img src=\"" + url + "\" " +
								"id=" + alt + " " +
								" alt='' style=\"max-width:200px;max-height:200px\" />" +
								"</a>" +
								"</div>";
								c3jQuery('#CentionChatQuestion').append(pastedData);
						}
					}else{
						const img = tmp.getElementsByTagName('img')[0];
						if(typeof img !== "undefined") {
							convertImgToCanvasAndUploadTemp(img);
						}else {
							if(isHTML(pastedData)){
								pastedData = sanitizeHTML(pastedData);
							}
							c3jQuery('#CentionChatQuestion').append(pastedData);
						}
					}

				}else if(pastedData && !isUsingChrome){
					//firefox and ie
					//firefox use base64, draw it as img and upload
					var img = tmp.getElementsByTagName('img')[0];
					if(typeof img !== "undefined") {
						if (!img.src) return;
						base64Data = img.src;
						var c = "<canvas id=\"canvas1\" width=\"0\" height=\"0\" ></canvas>";
						editableDiv.innerHTML += c;
						var canvas = document.getElementById("canvas1");
						var ctx = canvas.getContext("2d");
						var image = new Image();
						image.onload = function() {
							ctx.drawImage(image, 0, 0);
						};
						image.src = base64Data;
						var file = dataURItoBlob(base64Data);
						uploadChatTempImage(file);
					}else {
						if(isHTML(pastedData)){
							pastedData = sanitizeHTML(pastedData);
						}
						c3jQuery('#CentionChatQuestion').append(pastedData);
					}
				}else if(pastedData && isUsingChrome){
					if(base64Data) {
						var c = "<canvas id=\"canvas1\" width=\"0\" height=\"0\" ></canvas>";
						editableDiv.innerHTML += c;
						var canvas = document.getElementById("canvas1");
						var ctx = canvas.getContext("2d");
						var image = new Image();
						image.onload = function() {
							ctx.drawImage(image, 0, 0);
						};
						image.src = base64Data;
						var file = dataURItoBlob(base64Data);
						uploadChatTempImage(file);
					}else {
						if(isHTML(pastedData)){
							pastedData = sanitizeHTML(pastedData);

							//Handling paste image from website (Right click Image > Copy image)
							var pastedImgWrapper= document.createElement('div');
							pastedImgWrapper.innerHTML= pastedData;

							//This will convert pasted image that pasted as <img src>
							//into previewable image to agent
							//without having to upload this img.
							//this make it different from the dropped(dragged) image
							if(hasOnlyImg(pastedImgWrapper)){
								var alt = "";
								alt = pastedImgWrapper.children[0].getAttribute("alt");
								var url = pastedImgWrapper.children[0].getAttribute("src");
								pastedData = "<div id=\"draggedImgPreviewContainer\" class=\"imgDraggedPreviewContainer\">" +
								"<a id=\"imgPreviewLink-" + alt + "\" " +
									"class=\"imgPreviewLink\" " +
									"data-lightbox=\"imgPreviewLink" + alt + "\" "+
									"href=\""+ url +"\" >" +
								"<img src=\"" + url + "\" " +
								"id=" + alt + " " +
								" alt='' style=\"max-width:200px;max-height:200px\" />" +
								"</a>" +
								"</div>";
							}
							var file = dataURItoBlob(url);
							uploadChatTempImage(file);
						}
					}
				}else {
					if(isHTML(pastedData)){
						pastedData = sanitizeHTML(pastedData);
					}
					c3jQuery('#CentionChatQuestion').append(pastedData);
				}
			}
			var uploadLoader =  c3jQuery('#CentionUploadloader');
			function uploadChatTempImage(file) {
				if(uploadLoader) {
					uploadLoader.show();
				}
				var xhr =[];
				var formData = new FormData();
				var boundary = Math.floor(Math.random() * 6)+ Math.floor(''+new Date() / 1000);
				formData.append("uploadfile", file);
				formData.append( 'random', parseFloat(boundary));
				formData.append( 'session', CentionChat.sessionId);
				formData.append( 'area', CentionChat.areaId);
				formData.append( 'sessionSecret', CentionChat.sessionSecret);
				xhr = new XMLHttpRequest();
				xhr.open("POST",baseURL + spacePrefix +"/Cention/web/chat/client/uploadTempAttachment");
				xhr.onreadystatechange = function(ev){
					if (ev.target.readyState == 4 && ev.target.status == 200) {
						var ro = JSON.parse(ev.target.responseText);
						_tempImageAttachments.push(ro);
						let html;
						html = "<div id=\"draggedImgPreviewContainer\" class=\"imgDraggedPreviewContainer\">" +
							"<a id=\"imgPreviewLink-" + ro.id + "\" " +
								"class=\"imgPreviewLink\" " +
								"data-lightbox=\"imgPreviewLink" + ro.id + "\" "+
								"href=\""+ baseURL + spacePrefix + escape(ro.download) + '?t='+CentionChat.sessionSecret +"\" >" +
							"<img src=\"" + baseURL + spacePrefix + escape(ro.download) + '?t='+CentionChat.sessionSecret + "\" " +
							"id=" + ro.id + " " +
							" alt='' style=\"max-width:200px;max-height:200px\" />" +
							"</a>" +
							"</div>";
						c3jQuery('#CentionChatQuestion').append(html);
						if(uploadLoader) {
							uploadLoader.hide();
						}
					}
				};
				xhr.send(formData);
			}

			//Orientationn Potrait and Landscape
			c3jQuery(window).on( "orientationchange", function(event) {
				//console.log("Orientation:",event);
				//console.log("Orientation:",event.orientation); (jqueryMobile.api)
				if(c3jQuery('#CentionChatBody').is(':visible')){

					//Note: orientation change needs a delay to pick up on the new heights/widths dimensions for certain browsers
					//Therefore, using 'resize' event to include the appropriate positions after an orientationchange takes place

					if(getPosition == "bottomRight" || getPosition == "bottomLeft"){
						c3jQuery(window).one('resize', function() {
							c3jQuery("#CentionChatHeader").css({
								'bottom' : bottomPosition ? bottomPosition + c3jQuery('#CentionChatBody').innerHeight() + 'px' : c3jQuery('#CentionChatBody').innerHeight() + 'px'
							});
						});
					}
					if(getPosition == "centerRight"){
						c3jQuery(window).one('resize', function() {
							c3jQuery("#CentionChatHeader").css({
								'right' : c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px'
							});

						});
					}
					if(getPosition == "centerLeft"){
						c3jQuery(window).one('resize', function() {
							c3jQuery("#CentionChatHeader").css({
								'left' : c3jQuery('#CentionChatBody').outerWidth() - 73 + 'px'
							});
						});

					}
				}
			});

			// Setup contact form
			c3jQuery('#contactInputName').attr("placeholder", getCustomText("textInputName")+" *");
			c3jQuery('#contactInputEmail').attr("placeholder", getCustomText("textInputEmail")+" *");
			c3jQuery('#contactInputQuestion').attr("placeholder", getCustomText("textInputQuestion")+" *");
			c3jQuery('#CentionMsgSend').prop('disabled', true);

			c3jQuery('#contactButtonSubmit').on('click', function() {
				const formName = c3jQuery('#contactInputName').val();
				const formEmail = c3jQuery('#contactInputEmail').val();
				const formPhone = c3jQuery('#contactInputPhone').val();
				const formQuestion = c3jQuery('#contactInputQuestion').val();
				const emailIsValid = isValidEmail(formEmail);
				var phoneIsValid = false;
				if(formPhone == "" || (formPhone != "" && isValidPhone(formPhone))) {
					phoneIsValid = true;
				}
				const subj = formQuestion.substring(0, 30);
				if (formName && formQuestion && emailIsValid && phoneIsValid) {
					const createErrandURL = baseURL + spacePrefix + "/socket/external.api/createerrand";
					c3jQuery.post( createErrandURL, {"area": CentionChat.areaId, "name": formName, "from": formEmail, "phone": formPhone, "subject": subj, "body": formQuestion})
					.done(function( data ) {
						c3jQuery('#contactInputQuestion').val("");
						c3jQuery('#contactFormInfo').html(Linker.linkifyUrls(getCustomText("textContactFormSentNotice")));
						c3jQuery('#CentionNoAgentsView').addClass(" form-sent");
					})
					.fail(function( err ) {
						console.log("Error", err);
					});
				} else {
					if ((formName == "") || (formEmail == "") || (formQuestion == "")) {
						if(formName == ""){
							c3jQuery("#contactInputName").addClass("warning");
						}
						if(formEmail == ""){
							c3jQuery("#contactInputEmail").addClass("warning");
						}
						if(formQuestion == ""){
							c3jQuery("#contactInputQuestion").addClass("warning");
						}
					} else if (!emailIsValid){
						c3jQuery('#contactInputEmail').val("");
					} else if (!phoneIsValid) {
						c3jQuery("#contactInputPhone").addClass("warning");
						c3jQuery("#contactInputPhone").val("");
					}
					return false;
				}
			});

		} //end load mini chat
		//#TODO to support external chat
	});
};

// Linker linkifies text that looks like URL in text given by editor.
var Linker = {
	// Regex for capturing urls in ckeditor html.
	urlRegex: /(>|^)(https?:\/\/[^ \<]+)/i,

	linkifyUrls: function (html) {
		var res
		, i
		;

		res = Array.prototype.slice.call(html.split(/(\&nbsp;|\s)+/g))

		for(i=0; i<res.length; i++) {
			res[i] = res[i].replace(this.urlRegex, function(match, p1, p2) {
				var url = p2
					, lastChunk = ""
				;
				while (url && url.length > 1 && (
					url[url.length-1] == '.' ||
						url[url.length-1] == ',' ||
						url[url.length-1] == '"' ||
						url[url.length-1] == ']' ||
						url[url.length-1] == ')'
				)) {
					lastChunk += url[url.length-1];
					url = url.substring(0, url.length-1);
				}
				var removeExtraTagRegex = /(<([^>]+)>)/ig;
				var linkSrc = url.replace(/"/g, '\\x22')
				sanitizeLinkSrc = linkSrc.replace(removeExtraTagRegex, "");

				//TODO: Preview url using OG(Open graph) Meta data if possible

				return [ p1
					, '<a href="' + sanitizeLinkSrc + '" target="_blank">' + url + '</a>'
					, lastChunk
				].join('');
			});
		}
		return res.join(' ');
	}
};
