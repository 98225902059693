'use strict';

const isFF = navigator.userAgent.indexOf("Firefox") != -1;
var turndName = "", turndToken = "", turndExpire = 0;
var RTCCONFIG_TEMP = { iceServers : [{
		"urls" :
			navigator.mozGetUserMedia ? "stun:stun.services.mozilla.com" :
			"stun:stun.l.google.com:19302"
		}
	,	{urls: "stun:stun1.l.google.com:19302"}
	,	{urls: "stun:stun2.l.google.com:19302"}
	,	{urls: "stun:stun3.l.google.com:19302"}
	,	{urls: 'stun:stun4.l.google.com:19302' }
	]
	, iceTransportPolicy: "all"
	, iceCandidatePoolSize: 0
	};


//Firefox only supports 1 iceServer
if (isFF) {
	RTCCONFIG_TEMP = { iceServers : [ {urls: "stun:stun.l.google.com:19302"} ]
				, iceTransportPolicy: "all", iceCandidatePoolSize: 0
				};
}

function setRTCConf() {
	let turnHOSTURL = "";
	if(CentionChatStatus.feature["turn.host-url"]) {
		turnHOSTURL = "turn:"+CentionChatStatus.feature["turn.host-url"];
	}
	var RTCCONFIG = {
		"iceServers":
			[{
				"urls":[turnHOSTURL],"username":turndName,"credential":turndToken
			}],
			"iceTransportPolicy":"all","iceCandidatePoolSize":"0"
	}

	if(turndName !== "" || turndToken !== "") {
		console.log("RTC config used => ", RTCCONFIG);
		return RTCCONFIG;
	}

	return RTCCONFIG_TEMP;
}

const mediaStreamConstraints = {
	video: true,
	audio: true
};

const offerOptions = {
	offerToReceiveAudio: 1,
	offerToReceiveVideo: 1,
	iceRestart: true
};

//we can control here the limit on what should be opt to share
//for example, video { mandatory: { chromeMediaSource: 'screen' }} is opt
//only tabs within chrome
const displayMediaOptions = {
	video: {
		cursor: "always"
	},
	audio: false
};

const displayMediaOptionsForCoBrowsing = {
	video: {
		cursor: "always"
	},
	audio: false,
	prefercurrenttab: true
};

const localVideo = document.getElementById('CentionChatVideoFrameLocal');
const remoteVideo = document.getElementById('CentionChatVideoFrameRemote');

var sessionId, websocket, isAgentInitiated = false, clientRequestCall = false, onVideoCall = false, agentScreenShareMode = false, clientScreenShareMode = false, agentDisplayOffer = false, clientDisplayOffer = false;

var pc2, pcDisplay, sendChannel, receiveChannel, localStream, remoteStream, displayMediaStream, remoteDisplayStream;
var fullScreenMode = blurActivated = bgActivated = changingBg = agentPresetBlur = false, agentPresetBg = "";
const FS_DEFAULT = 0, FS_REMOTE = 1, FS_LOCAL = 2, FS_DISPLAY = 3, FS_DISPLAY_LOCAL = 4;
var currentFS = FS_REMOTE;
var coBrowseMode = false, coBrowseWhileSharing = false;


var sdpConstraints = {'mandatory': {'OfferToReceiveAudio':true, 'OfferToReceiveVideo':true }};
var coBrowseConstraints = {'mandatory': {'OfferToReceiveAudio':true, 'OfferToReceiveVideo':false }};
var collectedICEs = [], collectedDisplayICEs = [];
var negoNeeded = false;
var activeElem = "";
var camDisabledLocal = false;

let maximumMessageSize;

function getName() {
	return "Client PC";
}

//Start camera
// Handles start button action: creates local MediaStream.
async function startAction() {
	if(navigator.mediaDevices) {
		navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
		.then(gotLocalMediaStream).catch(handleLocalMediaStreamError);
		console.log('Requesting local stream.');
	} else {
		alert("Error: 'GetUserMedia' is not supported.")
		console.log("Using insecured connection, must use valid HTTPS");
	}
}

// Sets the MediaStream as the video element src.
function gotLocalMediaStream(mediaStream) {
	const localVideo = document.getElementById('CentionChatVideoFrameLocal');
	localVideo.srcObject = mediaStream;
	localStream = mediaStream;
	console.log('Received local stream.');
	c3jQuery("#startButton").show();
}

// Handles error by logging a message to the console.
function handleLocalMediaStreamError(error) {
	c3jQuery("#startButton").show();
	c3jQuery("#callButton").hide();
	c3jQuery("#maximizeVideo").hide();
	console.log(`navigator.getUserMedia error: ${error.toString()}.`);
}

function updateTurnDInfos(data) {
	turndName = data.turnDAuthName;
	turndToken = data.turnDAuthToken;
	turndExpire = data.turnDExpireTime;
}

async function onTrack(e) {
	remoteStream = e.streams[0];
	attachMediaStream("remote", remoteStream);
}

const createPeerConnection = (sessionId, ws, agentInitiated) => {
	if(pc2) {
		if(pc2.signalingState === "closed") {
			pc2 = new RTCPeerConnection(setRTCConf());
		}
	} else {
		pc2 = new RTCPeerConnection(setRTCConf());
	}

	if(!sendChannel || (sendChannel && sendChannel.readyState === "closed")) {
		sendChannel = pc2.createDataChannel("ClientChannel");
	}

	if(sendChannel) {
		sendChannel.onmessage = function (e) {
			controlRemoteEvents(sessionId, ws, e.data);
		};
		sendChannel.onopen = function () {
			console.log("client data send channel opened");
		};
		sendChannel.onclose = function () {
			console.log("client data send channel closed");
		};
	}

	pc2.addEventListener('icecandidate', e => onIceCandidate(pc2, sessionId, e, ws, agentInitiated));
	pc2.addEventListener('track', e => onTrack(e));

	pc2.addEventListener('iceconnectionstatechange', e => onIceStateChange(pc2, e));
	pc2.addEventListener('icegatheringstatechange', e => onICEGatheringChange(pc2, sessionId, ws, e));

	pc2.addEventListener('datachannel', e => onDataChannel(e));
	websocket = ws;
	return pc2;
};

function onDataChannel (e) {
	receiveChannel = e.channel;
	receiveChannel.onmessage = function (e) {
		controlRemoteEvents(sessionId, ws, e.data);
		};
	receiveChannel.onopen = function () {
		console.log("client data receive channel opened");
	};
	receiveChannel.onclose = function () {
		console.log("client data receive channel closed");
	};
}

var drawPos = {x: 0, y: 0}

function initDraw(ctx, mouse) {
	ctx.beginPath();
	//console.log("init draw position: ", mouse);
	ctx.moveTo(mouse.x, mouse.y); //starting point
}

function draw(ctx, mouse, color) {
	//console.log("drawing", mouse);
	ctx.lineTo(mouse.x, mouse.y);
	ctx.lineWidth = 1;
	ctx.strokeStyle = color;
	ctx.stroke();
}

function handleDragVideoFrame(e){
	e.preventDefault();
	if(!fullScreenMode) {
		window.my_dragging = {};
		my_dragging.pageX0 = e.pageX;
		my_dragging.pageY0 = e.pageY;
		my_dragging.elem = this;
		my_dragging.offset0 = c3jQuery(this).offset();
		function handle_dragging(e){
			var left = my_dragging.offset0.left + (e.pageX - my_dragging.pageX0);
			var top = my_dragging.offset0.top + (e.pageY - my_dragging.pageY0);
			c3jQuery(my_dragging.elem)
			.offset({top: top, left: left});
		}
		function handle_mouseup(e){
			c3jQuery('body')
			.off('mousemove', handle_dragging)
			.off('mouseup', handle_mouseup);
		}
		c3jQuery('body')
		.on('mouseup', handle_mouseup)
		.on('mousemove', handle_dragging);
	}
}

function clearMouseDown(e) {
	e.target.removeEventListener("mousedown", handleDragVideoFrame);
}

//If the element is using custom events, it is cannot be detected/controlled remotely
//only DOM events listed here can be controlled manually
//https://en.wikipedia.org/wiki/DOM_events
function elemClick(x,y){
	var clickEv = new MouseEvent( "click", { clientX: x, clientY: y, bubbles: true } )
	var mouseDownEv = new MouseEvent( "mousedown", {clientX: x, clientY: y, bubbles: true } )
	var mouseUpEv = new MouseEvent( "mouseup", {clientX: x, clientY: y, bubbles: true })
    var el = document.elementFromPoint(x,y);
	el.blur();
	removeCoBrowseFocus();
	if(el) {
		var elNodeName = el.nodeName.toLowerCase();
		activeElem = el;
		//add active elem class
		el.focus();
		el.dispatchEvent(clickEv);
		el.classList.add("active-cobrowse");
		el.dispatchEvent(mouseDownEv);
		el.dispatchEvent(mouseUpEv);
		//console.log("dbg: Element type ", elNodeName);
		if(elNodeName === "input" || elNodeName === "select" || elNodeName === "textarea") {
			//trigger onchange event if registered for the element
			var event = new UIEvent("change", {
				"view": window,
				"bubbles": true,
				"cancelable": true
			});
			el.dispatchEvent(event);
		}
		var elemDraggable = el.draggable;
		var elParentId = el.parentElement.id;
		if(elemDraggable || (elParentId == "CentionChatVideoFrame" || elParentId == "cention-video-container")) {
			el.addEventListener('mousedown', handleDragVideoFrame);
			el.addEventListener('mouseup', clearMouseDown); //fixme @todo: remove event listener but need more testing
		}
	}
}

const createDisplayConnection = (sessionId, ws, agentInitiated) => {
	if(pcDisplay) {
		if(pcDisplay.signalingState === "closed") {
			pcDisplay = new RTCPeerConnection(setRTCConf());
		}
	} else {
		pcDisplay = new RTCPeerConnection(setRTCConf());
	}

	//data channel
	if(!sendChannel || (sendChannel && sendChannel.readyState === "closed")) {
		sendChannel = pcDisplay.createDataChannel("ClientDisplayChannel");
	}

	if(sendChannel) {
		sendChannel.onmessage = function (e) {
			controlRemoteEvents(sessionId, ws, e.data);
		};
	}

	pcDisplay.addEventListener('datachannel', e => onDisplayDataChannel(e));

	//end of handling data channel

	pcDisplay.addEventListener('icecandidate', e => onIceCandidate(pcDisplay, sessionId, e, ws, agentInitiated));
	pcDisplay.addEventListener('iceconnectionstatechange', e => onIceStateChange(pcDisplay, e));
	pcDisplay.addEventListener('icegatheringstatechange', e => onICEGatheringChange(pcDisplay, sessionId, ws, e));
	pcDisplay.addEventListener('track', e => onDisplayTrack(e));

	var negotiating = false;// Chrome workaround
	pcDisplay.onnegotiationneeded = () => {
		if(clientDisplayOffer) {
			negotiating = true;
			if(negotiating && negoNeeded) {
				if(pcDisplay.signalingState !== "have-local-offer") {
					pcDisplay.createOffer({iceRestart: true}).then(d => pcDisplay.setLocalDescription(d))
					.then(() => sendMessage("offer", pcDisplay.localDescription, ws, sessionId, agentInitiated, false, coBrowseMode, ""))
					.catch((e) => {
						console.log("Error negotiating", e);
					});
				}
			}
		}
	};
	websocket = ws;
	return pcDisplay;
};

function onDisplayDataChannel() {
	receiveChannel = e.channel;
	receiveChannel.onmessage = function (e) {
		controlRemoteEvents(sessionId, ws, e.data);
	};

	receiveChannel.onopen = function () {
		console.log("client display receive channel opened");
		//receiveChannel.send(new Uint8Array(maximumMessageSize));
	};

	receiveChannel.onclose = function () {
		console.log("client display receive channel closed");
	};
}

async function onDisplayTrack(e) {
	remoteDisplayStream = e.streams[0];
	attachMediaStream("remote-display", remoteDisplayStream);
}

function controlRemoteEvents(sessionId, ws, data) {
	let msg = JSON.parse(data);
	let mouseCursor = document.querySelector(".cention-custom-cursor");
	let newPosX = 0, newPosY = 0, startPosX = 0, startPosY = 0;
	switch (msg.type) {
		case "video-blur-start":
			startAgentVideoBlur(sessionId, ws);
			break;
		case "video-blur-stop":
			stopAgentVideoBlur();
			break;
		case "video-bg-start":
			startAgentVideoBg(sessionId, msg.img, ws);
			break;
		case "video-bg-change":
			setBackground(msg.img);
			break;
		case "video-bg-change-progress":
			if(msg.status) {
				c3jQuery("#vid-self-loading").hide();
			} else {
				c3jQuery("#vid-self-loading").show();
			}
			break;
		case "video-bg-stop":
			stopAgentVideoBg();
			break;
		case "start-cobrowse":
			console.log("starting co browsing now..");
			break;
		case "mouse-start":
			mouseCursor.style.display = "block";
			break;
		case "mouse-stop":
			mouseCursor.style.display = "none";
			removeCoBrowseFocus();
			break;
		case "mouse-move":
			var pos = msg.data.pos;
			var pageXoffset = window.pageXOffset;
			var pageYOffset = window.pageYOffset;
			mouseCursor.style.top = (pos.y+pageYOffset)+"px";
			mouseCursor.style.left = (pos.x+pageXoffset)+"px";

			//handle dragging stuff if element supported
			if(pos.drag) {
				// 1. calculate the new position //should we include the page offset?
				newPosX = startPosX-pos.x;
				newPosY = startPosY-pos.y;

				// 2. with each move we also want to update the start X and Y
				startPosX = pos.x;
				startPosY = pos.y;

				if(activeElem !== "") {
					var elemDraggable = activeElem.draggable;
					var elParentId = activeElem.parentElement.id;
					if(elemDraggable || (elParentId == "CentionChatVideoFrame" || elParentId == "cention-video-container")) {
						//3. set the element's new position:
						var offset = activeElem.getBoundingClientRect();
						activeElem.style.top = (offset.top-newPosY) + "px";
						activeElem.style.left = (offset.left-newPosX) + "px";
					}
				}
			}
			break;
		case "mouse-down":
			var pos = msg.data.pos;
			mouseCursor.classList.add("mouse-down");

			// get the starting position of the cursor
			startPosX = pos.x;
			startPosY = pos.y;

			elemClick(pos.x, pos.y);
			break;
		case "mouse-up":
			mouseCursor.classList.remove("mouse-down");
			if(activeElem !== "") {
				activeElem.removeEventListener('mousedown', handleDragVideoFrame);
			}
			break;
		case "mouse-click":
			//console.log("mouse clicked pos :", msg.data);
			elemClick(msg.data.pos.x, msg.data.pos.y);
			break;
		case "init-highlight":
			//let drawCanvas = document.getElementById("centionCoBrowseCanvas");
			//let ctx = drawCanvas.getContext("2d");
			/* c3jQuery("#centionCoBrowseCanvas").show();
			var mouse = msg.data.pos;
			var size = msg.data.size;
			drawCanvas.style.width = size.width+"px";
			drawCanvas.style.height = size.height+"px";
			initDraw(ctx, mouse); */
			break;
		case "start-highlight":
			//let drawCanvas = document.getElementById("centionCoBrowseCanvas");
			//let ctx = drawCanvas.getContext("2d");
			/* var mouse = msg.data.pos;
			var color = msg.data.color;
			var highlight = msg.data.highlightMode;
			//start mock drawing
			draw(ctx, mouse, color); */
			break;
		case "stop-highlight":
			console.log("stop highlighting");
			break;
		case "stop-drawing":
			console.log("stop drawing");
			c3jQuery("#centionCoBrowseCanvas").hide();
			break;
		case "scroll-down":
			window.scrollBy(0,100);
			break;
		case "scroll-up":
			window.scrollBy(0,-100);
			break;
		case "scroll-left":
			window.scrollBy(-100,0);
			break;
		case "scroll-right":
			window.scrollBy(100,0);
			break;
		case "keydown":
			var key = msg.data.key, remove = false;
			var keyName = key.toLowerCase();
			if(keyName === "backspace") {
				key = "";
				remove = true;
			} else if(keyName === "shift" || keyName === "capslock"
			|| keyName === "tab" || keyName === "control") {
				key = "";
			} else if(keyName === "arrowdown"){
				window.scrollBy(0,100);
				key = "";
			} else if(keyName === "arrowup") {
				window.scrollBy(0,-100);
				key = "";
			} else if(keyName === "arrowleft") {
				window.scrollBy(-100,0);
				key = "";
			} else if(keyName === "arrowright") {
				window.scrollBy(100,0);
				key = "";
			}
			if(activeElem !== "") {
				var nodeType = activeElem.nodeName.toLowerCase();
				if(nodeType === "input" || nodeType === "textarea") {
					//handle input changes
					if(key != "") {
						activeElem.value = activeElem.value+key;
					} else {
						if(remove) {
							var val = activeElem.value;
							activeElem.value = val.substring(0,val.length-1);
						}
					}
				} else {
					//handle other DOM text changes like editable div and etc
					console.log("nodeType :", nodeType);
				}
			}
			break;
		default:
			console.log("Info: other event", msg.type);
			break;
	}
}

async function callAction(ws, sessionId, clientName) {
	console.log('Starting call');
	clientRequestCall = true;
	playRinging(true);
	//checking if camera started
	if(!localStream) {
		//start camera
		startAction();
	} else {
		const videoTracks = localStream.getVideoTracks();
		const audioTracks = localStream.getAudioTracks();
		if (videoTracks.length > 0) {
			console.log(`Using video device: ${videoTracks[0].label}`);
		}
		if (audioTracks.length > 0) {
			console.log(`Using audio device: ${audioTracks[0].label}`);
		}
	}

	if(!pc2 || pc2.signalingState === "closed") {
		pc2 = createPeerConnection(sessionId, ws, true);
	}

	localStream.getTracks().forEach(track => pc2.addTrack(track, localStream));

	try {
		const offer = await pc2.createOffer(offerOptions);
		await onCreateOfferSuccess(offer, sessionId, clientName, ws);
	} catch (e) {
		onCreateSessionDescriptionError(e);
	}
}

function onIceCandidate(pc, sessionId, event, ws, isAgentInitiated) {
	if(clientRequestCall || clientDisplayOffer) {
		isAgentInitiated = false;
	} else {
		isAgentInitiated = true;
	}
	if(event.candidate){
		sendMessage("ice-candidate", event.candidate, ws, sessionId, isAgentInitiated, isAgentInitiated);
	}
}

function onAddIceCandidateSuccess(pc) {
	console.log(`${getName(pc)} addIceCandidate success`);
}

function onAddIceCandidateError(pc, error) {
	console.log(`${getName(pc)} failed to add ICE Candidate: ${error.toString()}`);
}

function onCreateSessionDescriptionError(error) {
	console.error('Failed to set local session description: ' + error.toString())
}

function onIceStateChange(pc, event) {
	console.log(`${getName(pc)} ICE state: ${pc.iceConnectionState}`);
	if(pc === pc2) {
		if(pc.iceConnectionState === "closed") {
			console.log("video call rtc client closed");
		}
	} else if(pc === pcDisplay){
		if(pc.iceConnectionState === "closed") {
			console.log("screen sharing rtc client closed");
		}
	}
	if(pc.iceConnectionState === "connected") {
		if(agentDisplayOffer) {
			setAgentDisplayOffer(false);
		}
	}
}

function onICEGatheringChange(pc, sessionId, ws, event) {
	console.log(`${getName(pc)} ICE gathering state: ${pc.iceGatheringState}`);
	if(pc.iceGatheringState === "complete") {
		if(agentDisplayOffer) {
			setAgentDisplayOffer(false);
		}
		remoteVidOnPlay(sessionId, ws);
	}
}

function remoteVidOnPlay(sessionId, ws) {
	if(agentPresetBg) {
		startAgentVideoBg(sessionId, agentPresetBg, ws);
	}
	if(agentPresetBlur) {
		startAgentVideoBlur(sessionId, ws);
	}
}

//after initiate call offer, sending description to agent
async function onCreateOfferSuccess(desc, sessionId, clientName, ws, screenSharing) {
	console.log(getName(), ' setLocalDescription start');
	try {
		await pc2.setLocalDescription(desc);
		onSetLocalSuccess(pc2);
		let payload = {
			sdp: desc,
			name: clientName
		}
		sendMessage("offer", payload, ws ,sessionId, false, !screenSharing);
	} catch (e) {
		onSetSessionDescriptionError(e);
	}
}


function onSetLocalSuccess(pc) {
	console.log(`${getName(pc)} setLocalDescription complete`);
}

function onCreateSessionDescriptionError(error) {
	console.log(`Failed to create session description: ${error.toString()}`);
}

function onSetSessionDescriptionError(error) {
	console.log(`Failed to set session description: ${error.toString()}`);
}

//Answering video-offer from agent
async function createAnswerForAgent(msg, sessionId, ws) {
	playRinging(false);
	isAgentInitiated = true;
	if(!pc2 || pc2.signalingState === "closed") {
		pc2 = createPeerConnection(sessionId, ws, isAgentInitiated);
	}
	if(msg.presetBgImg) {
		agentPresetBg = msg.presetBgImg;
	}
	if(msg.presetBgBlur) {
		agentPresetBlur = msg.presetBgBlur;
	}
	pc2.setRemoteDescription(msg.sdp)
	.then(function () {
		navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
		.then(function(stream) {
			localStream = stream;
			localStream.getTracks().forEach(track => pc2.addTrack(track, localStream));
			attachMediaStream("local", stream);
			camDisabledLocal = false;
			c3jQuery('.local.avatar-overlay').hide();
		})
		.then(function() {
			pc2.createAnswer(sdpConstraints).then(function(answer) {
				setLocalAndSendMessage(answer, sessionId, ws, true);
				setOnVideoCall(true);
			})
			.catch(errorCallBack);
		})
	})
	startCount();
};

async function createCoBrowsingForAgent(msg, sessionId, ws) {
	isAgentInitiated = true;
	c3jQuery("#CentionChatVideoFrame").show();
	c3jQuery("[id*='coBrowsing']").show();

	c3jQuery("#CentionChatVideoButton").hide();
	c3jQuery("#CentionChatVideoButtonStop").show();

	c3jQuery("#CentionCoBrowsingStatus").show();
	c3jQuery("#coBrowsingStatusTxt").text("Initiating...");

	//disable all other icons
	c3jQuery('#CentionChatVideoBtns').find('div:not(#coBrowsing):not(#stopCoBrowsing)').addClass('disabled locked')

	//focus on co browse icon to let client know where to click next
	c3jQuery("#coBrowsing").addClass( "cobrowse-need-click" );

	setOnCoBrowseMode(true);
};

function setOnCoBrowseMode(status) {
	coBrowseMode = status;
}

async function handleReceiveAgentDisplay(msg, sessionId, ws) {
	setAgentDisplayOffer(true);
	if(!pcDisplay || pcDisplay.signalingState === "closed") {
		pcDisplay = createDisplayConnection(sessionId, ws, true)
	}
	if(pcDisplay) {
			pcDisplay.setRemoteDescription(msg.sdp)
			.then(function() {
				pcDisplay.createAnswer(sdpConstraints).then(function(answer) {
					setLocalAndSendMessage(answer, sessionId, ws, false);
				})
				.catch(errorCallBack);
				if(collectedDisplayICEs && collectedDisplayICEs.length > 0) {
					collectedDisplayICEs.forEach(function( cand, i ) {
						var candidate = new RTCIceCandidate(cand);
						if(candidate) {
							pcDisplay.addIceCandidate(candidate)
							.then(() => {
								//console.log("Done Adding ICEs into local conn");
							})
							.catch((e) => {
								reportError(e);
							});
						}
					});
				}
			})
	}
}

function handleRejectScreenShareOffer(sessionId, ws) {
	sendMessage("client-reject-screenshare", "", ws, sessionId, false, false);
	setAgentDisplayOffer(false);
}

//Handle "video-answer" received from agent
let hideFrameTimeout;
function hideFrame() {
	//hide the video frame after 10 secs
	c3jQuery("#CentionChatVideoFrame").hide();
	c3jQuery("#CentionChatVideoButton").show();
	c3jQuery("#CentionChatVideoButtonStop").hide();
}
async function onCreateAnswerSuccess(data, sessionId, ws, errMsg) {
	console.log(getName(),' setRemoteDescription start');
	playRinging(false);
	if((pc2 && pc2.signalingState === "have-local-offer") || (pcDisplay && pcDisplay.signalingState === "have-local-offer")) {
		clientRequestCall = false;
		try {
			if(data.init) {
				await pc2.setRemoteDescription(data.sdp);
				onSetRemoteSuccess(pc2, sessionId, data.init);
				setOnVideoCall(true);
				startCount();
			} else {
				if(data.coBrowse) {
					c3jQuery("#coBrowsingStatusTxt").text("Connected");
					//after a few secs, hide the video toolbar by default
					hideFrameTimeout = setTimeout(hideFrame(), 10000);
					//enable back all other icons
					c3jQuery('#CentionChatVideoBtns').find('div.locked').removeClass('disabled locked')

					const HIGHLIGHT = false; //if highlight feature is enabled on agent side
					if(HIGHLIGHT) {
						console.log("adding drawing canvas");
						var canv = document.createElement('canvas');
						canv.id = 'centionCoBrowseCanvas';
						canv.style.background = "none";
						canv.style.top = "0px";
						canv.style.position = "fixed";
						canv.style.width = window.innerWidth+"px";
						canv.style.height = window.innerHeight+"px";
						canv.style.display = "none";
						document.body.appendChild(canv);
					}
				}
				await pcDisplay.setRemoteDescription(data.sdp);
				onSetRemoteDisplaySuccess(pcDisplay, sessionId, data.init);
			}
		} catch (e) {
			onSetSessionDescriptionError(e);
		}
	} else {
		if((pc2 && pc2.signalingState === "stable") || (pcDisplay && pcDisplay.signalingState === "stable")) {
			//todo: should collect possible errors for future error handling
			ws.SendEvent('video-chat-error', {
				sessionId: sessionId,
				type: "video-chat-error",
				data: {type: "INVALID_REQUEST_STATE", msg: errMsg}
			});
		}
	}
}

//Set remote after client call to agent
async function onSetRemoteSuccess(pc, sid, init) {
	console.log(`${getName(pc)} setRemoteDescription complete`);
	//Adding those ICE after remoteDescription completed
	if(collectedICEs && collectedICEs.length > 0) {
		collectedICEs.forEach(function( cand, i ) {
			var candidate = new RTCIceCandidate(cand);
			if(candidate) {
				pc.addIceCandidate(candidate)
				.then(() => {
					onAddIceCandidateSuccess(pc);
				})
				.catch((e) => {
					onAddIceCandidateError(pc, e);
				});
			}
		});
	}

	c3jQuery('#hangupButton').show();
	c3jQuery('#startButton').hide();
	c3jQuery('#callButton').hide();
	c3jQuery("#disableVidButton").show();
	c3jQuery("#disableAudioButton").show();
	c3jQuery("#maximizeVideo").show();

}

async function onSetRemoteDisplaySuccess(pc, sid, init) {
	console.log(`${getName(pc)} setRemoteDescription complete`);
	//Adding those ICE after remoteDescription completed
	if(collectedDisplayICEs && collectedDisplayICEs.length > 0) {
		collectedDisplayICEs.forEach(function( cand, i ) {
			var candidate = new RTCIceCandidate(cand);
			if(candidate) {
				pc.addIceCandidate(candidate)
				.then(() => {
					currentFS = FS_DISPLAY_LOCAL;
					onAddIceCandidateSuccess(pc);
				})
				.catch((e) => {
					onAddIceCandidateError(pc, e);
				});
			}
		});
	}
	setClientDisplayOffer(false);
	clearTimeout(hideFrameTimeout);
}

function onSetSessionDescriptionError(error) {
	console.log(`Failed to set session description: ${error.toString()}`);
}

function attachMediaStream(type, stream) {
	const localVideo = document.getElementById('CentionChatVideoFrameLocal');
	const remoteVideo = document.getElementById('CentionChatVideoFrameRemote');
	const remoteDisplayVid = document.getElementById('agent-widget-display-view');

	if(!onVideoCall) {
		// c3jQuery("#CentionChatVideoFrameWrapper").hide();
	}

	let vid;
	if(type === "local") {
		c3jQuery("#CentionChatVideoFrameWrapper").show();
		document.getElementById('CentionChatVideoLocalWrapper').style.display = "flex";
		vid = localVideo;
		localStream = stream;
	}else if(type === "remote") {
		c3jQuery("#CentionChatVideoFrameWrapper").show();
		document.getElementById('CentionChatVideoRemoteWrapper').style.display = "flex";
		if( agentPresetBlur || agentPresetBg ) {
			remoteVideo.hidden = true;
		} else {
			remoteVideo.hidden = false;
		}
		if(stream) {
			vid = remoteVideo;
			remoteStream = stream;
		}
	}else if(type === "remote-display") {
		document.getElementById('CentionChatRemoteDisplay').style.display = "inline-flex";
		document.getElementById('CentionChatAgentScreenShareWrapper').style.display = "flex";
		if(!clientScreenShareMode && !coBrowseMode) {
			document.getElementById('CentionChatClientScreenShareWrapper').style.display = "none";
		}
		vid = remoteDisplayVid;
		remoteDisplayStream = stream;
		if(agentDisplayOffer) {
			setAgentScreenShare(true);
		}
		if(clientDisplayOffer) {
			setClientDisplayOffer(false);
			setClientScreenShare(true);
		}
	}
	vid.srcObject = stream;
}

async function setLocalAndSendMessage(sessionDescription, sessionId, ws, init) {
	try {
		if(init) {
			await pc2.setLocalDescription(sessionDescription);
			onSetLocalSuccess(pc2);
		} else {
			await pcDisplay.setLocalDescription(sessionDescription);
			onSetLocalSuccess(pcDisplay);
		}
		sendMessage("answer",sessionDescription, ws, sessionId, true , init);
	} catch (e) {
		onSetSessionDescriptionError(e);
	}
};

function errorCallBack(err) {
	console.log("errorCallBack => ", err);
}

//Client receives call ICE from agent
async function handleICECandidateMsgFromAgent(msg, fromAgent, ws) {
	if(msg.candidate) {
		collectedICEs.push(msg.candidate);
		if(pc2) {
			try {
				if(pc2.signalingState === "stable") {
					var candidate = new RTCIceCandidate(msg.candidate);
					if(candidate) {
						pc2.addIceCandidate(candidate)
						.then(() => {
							onAddIceCandidateSuccess(pc2);
						})
					}
				}
			} catch (e) {
				onAddIceCandidateError(pc2, e);
			}
		}
	}
}

//Client receives screen sharing ICE from agent
async function handleDisplayICECandidateFromAgent(msg, fromAgent, ws) {
	if(msg.candidate) {
		collectedDisplayICEs.push(msg.candidate);
		if(pcDisplay) {
			try {
				if(pcDisplay.signalingState === "stable") {
					var candidate = new RTCIceCandidate(msg.candidate);
					if(candidate) {
						pcDisplay.addIceCandidate(candidate)
						.then(() => {
							currentFS = FS_DISPLAY;
							onAddIceCandidateSuccess(pcDisplay);
						})
					}
				}
			} catch (e) {
				onAddIceCandidateError(pcDisplay, e);
			}
		}
	}
}

//agentInitiated -> if agent the one initiated the call
//init -> initial offer ,not during negotiation
function sendMessage(type, value, ws, sid, agentInitiated, init, coBrowse, selectedDisplay) {
	if(typeof coBrowse === "undefined") {
		coBrowse = false;
	}
	if(type === "answer") {
		ws.SendEvent('video-answer', {
			sessionId: sid,
			type: "video-answer",
			sdp: value,
			init: init
		});
		if(init) {
			c3jQuery("#CentionChatVideoFrame").show();
			c3jQuery("#startButton").hide();
			c3jQuery("#callButton").hide();
			c3jQuery("#hangupButton").show();
			c3jQuery("#disableVidButton").show();
			c3jQuery("#disableAudioButton").show();
			c3jQuery("#maximizeVideo").show();
		}
	} else if(type === "offer") {
		var payload = {
			sessionId: sid,
			type: "video-offer",
			sdp: value,
			init: init,
			coBrowse: coBrowse,
			selectedDisplay: selectedDisplay
		}
		ws.SendEvent("video-offer", payload);
	} else if(type === "ice-candidate") {
		var payload = {
			sessionId: sid,
			type: "new-ice-candidate",
			candidate: value,
			agentInitiated: agentInitiated,
			clientScreenShare: clientDisplayOffer,
			agentScreenShare: agentDisplayOffer,
		}
		ws.SendEvent("new-ice-candidate", payload);
	} else if(type === "hang-up") {
		ws.SendEvent('hang-up', {
			sessionId: sid,
			type: "hang-up"
		});
	} else if(type === "stop-screen-share") {
		ws.SendEvent('CLIENT_STOP_SCREEN_SHARE', {
			sessionId: sid,
			type: "CLIENT_STOP_SCREEN_SHARE",
			coBrowse: coBrowse
		});
	} else if(type === "client-reject-screenshare") {
		ws.SendEvent('CLIENT_REJECT_SCREEN_SHARE', {
			sessionId: sid,
			type: "CLIENT_REJECT_SCREEN_SHARE"
		});
	} else if(type === "stop-agent-screen-share") {
		ws.SendEvent('CLIENT_STOP_AGENT_SCREEN_SHARE', {
			sessionId: sid,
			type: "CLIENT_STOP_AGENT_SCREEN_SHARE"
		});
	} else if(type === "stop-cobrowse") {
		ws.SendEvent('stop-cobrowse', {
			sessionId: sid,
			type: "stop-cobrowse"
		});
	} else if(type === "activate-cobrowse") {
		ws.SendEvent('activate-cobrowse', {
			sessionId: sid,
			type: "activate-cobrowse"
		});
	}
}

//Video actions

// Handles hangup action: ends up call, closes connections and resets peers.
function hangupAction(sessionId, ws, fromAgent, fromSystem) {
	setOnVideoCall(false);
	if(pc2) {
		if(agentDisplayOffer || agentScreenShareMode || clientScreenShareMode || coBrowseMode) {
			//screen sharing still in session
			c3jQuery("#startButton").show();
		}
		c3jQuery("#CentionChatVideoLocalWrapper").hide();
		c3jQuery("#CentionChatVideoRemoteWrapper").hide();
	}
	const remoteVideo = document.getElementById("CentionChatVideoFrameRemote");
	if(remoteVideo) {
		if (remoteVideo.srcObject) {
			remoteVideo.srcObject.getTracks().forEach(track => track.stop());
		}
		remoteVideo.removeAttribute("src");
		remoteVideo.removeAttribute("srcObject");
		if(!fromAgent && !fromSystem) {
			playRinging(false);
			sendMessage("hang-up","",ws, sessionId);
		} else {
			c3jQuery('#hangupButton').hide();
			c3jQuery("#startButton").show();
			c3jQuery("#disableVidButton").hide();
			c3jQuery("#disableAudioButton").hide();
			c3jQuery("#enableVidButton").hide();
			c3jQuery("#enableAudioButton").hide();
			c3jQuery('#callButton').removeClass("disabled");
			//TODO: exit full screen

			playRinging(false);
		}
		console.log('Ending call.');
	}
	const localVideo = document.getElementById('CentionChatVideoFrameLocal');
	if(localVideo) {
		if (localVideo.srcObject) {
			localVideo.srcObject.getTracks().forEach(track => track.stop());
		}
		localVideo.removeAttribute("src");
		localVideo.removeAttribute("srcObject");
	}
	disableWebCam();
	disconnectPC();
	stopCount();
}

// Handles end button action: end video
function stopWebCam(sessionId, ws) {
	if(localStream) {
		localStream.getVideoTracks()[0].enabled = false;
		ws.SendEvent('client-disable-video', {
			sessionId: sessionId,
			type: "client-disable-video"
		});
	}
	console.log('Pausing video.');
	c3jQuery('.local.avatar-overlay').show();
	camDisabledLocal = true;
}

function disableWebCam() {
	if(localStream) {
		localStream.getVideoTracks()[0].stop();
		localStream.getAudioTracks()[0].stop();
		const localVideo = document.getElementById('CentionChatVideoFrameLocal');
		localVideo.srcObject = null;
	}

	if(remoteStream) {
		let remoteTracks = remoteStream.getTracks();
		remoteTracks.forEach(function(t) {
			t.stop();
		});
		const remoteVideo = document.getElementById('CentionChatVideoFrameRemote');
		remoteVideo.srcObject = null;
	}
	console.log('Stopping media.');
}

function reEnableWebCam(sessionId, ws) {
	if(localStream) {
		localStream.getVideoTracks()[0].enabled = true;
		ws.SendEvent('client-enable-video', {
			sessionId: sessionId,
			type: "client-enable-video"
		});
	}
	c3jQuery('.local.avatar-overlay').hide();
	console.log('Re-enabling video.');
	camDisabledLocal = false;
}

function stopAudio(sessionId, ws) {
	if(localStream) {
		localStream.getAudioTracks()[0].enabled = false;
		ws.SendEvent('client-disable-audio', {
			sessionId: sessionId,
			type: "client-disable-audio"
		});
	}
	console.log('Stopping audio.');
}

function startAudio(sessionId, ws) {
	if(localStream) {
		localStream.getAudioTracks()[0].enabled = true;
		ws.SendEvent('client-enable-audio', {
			sessionId: sessionId,
			type: "client-enable-audio"
		});
	}
	console.log('Re-enabling audio.');
}

function cancelCallAction(alertTxt) {
	if(!onVideoCall) {
		alert(alertTxt);
		c3jQuery('#callButton').removeClass("disabled");
		c3jQuery("#callButton").hide();
		c3jQuery("#hangupButton").hide();
		c3jQuery("#disableAudioButton").hide();
		c3jQuery("#disableVidButton").hide();
		c3jQuery("#startButton").show();

		playRinging(false);

		//stop local webcam stream
		disableWebCam();

		//close webcam frame
		// c3jQuery("#CentionChatVideoFrameWrapper").hide();
		setOnVideoCall(false);
		clientRequestCall = false;
	}
}

function cancelScreenShareAction(sessionId, ws) {
	stopScreenSharing(sessionId, true, false, ws);
	c3jQuery("#disableVidButton").hide();
	c3jQuery("#screenShare").show();
	c3jQuery("#stopScreenShare").hide();
	setClientDisplayOffer(false);
	setClientScreenShare(false);
	c3jQuery("#coBrowsing").show();
	c3jQuery("#stopCoBrowsing").hide();
	setOnCoBrowseMode(false);
}

function handleAgentMute() {
	c3jQuery('.agent-mute').show();
}

function handleAgentUnMute() {
	c3jQuery('.agent-mute').hide();
}
function handleAgentDisableVideo() {
	c3jQuery('.remote.avatar-overlay').show();
	console.log("agent disabled video");
}

function handleAgentEnableVideo() {
	c3jQuery('.remote.avatar-overlay').hide();
	console.log("agent re-enabled video");
}

function playRinging(play) {
	if(CentionBaseURL && spacePrefix) {
		var ringAudio = document.getElementById('CentionChatCallRinger');
		var src = CentionBaseURL + spacePrefix +'/cention/chat/sounds/Ringing_Phone.mp3';
		if(play) {
			if(ringAudio) {
				ringAudio.src = src;
				ringAudio.loop = true;
				ringAudio.play();
			}
		}else{
			if(ringAudio) {
				ringAudio.pause();
				ringAudio.loop = false;
				ringAudio.currentTime = 0;
				ringAudio.src = '';
			}
		}
	}
}

function resumeCoBrowsing(sessionId) {
	//console.log("TODO: resume co browsing ", sessionId);
}

async function startScreenSharing(sessionId, ws, coBrowse) {
	var connNew = false;
	if(!pcDisplay || pcDisplay.connectionState === "closed") {
		pcDisplay = createDisplayConnection(sessionId, ws, false);
		connNew = true;
	}
	if(clientScreenShareMode) {
		var coBrowseAlertMsg = "Do you want to proceed with co browsing session ? "; //todo: translation
		var clientAcceptance = confirm(coBrowseAlertMsg);
		if (clientAcceptance == true) {
			coBrowseWhileSharing = true;
			sendMessage("activate-cobrowse","",ws, sessionId);
			//hide self screeen preview
			c3jQuery("#CentionChatClientScreenShareWrapper").hide();
			//and show co browsing toolbar
			c3jQuery("#toggleRemoteControl").show();
			return;
		} else {
			console.log("Cancelling co browse and proceed with current session");
			return;
		}
	}
	try {
		var shareContraint = displayMediaOptions;
		if(coBrowse) {
			shareContraint = displayMediaOptionsForCoBrowsing;
			c3jQuery("#coBrowsingStatusTxt").show();
			c3jQuery("#coBrowsingStatusTxt").text("Starting...");
		}
		if(isFF) {
			//firefox
			await navigator.mediaDevices.getUserMedia({
				video: {
				  mediaSource: 'browser' //or window, or screen
				}
			  }).then(returnedStream => {
				// use the stream
				console.log("returnedStream => ", returnedStream);
				displayMediaStream = returnedStream;
			  });
		} else {
			//chrome
			if (!displayMediaStream) {
				displayMediaStream = await navigator.mediaDevices.getDisplayMedia(shareContraint);
			} else {
				displayMediaStream = "";
				displayMediaStream = await navigator.mediaDevices.getDisplayMedia(shareContraint);
			}
		}
		//Listen to click stop sharing from browser's UI / shared screen closed
		displayMediaStream.getVideoTracks()[0].onended = function () {
			stopScreenSharing(sessionId, false, false, ws);
		};

		if(!coBrowse) {
			if(agentScreenShareMode) {
				document.getElementById('CentionChatRemoteDisplay').style.display = "inline-flex";
			} else {
				document.getElementById('CentionChatRemoteDisplay').style.display = "block";
			}
			document.getElementById('CentionChatClientScreenShareWrapper').style.display = "flex";
			document.getElementById('client-widget-display-view').srcObject = displayMediaStream;
		} else {
			//if coBrowse mode, no need to show self view on screen sharing
			//todo: once agent can collaborate, change to "connected"
			c3jQuery("#CentionCoBrowsingStatus").show();
			c3jQuery("#coBrowsingStatusTxt").show();
			c3jQuery("#coBrowsingStatusTxt").text("Connecting...");
		}
		if(pcDisplay) {
			if(displayMediaStream) {
				let sharedSettings = displayMediaStream.getVideoTracks()[0].getSettings();
				let selectedDisplay = sharedSettings.displaySurface; //browser (tab), window or screen
				if(coBrowse && selectedDisplay !== "browser") {
					alert("Please select a Chrome Tab to use co browsing "); //todo: translation
					cancelScreenShareAction(sessionId, ws);
					return;
				}
				displayMediaStream.getTracks().forEach(track => {
					if(track.kind === 'video') {
						setClientDisplayOffer(true);
						if(connNew) {
							pcDisplay.addTrack(track, displayMediaStream);
							try {
								pcDisplay.createOffer({iceRestart: true}).then(d => pcDisplay.setLocalDescription(d))
								.then(() => sendMessage("offer", pcDisplay.localDescription, ws, sessionId, false, false, coBrowse, selectedDisplay))
							} catch (e) {
								onCreateSessionDescriptionError(e);
							}
						} else {
							negoNeeded = true;
							pcDisplay.addTrack(track, displayMediaStream);
						}
					}
				});
				setClientScreenShare(true);
			}
		} else {
			console.log("Error: No connection and local stream detected.");
		}
	} catch (e) {
		console.log('Unable to acquire screen capture: ' + e);
		//cancel screens hare
		cancelScreenShareAction(sessionId, ws);
	}
}

async function stopScreenSharing(sessionId, cancel, remote, ws) {
	if(displayMediaStream) {
		let tracks = displayMediaStream.getTracks();
		tracks.forEach(track => track.stop());
		if(!cancel && !remote) {
			sendMessage("stop-screen-share","",ws, sessionId, "", "", coBrowseMode);
		} else {
			if(cancel) {
				var obj = {
					sessionId: sessionId,
					type: "cancel-co-browsing"
				};
				sendMsgThruChannel(JSON.stringify(obj));
			}
		}
		document.getElementById('CentionChatClientScreenShareWrapper').style.display = "none";
		if(!clientScreenShareMode && !agentScreenShareMode && !coBrowseMode) {
			document.getElementById('CentionChatRemoteDisplay').style.display = "none";
		}
		c3jQuery("#stopScreenShare").hide();
		c3jQuery("#screenShare").show();
		setClientScreenShare(false);
		stopCoBrowsing(sessionId, ws);
	}
	coBrowseMode = false;
	if(!agentScreenShareMode) {
		disconnectPC(true, false);
	}
	stopCapture();
	c3jQuery("#CentionChatCoBrowseButton").show();
	c3jQuery("#CentionChatCoBrowseStopButton").hide();
	c3jQuery("#coBrowsingStatusTxt").text("Stopped");
	removeCoBrowseFocus();
}

function handleStopAgentScreenShare(sessionId, remote, ws) {
	const videoElem = document.getElementById('agent-widget-display-view');
	if(videoElem.srcObject) {
		let tracks = videoElem.srcObject.getTracks();
		tracks.forEach(track => track.stop());
		videoElem.srcObject = null;
	}
	document.getElementById('CentionChatAgentScreenShareWrapper').style.display = "none";
	if(!clientScreenShareMode && !agentScreenShareMode && !coBrowseMode) {
		document.getElementById('CentionChatRemoteDisplay').style.display = "none";
	}
	if(remote){
		sendMessage("stop-agent-screen-share","",ws, sessionId);
	}
	setAgentScreenShare(false);
	if(!clientScreenShareMode && !coBrowseMode) {
		disconnectPC(true);
	}
}

function setAgentDisplayOffer(status) {
	agentDisplayOffer = status;
}

function setClientDisplayOffer(status) {
	clientDisplayOffer = status;
}

function setAgentScreenShare(status) {
	agentScreenShareMode = status;
}

function setClientScreenShare(status) {
	clientScreenShareMode = status;
}

function setOnVideoCall(status) {
	onVideoCall = status;
}

function disconnectPC(screenSharing, force) {
	if(force) {
		if(pc2) {
			try{
				pc2.removeEventListener('icecandidate', onIceCandidate);
				pc2.removeEventListener('iceconnectionstatechange', onIceStateChange);
				pc2.removeEventListener('icegatheringstatechange', onICEGatheringChange);
				pc2.removeEventListener('track', onTrack);
				pc2.removeEventListener('datachannel', onDataChannel);
			} catch(e) {
				console.log("error removing event listener");
			}
			pc2.close();
			pc2 = null;
			collectedICEs = [];
		} else if(pcDisplay) {
			try{
				pcDisplay.removeEventListener('datachannel', onDisplayDataChannel);
				pcDisplay.removeEventListener('icecandidate', onIceCandidate);
				pcDisplay.removeEventListener('iceconnectionstatechange', onIceStateChange);
				pcDisplay.removeEventListener('icegatheringstatechange', onICEGatheringChange);
				pcDisplay.removeEventListener('track', onDisplayTrack);
			} catch(e) {
				console.log("error removing event listener");
			}
			pcDisplay.close();
			pcDisplay = null;
			collectedDisplayICEs = [];
		}
		if(sendChannel){
			sendChannel.close();
		}
		if(receiveChannel){
			receiveChannel.close();
		}
		if(blurActivated){
			stopAgentVideoBlur();
		}
		if(changingBg){
			stopAgentVideoBg();
		}
		agentPresetBg = "";
		agentPresetBlur = false;
		onResetRemoteVideo();
	} else {
		if(!coBrowseMode) {
			if(screenSharing) {
				if(pcDisplay) {
					pcDisplay.close();
					pcDisplay = null;
					collectedDisplayICEs = [];
					currentFS = FS_DEFAULT
					console.log("Disconnected screen sharing connection");
				}
			} else {
				if(pc2) {
					pc2.close();
					pc2 = null;
					collectedICEs = [];
					if(sendChannel){
						sendChannel.close();
					}
					if(receiveChannel){
						receiveChannel.close();
					}
					if(blurActivated){
						stopAgentVideoBlur();
					}
					if(changingBg){
						stopAgentVideoBg();
					}
					agentPresetBg = "";
					agentPresetBlur = false;
					onResetRemoteVideo();
					console.log("Disconnected call connection");
				}
			}
		} else {
			console.log("Co browsing still on going, no disconnect");
		}
	}
	window.removeEventListener('beforeunload', onBeforeUnload);
	window.removeEventListener('unload', onUnload);
}

function reportError(errMessage) {
	log_error(`Error ${errMessage.name}: ${errMessage.message}`);
}

function log_error(text) {
	var time = new Date();
	console.trace("[" + time.toLocaleTimeString() + "] " + text);
}

var timer;

function timerCount() {
	var time_shown = c3jQuery("#callTimer").text();
	var time_chunks = time_shown.split(":");
	var hour, mins, secs;

	hour = Number(time_chunks[0]);
	mins = Number(time_chunks[1]);
	secs = Number(time_chunks[2]);
	secs++;
	if (secs == 60) {
		secs = 0;
		mins = mins + 1;
	}
	if (mins == 60) {
		mins = 0;
		hour = hour + 1;
	}
	if (hour == 13) {
		hour = 1;
	}

	c3jQuery("#callTimer").text(hour + ":" + plz(mins) + ":" + plz(secs));

}

function startCount() {
	c3jQuery("#callTimer").show();
	c3jQuery("#callTimer").text("0:00:00");
	timer = setInterval(timerCount, 1000);
}

function stopCount() {
	clearInterval(timer);
	timer = null;
	c3jQuery("#callTimer").hide();
}

function plz(digit) {
	var zpad = digit + '';
	if (digit < 10) {
		zpad = "0" + zpad;
	}
	return zpad;
}

function sendMsgThruChannel(msg) {
	if(receiveChannel && receiveChannel.readyState === "open"){
		receiveChannel.send(msg);
	} else if(sendChannel && sendChannel.readyState === "open") {
		sendChannel.send(msg);
	} else {
		console.log("No opened data channel");
	}
}

/** blur agent's background */

const tsScript = "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs/dist/tf.min.js";
const bodyPixScript = "https://cdn.jsdelivr.net/npm/@tensorflow-models/body-pix@2.2.0/dist/body-pix.min.js";
var tfModel = ""; //to indicated if tfmodel has been imported

function checkWebGL2(sessionId, ws) {
	const gl = document.createElement('canvas').getContext('webgl2');
	if (!gl) {
		if (typeof WebGL2RenderingContext !== 'undefined') {
			console.log('your browser appears to support WebGL2 but it might be disabled. Try updating your OS and/or video card drivers');
			ws.SendEvent('client-bg-effect-error', {
				sessionId: sessionId,
				type: "client-bg-effect-error"
			});
			return false;
		} else {
			console.log('your browser has no WebGL2 support at all');
			ws.SendEvent('client-bg-effect-error', {
				sessionId: sessionId,
				type: "client-bg-effect-error"
			});
			return false;
		}
	} else {
		return true;
	}
}

async function startAgentVideoBlur(sessionId, ws) {
	if(checkWebGL2(sessionId, ws)){
		const remoteVideo = document.getElementById('CentionChatVideoFrameRemote');
		const canvasElement = document.getElementById('agentBlurCanvas');
		const canvasCtx = canvasElement.getContext('2d');

		const selfieSegmentation = new SelfieSegmentation({locateFile: (file) => {
			return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
		}});
		selfieSegmentation.setOptions({
			modelSelection: 1,
		});
		selfieSegmentation.onResults(onResultsForBlur);

		if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
			blurActivated = true;
			async function remoteOnFrame(now, metadata) {
				remoteVideo.currentTime = now;
				canvasElement.width = remoteVideo.width;
				canvasElement.height = remoteVideo.height;
				await selfieSegmentation.send({image: remoteVideo});
				if(blurActivated) {
					remoteVideo.requestVideoFrameCallback(remoteOnFrame);
				}
			}
			remoteVideo.requestVideoFrameCallback(remoteOnFrame);
			c3jQuery('#CentionChatVideoFrameRemote').hide();
			c3jQuery('#agentBlurCanvas').show();
		} else {
			console.log('Error: requestVideoFrameCallback API not supported');
			ws.SendEvent('client-bg-effect-error', {
				sessionId: sessionId,
				type: "client-bg-effect-error"
			});
		}

		function onResultsForBlur(results) {
			canvasCtx.save();
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height); //this is draw bg

			//re-draw selfie inner segment
			canvasCtx.globalCompositeOperation = 'source-in';
			var imageData = results.image;
			var pixel = imageData;
			for (var p = 0; p < pixel.length; p += 4) {
				if (results.segmentationMask.data[p / 4] == 0) {
					pixel[p + 3] = 0;
				}
			}
			canvasCtx.imageSmoothingEnabled = true;
			canvasCtx.drawImage(imageData, 0, 0, canvasElement.width, canvasElement.height);

			 // Only overwrite missing pixels with blur effect
			canvasCtx.globalCompositeOperation = 'destination-atop';
			canvasCtx.filter = 'blur(4px)';
			canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.restore();
		}
	}
}

function stopAgentVideoBlur() {
	blurActivated = false;
	onExitBlurRemote();
	if(fullScreenMode){
		exitBlurFullScreen();
	}
}

/** Change agent's background */
function startAgentVideoBg(sessionId, img, ws) {
	if(checkWebGL2(sessionId, ws)){
		const remoteVideo = document.getElementById('CentionChatVideoFrameRemote');
		const bgWrapper = document.getElementById('bg-container');
		const canvasElement = document.getElementById('canvasBg');
		const canvasCtx = canvasElement.getContext('2d');

		const selfieSegmentation = new SelfieSegmentation({locateFile: (file) => {
			return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
		}});
		selfieSegmentation.setOptions({
			modelSelection: 1,
		});
		selfieSegmentation.onResults(onResults);

		if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
			changingBg = true;
			async function remoteOnFrame(now, metadata) {
				remoteVideo.currentTime = now;
				canvasElement.width = metadata.width;
				canvasElement.height = metadata.height;
				await selfieSegmentation.send({image: remoteVideo});
				if(changingBg) {
					remoteVideo.requestVideoFrameCallback(remoteOnFrame);
				}
			}
			remoteVideo.requestVideoFrameCallback(remoteOnFrame);
			setBackground(img);
			remoteVideo.hidden = true;
			bgWrapper.hidden = false;
		} else {
			console.log('Error: requestVideoFrameCallback API not supported');
			ws.SendEvent('client-bg-effect-error', {
				sessionId: sessionId,
				type: "client-bg-effect-error"
			});
		}

		function onResults(results) {
			canvasCtx.save();
			canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.drawImage(results.segmentationMask, 0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.globalCompositeOperation = 'source-in';
			var imageData = results.image;
			var pixel = imageData;
			for (var p = 0; p < pixel.length; p += 4) {
				if (results.segmentationMask.data[p / 4] == 0) {
					pixel[p + 3] = 0;
				}
			}
			canvasCtx.imageSmoothingEnabled = true;
			canvasCtx.drawImage(imageData, 0, 0, canvasElement.width, canvasElement.height);
			canvasCtx.restore();
		}
	}
}

function setBackground(bg) {
	blurActivated = false;
	bgActivated = true;
	if(!fullScreenMode){
		onBgRemote();
	} else {
		resizeEffectForFullScreen();
	}
	if(CentionBaseURL) {
		var bgPath = CentionBaseURL +"/ng/img/background/"+bg;
		if(spacePrefix) {
			bgPath = CentionBaseURL + spacePrefix +"/ng/img/background/"+bg;
		}
		c3jQuery("#vid-bg-container").css("background-image", "url(" + bgPath + ")");
	} else {
		console.log("Please set base url");
	}
}

function stopAgentVideoBg() {
	bgActivated = false;
	changingBg = false;
	onExitBgRemote();
	if(fullScreenMode){
		exitBgFullScreen();
	}
}

function onResetRemoteVideo(){
	c3jQuery("#agentBlurCanvas").hide();
	c3jQuery("#bg-container").hide();
	c3jQuery("#canvasBg").hide();
	c3jQuery("#vid-bg-container").hide();
	c3jQuery("#CentionChatVideoFrameRemote").show();

	resetCanvasSize();
}

function resetCanvasSize(){
	c3jQuery("#bg-container").removeClass("fullscreen");
	c3jQuery("#canvasBg").removeClass("fullscreen");
	c3jQuery("#vid-bg-container").removeClass("fullscreen");
	c3jQuery("#agentBlurCanvas").removeClass("fullscreen");
}

function onBlurFullScreen() {
	c3jQuery("#agentBlurCanvas").show();
	c3jQuery("#CentionChatVideoFrameRemote").hide();

	if(!agentScreenShareMode && !clientScreenShareMode && !coBrowseMode) {
		c3jQuery("#agentBlurCanvas").addClass("fullscreen");
		var vHeight = "calc(100vh - 100px)";
		var vWidth = "100vw";
		c3jQuery("#agentBlurCanvas").height(vHeight);
		c3jQuery("#agentBlurCanvas").width(vWidth);
	}

	c3jQuery("#bg-container").hide();
	c3jQuery("#canvasBg").hide();
	c3jQuery("#vid-bg-container").hide();
}

function onBlurRemote() {
	var vHeight = c3jQuery("#CentionChatVideoFrameRemote").height();
	var vWidth = c3jQuery("#CentionChatVideoFrameRemote").width();
	c3jQuery("#agentBlurCanvas").height(vHeight);
	c3jQuery("#agentBlurCanvas").width(vWidth);

	c3jQuery("#agentBlurCanvas").show();
	c3jQuery("#CentionChatVideoFrameRemote").hide();

	c3jQuery("#bg-container").hide();
	c3jQuery("#canvasBg").hide();
	c3jQuery("#vid-bg-container").hide();

	//when become thumbnail during screen sharing session
	if(fullScreenMode){
		if(agentScreenShareMode){
			c3jQuery("#agentBlurCanvas").removeClass("fullscreen");
			c3jQuery("#agentBlurCanvas").addClass("cVidThumbnail right");
			if(clientScreenShareMode || coBrowseMode) {
				c3jQuery("#agentBlurCanvas").removeClass("fullscreen left right");
				c3jQuery("#agentBlurCanvas").addClass("cVidThumbnail side-right");
			}
		} else if(clientScreenShareMode || coBrowseMode) {
			c3jQuery("#agentBlurCanvas").removeClass("fullscreen");
			c3jQuery("#agentBlurCanvas").addClass("cVidThumbnail left");
		}
	}
}

function onExitBlurRemote(){
	c3jQuery("#agentBlurCanvas").hide();
	c3jQuery("#CentionChatVideoFrameRemote").show();
}

function exitBlurFullScreen() {
	c3jQuery("#CentionChatVideoFrameRemote").show();
	c3jQuery("#agentBlurCanvas").hide();
	c3jQuery("#agentBlurCanvas").removeClass("fullscreen cVidThumbnail left right side-left side-right");
}

function onBgFullScreen() {
	c3jQuery("#bg-container").show();
	c3jQuery("#canvasBg").show();
	c3jQuery("#vid-bg-container").show();
	c3jQuery("#CentionChatVideoFrameRemote").hide();
	c3jQuery("#agentBlurCanvas").hide();

	if(!agentScreenShareMode && !clientScreenShareMode && !coBrowseMode) {
		c3jQuery("#bg-container").addClass("fullscreen");
		c3jQuery("#canvasBg").addClass("fullscreen");
		c3jQuery("#vid-bg-container").addClass("fullscreen");
		var vHeight = "calc(100vh - 100px)";
		var vWidth = "100vw";
		c3jQuery("#bg-container").height(vHeight);
		c3jQuery("#bg-container").width(vWidth);
		c3jQuery("#vid-bg-container").height(vHeight);
		c3jQuery("#vid-bg-container").width(vWidth);
	}
}

function onBgRemote(){
	c3jQuery("#bg-container").show();
	c3jQuery("#canvasBg").show();
	c3jQuery("#vid-bg-container").show();
	c3jQuery("#agentBlurCanvas").hide();

	var vHeight = c3jQuery("#CentionChatVideoFrameRemote").height();
	var vWidth = c3jQuery("#CentionChatVideoFrameRemote").width();

	c3jQuery("#bg-container").height(vHeight);
	c3jQuery("#bg-container").width(vWidth);
	c3jQuery("#vid-bg-container").height(vHeight);
	c3jQuery("#vid-bg-container").width(vWidth);

	c3jQuery("#CentionChatVideoFrameRemote").hide();

	//when become thumbnail during screen sharing session
	if(fullScreenMode){
		if(agentScreenShareMode){
			c3jQuery("#vid-bg-container").removeClass("fullscreen");
			c3jQuery("#bg-container").removeClass("fullscreen");
			c3jQuery("#canvasBg").removeClass("fullscreen");
			c3jQuery("#bg-container").addClass("cVidThumbnail right");
			if(clientScreenShareMode) {
				c3jQuery("#bg-container").removeClass("fullscreen left right");
				c3jQuery("#canvasBg").removeClass("fullscreen left right");
				c3jQuery("#bg-container").addClass("cVidThumbnail side-right");
				c3jQuery("#canvasBg").addClass("cVidThumbnail side-right");
			}
		} else if(clientScreenShareMode) {
			c3jQuery("#vid-bg-container").removeClass("fullscreen");
			c3jQuery("#bg-container").removeClass("fullscreen");
			c3jQuery("#canvasBg").removeClass("fullscreen");
			c3jQuery("#bg-container").addClass("cVidThumbnail left");
			c3jQuery("#canvasBg").addClass("cVidThumbnail left");
		}
	}
}

function onExitBgRemote(){
	c3jQuery("#bg-container").hide();
	c3jQuery("#canvasBg").hide();
	c3jQuery("#vid-bg-container").hide();
	c3jQuery("#CentionChatVideoFrameRemote").show();
}

function exitBgFullScreen() {
	c3jQuery("#bg-container").removeClass("fullscreen cVidThumbnail left right side-left side-right");
	c3jQuery("#canvasBg").removeClass("fullscreen cVidThumbnail left right side-left side-right");
	c3jQuery("#vid-bg-container").removeClass("fullscreen cVidThumbnail left right side-left side-right");
}

function resizeEffectForFullScreen(){
	if(currentFS == FS_REMOTE){
		if(bgActivated) {
			onBgFullScreen();
		} else if(blurActivated) {
			onBlurFullScreen();
		}
	} else {
		if(bgActivated) {
			onBgRemote();
		} else if(blurActivated) {
			onBlurRemote();
		}
	}
}

//co browsing
function receiveCoBrowseRequest(data) {
	console.log("dbg: co browse receive first signal for session ", data);

	var remoteCoBrowseConnection = new RTCPeerConnection();
	remoteCoBrowseConnection.ondatachannel = receiveCoBrowseChannelCallback;
}

function receiveCoBrowseChannelCallback(e) {
	console.log("receive cobrowse callback ", e);
}

var captureStream, interval;
//no longer using these but kept for future reference
//taking snap shot of webpage to be sent to agent
/*
const capture = async (sessionId) => {
	console.log("start capturing live");
	//capturing screen shot first
	const screenshotTarget = document.body;
	var base64image;
	var charSlice = 10000;
	var dataSent = 0;
	html2canvas(screenshotTarget).then((canvas) => {
		base64image = canvas.toDataURL("image/png");
	});
	interval = setInterval(function() {
		var slideEndIndex = dataSent + charSlice;
		if (slideEndIndex > base64image.length) {
			slideEndIndex = base64image.length;
		}
		var chunk = base64image.slice(dataSent, slideEndIndex);
		//console.log("Chunk -> ", chunk);

		var obj = {};
		obj.part = dataSent;
		obj.sessionId = sessionId;
		obj.img = chunk;
		obj.type = "client-snapshots";
		sendMsgThruChannel(JSON.stringify(obj));

		dataSent = slideEndIndex;
		if (dataSent + 1 >= base64image.length) {
			trace("All data chunks sent.");
			sendMsgThruChannel("\n");
			clearInterval(interval);
		}
	}, 10)
};

function trace(msg) {
	console.log(msg);
} */

function stopCapture() {
	clearInterval(interval);
	let mouseCursor = document.querySelector(".cention-custom-cursor");
	mouseCursor.style.display = "none";
}

/* Un-used code for now
var observer, targetNode, observerConfig;
function initDOMObserver() {
	// Select the node that will be observed for mutations
	targetNode = document.getElementsByClassName('cention-client-wrapper')[0];

	// Options for the observer (which mutations to observe)
	observerConfig = { attributes: true, childList: true };

	// Callback function to execute when mutations are observed
	var callback = function(mutationsList) {
		for(var mutation of mutationsList) {
			if (mutation.type == 'childList') {
				console.log('A child node has been added or removed.');
			}
			else if (mutation.type == 'attributes') {
				console.log('The ' + mutation.attributeName + ' attribute was modified.');
			}
		}
	};

	// Create an observer instance linked to the callback function
	observer = new MutationObserver(callback);
}

function startObserve() {
	// Start observing the target node for configured mutations
	observer.observe(targetNode, observerConfig);
	console.log("start DOM observing");
}

function stopObserving() {
	// Later, you can stop observing
	observer.disconnect();
	console.log("stopped DOM observing");
} */

function stopCoBrowsing(sessionId, ws) {
	//disconnect webrtc when there's no active video call/screen sharing/cobrowsing
	if(!onVideoCall && !agentScreenShareMode && !clientScreenShareMode && !coBrowseMode) {
		disconnectPC();
	}
}

function toggleAgentMouseAccess(enable) {
	var obj = {
		type: "toggle-mouse-access",
		value: enable
	};
	sendMsgThruChannel(JSON.stringify(obj));
}

function toggleAgentKeyboardAccess(enable) {
	var obj = {
		type: "toggle-keyboard-access",
		value: enable
	};
	sendMsgThruChannel(JSON.stringify(obj));
}

//window.unload = noTimeout;

//window.onresize = reportWindowSize;

/* function reportWindowSize() {
	var height = window.innerHeight;
	var width = window.innerWidth;
	var obj = {};
	obj.sizes = {h: height, w: width};
	obj.sessionId = sessionId;
	obj.type = "client-resize";
	sendMsgThruChannel(JSON.stringify(obj));
} */

//These functions below is reference for future used.

/* function startCoBrowse() {
	//start cloning

	var element = document.getElementsByTagName('body')[0];
	var copy = element.cloneNode(true);
	//var destination = document.getElementById('destination');
	//destination.appendChild(copy);

	//console.log("cloned body: ", copy);
	var html = copy.outerHTML;
	var data = { html: html };
	//  This gives you a string in JSON syntax of the object above that you can
	// send with XMLHttpRequest.
	var json = JSON.stringify(data);
	console.log(json);

	//copyComputedStyle(element, copy);
}

var realStyle = function (_elem, _style) {
	var computedStyle;
	if (typeof _elem.currentStyle != 'undefined') {
		computedStyle = _elem.currentStyle;
	} else {
		computedStyle = document.defaultView.getComputedStyle(_elem, null);
	}

	return _style ? computedStyle[_style] : computedStyle;
};

var copyComputedStyle = function (element, copy) {
	var s = realStyle(element);
	for (var i in s) {
		// Do not use `hasOwnProperty`, nothing will get copied
		if (typeof s[i] == "string" && s[i] && i != "cssText" && !/\d/.test(i)) {
			// The try is for setter only properties
			try {
				copy.style[i] = s[i];
				// `fontSize` comes before `font` If `font` is empty, `fontSize` gets
				// overwritten.  So make sure to reset this property. (hackyhackhack)
				// Other properties may need similar treatment
				if (i == "font") {
					copy.style.fontSize = s.fontSize;
				}
			} catch (e) { }
		}
	}
};
 */

function removeCoBrowseFocus() {
	var elems = document.querySelectorAll(".active-cobrowse");
	for (var i = 0; i < elems.length; i++) {
		elems[i].classList.remove('active-cobrowse');
	}
}

function isRetinaDisplay() {
    if (window.matchMedia) {
        var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
        return (mq && mq.matches || (window.devicePixelRatio > 1));
    }
}

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

var browserZoomLevel = Math.round(window.devicePixelRatio * 100);

function checkNormalBrowserZoom() {
	var normalZoom = false;
	if(isRetinaDisplay()) {
		if(isFF) {
			if(browserZoomLevel === 140) {
				console.log("Retina: Browser is on normal zoom");
				normalZoom = true;
			}
		} else {
			if(isChrome || isSafari) {
				if(browserZoomLevel === 200) {
					console.log("Retina: Browser is on normal zoom");
					normalZoom = true;
				}
			} else {
				//unsupported browser
				console.log("Error on checkNormalBrowserZoom(): Unsupported browser");
			}
		}
	} else {
		if(browserZoomLevel === 100) {
			normalZoom = true;
		}
	}
	return normalZoom;
}

var isOnNormalZoom = checkNormalBrowserZoom();


//utils
/**
 * Gets all event-handlers from a DOM element.
 * Events with namespace are allowed.
 *
 * @param  {Element} node: DOM element
 * @param  {String} eventns: (optional) name of the event/namespace
 * @return {Object}
 */
 function getEventHandlers(element, eventns) {
	const i = (eventns || '').indexOf('.'),
	  event = i > -1 ? eventns.substr(0, i) : eventns,
	  namespace = i > -1 ? eventns.substr(i + 1) : void(0),
	  handlers = Object.create(null);
	element = c3jQuery(element);
	if (!element.length) return handlers;
	// gets the events associated to a DOM element
	const listeners = c3jQuery._data(element.get(0), "events") || handlers;
	const events = event ? [event] : Object.keys(listeners);
	if (!eventns) return listeners; // Object with all event types
	events.forEach((type) => {
	  // gets event-handlers by event-type or namespace
	  (listeners[type] || []).forEach(getHandlers, type);
	});
	// eslint-disable-next-line
	function getHandlers(e) {
	  const type = this.toString();
	  const eNamespace = e.namespace || (e.data && e.data.handler);
	  // gets event-handlers by event-type or namespace
	  if ((event === type && !namespace) ||
		  (eNamespace === namespace && !event) ||
		  (eNamespace === namespace && event === type)) {
		handlers[type] = handlers[type] || [];
		handlers[type].push(e);
	  }
	}
	return handlers;
  }

//for this feature, we hold supporting multiple page
//support and co browsing needs to be re-start / re initiate
//after change page.
window.addEventListener('beforeunload', onBeforeUnload);

function onBeforeUnload(event) {
	if (!coBrowseMode && !clientScreenShareMode) {
		return;
	}
	const e = event || window.event;
	// Cancel the event
	e.preventDefault();
	if (e) {
	  e.returnValue = ''; // Legacy method for cross browser support
	}
	return ''; // Legacy method for cross browser support
}

window.addEventListener('unload', onUnload);

function onUnload (e) {
	if(coBrowseMode || clientScreenShareMode) {
		stopScreenSharing(sessionId, false, false, websocket);
		disconnectPC(true, true);
	}
}
